import { useCallback, useMemo } from "react";
import styles from "./SearchVariantsList.module.scss";
import cn from "classnames";

export const SearchVariantsList = ({
  setResultsData,
  addressData = [],
  physicalData = [],
  companyData = [],
  results,
  setSearchResults,
  className,
  setQuery,
  handleClick,
  // setValue
}) => {
  const addResults = useCallback(
    (item) => {
      if (!results.some((result) => result.uuid === item.uuid)) {
        setSearchResults([...results, item]);
      }
      setQuery("");
    },
    [results, setSearchResults, setQuery]
  );

  const addSearchResults = useCallback(
    (item) => {
      setSearchResults(item);
      setResultsData(true);;
    },
    [setResultsData, setSearchResults]
  );

  const searchVariantsRender = useMemo(() => {
    if (addressData.getAddresses && addressData.getAddresses.length > 0) {
      return (
        <>
          {addressData.getAddresses.map((item) => (
            <li
              className={styles.searchItem}
              onClick={() => addSearchResults(item)}
            >
              {item.value}
            </li>
          ))}
        </>
      );
    }

    if (physicalData.length > 0) {
      return (
        <>
          {physicalData.map((item) => (
            <li
              key={item.id}
              className={styles.listItem}
              onClick={() => addResults(item.node)}
            >
              <div className={styles.info}>
                <span className={styles.client}>
                  {item.node.lastName +
                    " " +
                    item.node.firstName +
                    " " +
                    item.node.patronymic}
                </span>
              </div>
              {item?.node?.signaturesActive && (
                <span className={styles.provider}>{item?.node?.signaturesActive?.connector === 'DIGITAL_DEAL' ? 'myDss' : 'idPoint'}</span>
              )}
            </li>
          ))}
        </>
      );
    }

    if (companyData.length > 0) {
      return (
        <>
          {companyData.map((company) => (
            <li
              key={company.id}
              className={styles.listItem}
              onClick={() => {
                addResults(company.node);
                handleClick();
              }}
            >
              {company.node.fullName}
            </li>
          ))}
        </>
      );
    }

    return null;
  }, [
    physicalData,
    companyData,
    addResults,
    addSearchResults,
    addressData.getAddresses,
    handleClick,
  ]);
  if (addressData.getAddresses && addressData.getAddresses.length > 0) {
    return <ul className={styles.searchList}>{searchVariantsRender}</ul>;
  } else if (companyData.length > 0 || physicalData.length > 0) {
    return (
      <ul className={cn(styles.list, className)}>{searchVariantsRender}</ul>
    );
  }
};
