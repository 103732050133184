import { useEffect, useState } from "react";
import styles from "./ParticipantsList.module.scss";
import {
  newRool,
  suspensionAppeal,
  additionalDocs,
  govRegEnd,
  govRegReturn,
} from "../../../../constants/constants";

export const ParticipantsList = ({
  participants,
  currentParticipantUuid,
  setCurentParticipantUuid,
  setIsCopied,
  applicationType,
}) => {
  const [filteredRepresentatives, setFilteredRepresentatives] = useState([]);

  useEffect(() => {
    if (participants?.length > 0) {
      const newRepresentatives = [];
      const existingIds = new Set(); // Используем Set для отслеживания уникальных id

      if (
        applicationType === suspensionAppeal ||
        applicationType === additionalDocs ||
        applicationType === govRegEnd ||
        applicationType === govRegReturn
      ) {
        for (let i = participants?.length - 1; i >= 0; i--) {
          if (
            participants[i]?.physicalPerson &&
            !participants[i].representative
          ) {
            const physicalPerson = {
              ...participants[i].physicalPerson,
              itemUuid: participants[i].uuid,
            };

            newRepresentatives.push(physicalPerson);
            existingIds?.add(physicalPerson.id); // Добавляем id в Set
          }
          if (participants[i]?.representative) {
            const representative = {
              ...participants[i].representative,
              itemUuid: participants[i].uuid,
            };

            newRepresentatives.push(representative);
            // Добавляем id в Set
          }
        }
      } else {
        for (let i = participants?.length - 1; i >= 0; i--) {
          if (
            participants[i]?.physicalPerson &&
            participants[i]?.participantType !== "REPRESENTED_PHYSICAL_PERSON"
          ) {
            const physicalPerson = {
              ...participants[i].physicalPerson,
              itemUuid: participants[i].uuid,
            };

            newRepresentatives.push(physicalPerson);
            existingIds.add(physicalPerson.id); // Добавляем id в Set
          }
          if (
            participants[i].legalPerson ||
            participants[i]?.participantType === "REPRESENTED_PHYSICAL_PERSON"
          ) {
            const legalPerson = {
              ...participants[i].legalPerson,
              itemUuid: participants[i].uuid,
            };
            if (
              applicationType === newRool &&
              !existingIds.has(legalPerson.id)
            ) {
              newRepresentatives.push(legalPerson);
              existingIds.add(legalPerson.id); // Добавляем id в Set
            } else if (applicationType !== newRool) {
              // Если applicationType не равен newRool, добавляем legalPerson без проверки
              newRepresentatives.push(legalPerson);
              existingIds.add(legalPerson.id); // Добавляем id в Set
            } else if (
              applicationType === suspensionAppeal &&
              participants[i].legalPerson
            ) {
              const representative = {
                ...participants[i].representative,
                itemUuid: participants[i].uuid,
              };
              newRepresentatives.push(representative);
            }
          }
        }
      }

      setFilteredRepresentatives(newRepresentatives);
    }
  }, [participants, applicationType]);

  return (
    <div>
      <h3 className={styles.participantsListTitle}>Выберите участника</h3>
      <ul className={styles.participantsList}>
        {filteredRepresentatives.map((item) => (
          <>
            {item.lastName ? (
              <li
                key={item.id}
                className={
                  item?.itemUuid === currentParticipantUuid
                    ? styles.activeParticipant
                    : ""
                }
                onClick={() => {
                  setCurentParticipantUuid(item?.itemUuid);

                  if (setIsCopied) {
                    setIsCopied(false);
                  }
                }}
              >
                {item.lastName + " " + item.firstName + " " + item.patronymic}
              </li>
            ) : null}
            {item.fullName ? (
              <li
                className={
                  item?.itemUuid === currentParticipantUuid
                    ? styles.activeParticipant
                    : ""
                }
                onClick={() => {
                  setCurentParticipantUuid(item?.itemUuid);

                  if (setIsCopied) {
                    setIsCopied(false);
                  }
                }}
              >
                {item.shortName}
              </li>
            ) : null}
          </>
        ))}
      </ul>
    </div>
  );
};
