import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../../../assets/icons/Logo";
import styles from "./Header.module.scss";
import { UserIcon } from "../../../assets/icons/UserIcon";
import cn from "classnames";
import { Button } from "../../Button";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Context } from "../../../App";
import { ToastComponent } from "../../ToastComponent";
import useRefreshToken from "../../../hooks/useRefreshToken";
import { useSelector } from "react-redux";
import { useLazyQuery, useQuery } from "@apollo/client";
import { selectToken } from "../../../redux/slices/user";
import { ReactComponent as Notification } from "../../../assets/svgs/notfication.svg";
import { ALL_TICKETS_LIST } from "../../../services/queries/queries/support/allTicketsList";
import { CHECK_READ_TICKETS } from "../../../services/queries/queries/applications/checkReadTickets";

const navArray = [
  {
    id: 1,
    title: "Заявки",
    path: "/applications/",
    role: ["AD", "MA"],
  },

  {
    id: 2,
    title: "ЕГРН",
    path: "/egrn/",
    role: ["AD"],
  },

  {
    id: 3,
    title: "Базы",
    path: "/bases/",
    role: ["AD", "MA"],
  },

  {
    id: 4,
    title: "Объекты",
    path: "/objects/",
    role: ["AD", "MA"],
  },

  {
    id: 5,
    title: "Поддержка",
    path: "/support/",
    role: ["AD", "MA"],
  },

  {
    id: 6,
    title: "Пользователи",
    path: "/users/",
    role: ["AD"],
  },
];

export const Header = () => {
  const location = useLocation();
  const currentRole = localStorage.getItem("role");
  const userName = localStorage.getItem("userName");
  const userCompany = localStorage.getItem("userCompany");
  const currentPath = location.pathname;
  const { tab, setShowModal, setTabIndex } = useContext(Context);
  const [showProfile, setShowProfile] = useState(false);
  const actionsRef = useRef(null);
  const read = JSON.parse(localStorage.getItem("read"));
  const [showNotification, setShowNotification] = useState(null);

  const [allTickets, { data: ticketsInfo }] = useLazyQuery(ALL_TICKETS_LIST, {
    fetchPolicy: "no-cache",
  });

  const [checkReadMessages, { data: readData }] = useLazyQuery(
    CHECK_READ_TICKETS,
    {
      fetchPolicy: "no-cache",
    }
  );

  const buttonTextVariantRender = useMemo(() => {
    if (currentPath === "/applications/") {
      return (
        <Link className={styles.headerNewLink} to="/applications/new">
          Новая заявка
        </Link>
      );
    } else if (currentPath === "/egrn/") {
      return (
        <Button
          className={styles.headerBtn}
          variant="green"
          onClick={() => setShowModal(true)}
        >
          Новая выписка
        </Button>
      );
    } else if (currentPath === "/bases/") {
      return (
        <Button
          className={styles.headerBtn}
          variant="green"
          onClick={() => setShowModal(true)}
        >
          {tab}
        </Button>
      );
    } else if (currentPath === "/objects/") {
      return (
        <Button
          className={styles.headerBtn}
          variant="green"
          onClick={() => setShowModal(true)}
        >
          Новый объект
        </Button>
      );
    } else if (currentPath === "/support/") {
      return (
        <Button
          className={styles.headerBtn}
          variant="green"
          onClick={() => setShowModal(true)}
        >
          Новое обращение
        </Button>
      );
    } else if (currentPath === "/users/") {
      return (
        <Button
          className={styles.headerBtn}
          variant="green"
          onClick={() => setShowModal(true)}
        >
          Новое физлицо
        </Button>
      );
    }

    return null;
  }, [currentPath, tab]);

  useEffect(() => {
    const socketLink = process.env.REACT_APP_API_BASE_URL_WS;
    const token = localStorage.getItem("accessToken");
    const fullSocketLink = socketLink + `?token=${token}`;
    const socket = new WebSocket(fullSocketLink);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data) {
        if (data.message) {
          checkReadMessages();
        }
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    checkReadMessages();
  }, []);

  useEffect(() => {
    if (
      ticketsInfo?.allTickets?.edges?.some(
        (item) => item?.node?.isRead === false
      )
    ) {
      setShowNotification(true);
    } else {
      setShowNotification(false);
    }
  }, [ticketsInfo]);

  return (
    <header className={styles.header}>
      <div className={styles.headerInner}>
        <div className={styles.headerNav}>
          <Link to="/" className={styles.headerLogo}>
            <Logo />
          </Link>
          {navArray.map((nav) => {
            if (nav.role.includes(currentRole)) {
              if (nav.title === "Поддержка") {
                return (
                  <div className={styles.headerSupportBlock} key={nav.id}>
                    <Link
                      key={nav.id}
                      to={nav.path}
                      className={cn(styles.headerLink, {
                        [styles.activeLink]: nav.path === currentPath,
                      })}
                    >
                      {nav.title}

                      {readData?.notReadTickets?.isRead === true && (
                        <div className={styles.headerNotification}>
                          <Notification />
                        </div>
                      )}
                    </Link>
                  </div>
                );
              }
              if (nav.title === "Базы") {
                return (
                  <div
                    className={styles.headerSupportBlock}
                    key={nav.id}
                    onClick={() => setTabIndex(0)}
                  >
                    <Link
                      key={nav.id}
                      to={nav.path}
                      className={cn(styles.headerLink, {
                        [styles.activeLink]: nav.path === currentPath,
                      })}
                    >
                      {nav.title}
                    </Link>
                  </div>
                );
              } else {
                return (
                  <Link
                    key={nav.id}
                    to={nav.path}
                    className={cn(styles.headerLink, {
                      [styles.activeLink]: nav.path === currentPath,
                    })}
                  >
                    {nav.title}
                  </Link>
                );
              }
            }
          })}
        </div>

        <div className={styles.headerActions} ref={actionsRef}>
          {buttonTextVariantRender}
          <span
            className={styles.headerAvatar}
            onClick={() => setShowProfile(!showProfile)}
          >
            <UserIcon />
          </span>
          {showProfile && (
            <ToastComponent
              isOpen={showProfile}
              setIsOpen={setShowProfile}
              toastRef={actionsRef.current}
              className={styles.profileInfo}
            >
              <span className={styles.profileName}>{userName}</span>
              {userCompany !== "" && userCompany !== "null" ? (
                <span className={styles.companyName}>{userCompany}</span>
              ) : (
                ""
              )}
              <Link to={`/change-password`} className={styles.profileLink}>
                Сменить пароль
              </Link>
              <Link
                to={"/login"}
                className={styles.profileLink}
                onClick={() => localStorage.clear()}
              >
                Выйти из профиля
              </Link>
            </ToastComponent>
          )}
        </div>
      </div>
    </header>
  );
};
