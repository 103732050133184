import { Input } from "../../../Input";
import { Button } from "../../../Button";
import styles from "./CreatePhycicalForm.module.scss";
import { useForm } from "react-hook-form";
import { RadioButton } from "../../../RadioButton";
import { FormCalendar } from "../../../FormCalendar";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../../App";
import { Select } from "../../../Select/ui/Select";
import { checkValidation } from "../../../../helpers/checkValidation";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_PHYSYCAL_PERSON } from "../../../../services/queries/queries/createPhysicalPerson";
import {
  GET_ADDRESS_PHYSICAL,
  PHYSICALPERSONSLIST,
} from "../../../../services/queries/queries/physicalPersonsList";
import { transformDate } from "../../../../helpers/transformDate";
import { DELETE_PERSON } from "../../../../services/queries/queries/deletePerson";
import { PERSON_INFO } from "../../../../services/queries/queries/personInfo";
import { UPDATE_PHYSICAL } from "../../../../services/queries/queries/updatePerson";
import { unTransformDate } from "../../../../helpers/untransformDate";
import { cleanObject } from "../../../../helpers/cleanObjects";
import useInputSearch from "../../../../hooks/useInputSearch";
import SignatureForm from "../SignatureForm/SignatureForm";
import { checkObjectFields } from "../../../../helpers/checkObjectFields";
import { SearchAddressInput } from "../SearchAddressInput/SearchAddressInput";
import { PhysicalDocumentsUpload } from "../../../PhysicalDocumentsUpload";
import { GET_SIGS_FILES } from "../../../../services/queries/mutations/physicalPersons/getSigFiles";
import { CHECK_API_KEY } from "../../../../services/queries/checkApiToken";

export const CreatePhycicalForm = ({ id, hideModal, uuid, item }) => {
  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    setError,
    reset,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      citizenship: "РФ",
    },
  });

  const { data: personInfo, loading } = useQuery(PERSON_INFO, {
    variables: {
      id,
    },
    skip: !id,
    refetchQueries: [{ query: GET_SIGS_FILES, variables: { id } }],
  });
  const { data: physicalData } = useQuery(GET_ADDRESS_PHYSICAL, {
    variables: { id: item?.node?.id },
    skip: !item?.node.id,
  });
  const [otherToAddress, setOtherToAddress] = useState(
    physicalData?.physicalPerson.registrationAddress || {}
  ); // Вытекающие из адреса (locality, city etc)

  const formatAddress = [
    otherToAddress?.index || "",
    otherToAddress?.region || "",
    otherToAddress?.district || "",
    otherToAddress?.locality || otherToAddress?.locality !== "None None"
      ? otherToAddress?.locality
      : "",
    otherToAddress?.settlementWithType || "",
    otherToAddress?.locationName || "",
    otherToAddress?.city || "",
    otherToAddress?.street || "",
    otherToAddress?.house ? "дом " + otherToAddress?.house : "",
    otherToAddress?.block ? "корпус " + otherToAddress?.block : "",
    otherToAddress?.apartment ? "кв " + otherToAddress?.apartment : "",
  ]
    .filter(Boolean)
    .join(", ");

  const { setShowModal } = useContext(Context);
  const [formStep, setFormStep] = useState(0);
  const userId= localStorage.getItem('id');

  const snilsValidation = /^[0-9\-]+$/;
  const innValidation = /^[0-9]+$/;
  const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;
  const [allValues, setAllvalues] = useState({});

  const [createPhysicalPerson, { error }] = useMutation(
    CREATE_PHYSYCAL_PERSON,
    {
      refetchQueries: [PHYSICALPERSONSLIST],
    }
  );

  const [deletePhysicalPerson] = useMutation(DELETE_PERSON, {
    refetchQueries: [PHYSICALPERSONSLIST],
  });

  const [updatePhysicalPerson] = useMutation(UPDATE_PHYSICAL, {
    refetchQueries: [
      PHYSICALPERSONSLIST,
      { query: PERSON_INFO, variables: { id } },
    ],
  });

  const {data: checkData} = useQuery(CHECK_API_KEY, {
    variables: {
      id: userId
    },
    fetchPolicy: 'no-cache'
  })

  const { search, setSearch, data } = useInputSearch("", "GET_ADDRESSES");
  const [tabIndex, setTabIndex] = useState(0);
  const [ageError, setAgeError] = useState("");
  const [addressError, setAddressError] = useState(false);
  const selectedDocumentsValue = watch("documents");

  const deletePerson = () => {
    if (personInfo && personInfo.physicalPerson) {
      deletePhysicalPerson({
        variables: {
          uuid: uuid,
        },
      });
    }
    setShowModal(false);
  };

  const onSubmitHandler = async (formData) => {
    const physycalData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      gender:
        formData.gender === "Мужской"
          ? "MALE"
          : formData.gender === "Женский"
          ? "FEMALE"
          : null,
      patronymic: formData.patronimic,
      citizenship: formData.citizenship,
      dateOfBirth: transformDate(formData.dateOfBirth),
      placeOfBirth: formData.placeOfBirth,
      inn: formData.inn,
      snils: formData.snils ? formData.snils.split("-").join("") : "",
      email: formData?.email,
      phone: formData?.phone?.replace(/[^\d+]/g, ""),
    };

    const documentsData = {
      code: formData.departmentCode
        ? formData.departmentCode.split("-").join("")
        : '',
      dateIssue: transformDate(formData.issueDate),
      issuedBy: formData.whomIssued || "",
      number: formData.number,
      series: formData.series || '',
      type:
        formData.documents === "Паспорт гражданина РФ"
          ? "PASSPORT"
          : "FOREIGN_PASSPORT",
    };

    const addressData = {
      block: otherToAddress?.block,
      city: otherToAddress?.city,
      country: otherToAddress?.country,
      district: otherToAddress?.district,
      house: otherToAddress?.house,
      index: otherToAddress?.index,
      locality: otherToAddress?.locality || otherToAddress?.city || '',
      okato: otherToAddress?.okato,
      oktmo: otherToAddress?.oktmo,
      region: otherToAddress?.region,
      street: otherToAddress?.street,
      streetType: otherToAddress?.typeStreet,
      structure: otherToAddress?.structure,
      value: formatAddress?.length > 0 ? formatAddress : search,
      apartment: otherToAddress?.apartment,
      regionKladrId: otherToAddress?.regionKladrId,
      fias: otherToAddress?.fias,
      kladr: otherToAddress?.kladr,
    };
    

    try {
      if (!id) {
        await createPhysicalPerson({
          variables: {
            inputPhysicalPerson: cleanObject(physycalData),
            inputDocument: cleanObject(documentsData),
            inputAddress: cleanObject(addressData),
          },
        })
          .then(() => setShowModal(false))
          .catch((err) => {
            setError("root.serverError", {
              type: "Ошибка при отправке запроса",
            });

            if (error.graphQLErrors) {
              error.graphQLErrors.forEach(({ message }) =>
                setError("root.serverError", {
                  type: message.split(".")[0],
                })
              );
            }

            if (error.networkError) {
              setError("root.serverError", {
                type: "Received status code 400",
              });
              console.log(`[Сетевая ошибка]: ${error.networkError}`);
            }
          });
      } else {
        await updatePhysicalPerson({
          variables: {
            id,
            uuid: uuid,
            inputPhysicalPerson: physycalData,
            inputDocument: documentsData,
            inputAddress: addressData,
          },
          // onCompleted: hideModal(null),
        })
          .then(() => setShowModal(false))
          .catch((err) => {
            setError("root.serverError", {
              type: `Ошибка при отправке запроса. ${err?.message}`,
            });
            if (error.graphQLErrors) {
              error.graphQLErrors.forEach(({ message }) =>
                setError("root.serverError", {
                  type: message.split(".")[0],
                })
              );
            }

            if (error.networkError) {
              setError("root.serverError", {
                type: "Received status code 400",
              });
              console.log(`[Сетевая ошибка]: ${error.networkError}`);
            }
          });
      }

      if (!errors?.root?.serverError) {
        if (hideModal) {
          hideModal(null);
        }
      }
    } catch (error) {
      setError("root.serverError", {
        type: `Ошибка при отправке запроса. ${error?.message}`,
      });

      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message }) =>
          setError("root.serverError", {
            type: message.split(".")[0],
          })
        );
      }

      if (error.networkError) {
        setError("root.serverError", {
          type: `Ошибка при отправке запроса. ${error?.message}`,
        });
        console.log(`[Сетевая ошибка]: ${error.networkError}`);
      }
    }
  };
  useEffect(() => {
    if (personInfo && personInfo.physicalPerson) {
      reset({
        firstName: personInfo.physicalPerson?.firstName,
        lastName: personInfo.physicalPerson?.lastName,
        patronimic: personInfo.physicalPerson?.patronymic,
        gender:
          personInfo.physicalPerson?.gender === "MALE"
            ? "Мужской"
            : personInfo.physicalPerson?.gender === "FEMALE"
            ? "Женский"
            : null,
        inn: personInfo.physicalPerson.inn,
        phone: personInfo.physicalPerson.phone,
        email: personInfo.physicalPerson.email,
        citizenship: personInfo.physicalPerson?.citizenship,
        placeOfBirth: personInfo.physicalPerson?.placeOfBirth,
        dateOfBirth: unTransformDate(personInfo.physicalPerson?.dateOfBirth),
        snils: personInfo.physicalPerson?.snils,
        documents:
          personInfo?.physicalPerson?.document?.type === "PASSPORT"
            ? "Паспорт гражданина РФ"
            : "Загранпаспорт",
        series: personInfo?.physicalPerson?.document?.series,
        number: personInfo?.physicalPerson?.document?.number,
        issueDate: unTransformDate(
          personInfo?.physicalPerson?.document?.dateIssue
        ),
        whomIssued: personInfo?.physicalPerson?.document?.issuedBy,
        departmentCode: personInfo?.physicalPerson?.document?.code,
      });
    }
  }, [personInfo, reset]);

  console.log(selectedDocumentsValue, 'value')

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className={styles.formInner}>
        <div className={styles.relativeBox}>
          <h3 className={styles.formTitle}>Физлицо</h3>
          <div className={styles.info}>
            <Input
              isFormField={true}
              name="lastName"
              label={!id ? "Фамилия*" : "Фамилия"}
              variant="bordered-green"
              size="full"
              register={register}
              registerObj={{ required: "Поле обязательно для заполнения" }}
              errors={errors}
            />
            <Input
              isFormField={true}
              name="firstName"
              label={!id ? "Имя*" : "Имя"}
              variant="bordered-green"
              size="full"
              register={register}
              registerObj={{ required: "Поле обязательно для заполнения" }}
              errors={errors}
            />
            <Input
              isFormField={true}
              name="patronimic"
              label="Отчество*"
              variant="bordered-green"
              size="full"
              register={register}
              registerObj={{
                required: {
                  value: id ? true : false,
                  message: "Поле обязательно для заполнения",
                },
              }}
              errors={errors}
            />
            <label className={styles.radiosBox}>
              Пол
              <RadioButton
                name="gender"
                text1="Мужской"
                text2="Женский"
                register={register}
              />
            </label>
            <FormCalendar
              label="Дата рождения"
              name="dateOfBirth"
              register={register}
              setValue={setValue}
              className={styles.physycalCalendar}
              error={ageError}
              setError={setAgeError}
            />
            <Input
              isFormField={true}
              name="placeOfBirth"
              label="Место рождения"
              variant="bordered-green"
              size="full"
              register={register}
              registerObj={{
                required: {
                  value: id ? true : false,
                  message: "Поле обязательно для заполнения",
                },
              }}
              errors={errors}
            />

            <SearchAddressInput
              defaultAddress={item?.address}
              data={data}
              setSearch={setSearch}
              defaultValues={
                physicalData?.physicalPerson.registrationAddress || {}
              }
              setOtherToAddress={setOtherToAddress}
              otherToAddress={otherToAddress}
              formatAddress={formatAddress}
              addressError={addressError}
              setAddressError={setAddressError}
            />
            <Input
              isFormField={true}
              name="citizenship"
              label="Гражданство"
              variant="bordered-green"
              size="full"
              register={register}
              registerObj={{
                required: {
                  value: id ? true : false,
                  message: "Поле обязательно для заполнения",
                },
              }}
              errors={errors}
              value="РФ"
              readOnly={
                selectedDocumentsValue === "Загранпаспорт" ? false : true
              }
            />

            <Input
              isFormField={true}
              name="snils"
              label="СНИЛС"
              variant="bordered-green"
              mask="999-999-999-99"
              size="full"
              setValue={setValue}
              register={register}
              registerObj={{
                required: {
                  value: id ? true : false,
                  message: "Поле обязательно для заполнения",
                },

                minLength: {
                  value: 11,
                  message: "Минимум 11 цифр",
                },
              }}
              placeholder="00000000000"
              onKeyPress={(e) => {
                checkValidation(e, snilsValidation);
              }}
              errors={errors}
            />

            <Input
              isFormField={true}
              name="inn"
              label="ИНН"
              variant="bordered-green"
              size="full"
              register={register}
              mask="999999999999"
              placeholder="000000000000"
              registerObj={{
                maxLength: {
                  value: 12,
                  message: "Максимум 12 цифр",
                },
              }}
              onKeyPress={(e) => {
                checkValidation(e, innValidation);
              }}
              errors={errors}
            />
          </div>

          <div className={styles.documents}>
            <p className={styles.documentsTitle}>Удостоверение личности</p>
            <Select
              isFormSelect={true}
              label="Документ"
              options={["Паспорт гражданина РФ", "Загранпаспорт"]}
              variant="bordered-green"
              size="full"
              name="documents"
              register={register}
            />
            <label className={styles.series}>
              {selectedDocumentsValue === "Паспорт гражданина РФ" && (
                <>
                  <Input
                    isFormField={true}
                    name="series"
                    label="Серия и номер"
                    variant="bordered-green"
                    register={register}
                    registerObj={{
                      required: {
                        value:
                          id && selectedDocumentsValue === "Паспорт гражданина РФ"
                            ? true
                            : id &&
                              selectedDocumentsValue === "Загранпаспорт" &&
                              false,
                        message: "",
                      },
                    }}
                    mask="9999"
                    onKeyPress={(e) => {
                      checkValidation(e, innValidation);
                    }}
                    errors={errors}
                    className={styles.seriesField}
                    placeholder="0000"
                  />
                  <Input
                    isFormField={true}
                    name="number"
                    variant="bordered-green"
                    size="full"
                    register={register}
                    registerObj={{
                      required: {
                        value: id ? true : false,
                      },
                    }}
                    mask="999999"
                    errors={errors}
                    className={styles.numberField}
                    placeholder="000000"
                  />
                </>
              )}

              {selectedDocumentsValue === "Загранпаспорт" && (
                <>
                  <Input
                    isFormField={true}
                    name="series"
                    label="Серия и номер"
                    variant="bordered-green"
                    register={register}
                    registerObj={{
                      required: {
                        value:
                          id && selectedDocumentsValue === "Паспорт гражданина РФ"
                            ? true
                            : id &&
                              selectedDocumentsValue === "Загранпаспорт" &&
                              false,
                        message: "",
                      },
                    }}
                    errors={errors}
                    className={styles.seriesField}
                    placeholder="0000"
                  />
                  <Input
                    isFormField={true}
                    name="number"
                    variant="bordered-green"
                    size="full"
                    register={register}
                    registerObj={{
                      required: {
                        value: id ? true : false,
                      },

                      pattern: id && innValidation,
                    }}
                    errors={errors}
                    className={styles.numberField}
                    placeholder="Номер загранпаспорта"
                  />
                </>
              )}
            </label>
            <FormCalendar
              label="Дата выдачи"
              name="issueDate"
              register={register}
              registerObj={{
                required: {
                  value: id ? true : false,
                  message: "Поле обязательно для заполнения",
                },
              }}
              setValue={setValue}
              className={styles.physycalCalendar}
              errors={errors}
            />
            <Input
              isFormField={true}
              name="whomIssued"
              label="Кем выдан"
              variant="bordered-green"
              size="full"
              register={register}
              registerObj={{
                required: {
                  value:
                    id && selectedDocumentsValue === "Паспорт гражданина РФ"
                      ? true
                      : id &&
                        selectedDocumentsValue === "Загранпаспорт" &&
                        false,
                  message: "Поле обязательно для заполнения",
                },
              }}
              errors={errors}
            />
            {selectedDocumentsValue === "Паспорт гражданина РФ" && (
              <Input
                isFormField={true}
                name="departmentCode"
                label="Код подразделения"
                mask="999-999"
                variant="bordered-green"
                size="full"
                register={register}
                registerObj={{
                  required: {
                    value:
                      id && selectedDocumentsValue === "Паспорт гражданина РФ"
                        ? true
                        : id &&
                          selectedDocumentsValue === "Загранпаспорт" &&
                          false,
                    message: "Поле обязательно для заполнения",
                  },
                }}
                errors={errors}
                placeholder="000000"
              />
            )}

            {selectedDocumentsValue === "Загранпаспорт" && (
              <Input
                isFormField={true}
                name="departmentCode"
                label="Код подразделения"
                variant="bordered-green"
                size="full"
                register={register}
                registerObj={{
                  required: {
                    value:
                      id && selectedDocumentsValue === "Паспорт гражданина РФ"
                        ? true
                        : id &&
                          selectedDocumentsValue === "Загранпаспорт" &&
                          false,
                    message: "Поле обязательно для заполнения",
                  },
                }}
                errors={errors}
              />
            )}
          </div>
          <div className={styles.contacts}>
            <p className={styles.documentsTitle}>Контакты</p>
            <Input
              isFormField={true}
              name="email"
              label={!id ? "Email*" : "Email"}
              variant="bordered-green"
              size="full"
              register={register}
              registerObj={{
                pattern: {
                  value: emailValidation,
                  message: "Введите коректынй e-mail",
                },

                required: "Поле обязательно для заполнения",
              }}
              errors={errors}
              placeholder="test@mail.ru"
            />
            <Input
              isFormField={true}
              name="phone"
              mask="+9(999) 999-99-99"
              label={!id ? "Телефон*" : "Телефон"}
              variant="bordered-green"
              size="full"
              register={register}
              registerObj={{
                required: "Поле обязательно для заполнения",
              }}
              errors={errors}
              placeholder="+7(000) 000-00-00"
            />
          </div>

          {errors?.root?.serverError?.type && (
            <p className={styles.formError}>Ошибка при отправке запроса</p>
          )}

          <Button
            type="submit"
            variant="green"
            className={styles.formBtn}
            disabled={ageError !== ""}
            onMouseEnter={() => setAllvalues(getValues("root"))}
            // onMouseLeave={() => setAllvalues(null)}
          >
            Сохранить
          </Button>

          <Button
            variant="red"
            className={styles.deleteForm}
            onClick={() => {
              deletePerson();
              hideModal(null);
            }}
            type="button"
          >
            Удалить физлицо
          </Button>
        </div>
        <div>
          {item?.node && !checkObjectFields(item?.node) && (
            <SignatureForm
              formStep={formStep}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              setFormStep={setFormStep}
              id={id}
              register={register}
              setValue={setValue}
              errors={errors}
              item={item}
              checkData={checkData}
            />
          )}
          {id && personInfo?.physicalPerson?.signaturesActive?.isActive && (
            <PhysicalDocumentsUpload
              uuid={uuid}
              id={id}
              personLoading={loading}
              documentsData={personInfo?.physicalPerson?.personDocuments}
              signatureActive={personInfo?.physicalPerson?.signaturesActive}
            />
          )}
        </div>
      </div>
    </form>
  );
};
