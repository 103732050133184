import React, { useState, useEffect } from "react";
import { Link, useNavigate, useMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import styles from "./AuthForm.module.scss";
import { Input } from "../../../Input/ui/Input";
import { Button } from "../../../Button/ui/Button";
import { Eye } from "../../../../assets/icons/Eye";
import { AUTH } from "../../../../services/queries";
import {
  setOldPassword,
  setTokens
} from "../../../../redux/slices/user";

export const AuthForm = ({ setRestorePassword }) => {
  const dispatch = useDispatch();

  const [passwordType, setPasswordType] = useState("password");
  const [errorTextLogin, setErrorTextLogin] = useState("");
  const navigate = useNavigate();

  const [mutateFunction, { data, loading, error }] = useMutation(AUTH, {
    onCompleted: (data) => {
      localStorage.setItem("userName", data?.auth?.user?.username);
      localStorage.setItem('id', data?.auth?.user?.profile?.id)
      localStorage.setItem('userCompany', data?.auth?.user?.companyName)
      localStorage.setItem("role", data?.auth?.user?.role);
      dispatch(setTokens(data?.auth?.token, data?.auth?.refreshToken));
      localStorage.setItem("accessToken", data?.auth?.token);
      localStorage.setItem("refreshToken", data?.auth?.refreshToken);
    },
  });
  
  const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,16}/;
  const regLogin = /^[a-zA-Z0-9_]+$/;

  const match = useMatch("/reset-password/:token");

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onPasswordButtonClick = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const onSubmit = (formData) => {
    mutateFunction({
      variables: {
        password: formData.password,
        login: formData.login,
      },
    })
      .then(() => dispatch(setOldPassword(formData.password)))
      .catch((error) => {
        setErrorTextLogin(error?.message);
      });
  };

  useEffect(() => {
    if (data?.auth) {
      dispatch(setTokens(data.auth));
    }
  }, [data?.auth]);

  useEffect(() => {
    if (data && data?.auth?.user?.role === "CL") {
      navigate(`/client-cabinet/`);
    }

    if (
      (data && data?.auth?.user?.role === "MA") ||
      data?.auth?.user?.role === "AD"
    ) {
      navigate("/applications/");
    }

    if (data?.auth?.user?.uuid) {
      localStorage.setItem("userUuid", data?.auth?.user?.uuid);
    }
  }, [data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.authForm}>
      <h3 className={styles.title}>Добро пожаловать!</h3>

      <h4 className={styles.subtitle}>Введите логин чтобы войти</h4>

      <Input
        id="login"
        name="login"
        type="text"
        label="Логин"
        errors={errors}
        isFormField={true}
        errorVariant="absolute"
        className={styles.input}
        register={register}
        registerObj={{
          required: "Укажите почту или логин",
          onChange: () => {
            clearErrors(`email`);
            if (error?.message) {
              setErrorTextLogin("");
            }
          },
        }}
      />

      <div className={styles.input}>
        <div className={styles.passwordInputContainer}>
          <Input
            id="password"
            name="password"
            type={passwordType}
            errors={errors}
            label="Пароль"
            errorVariant="absolute"
            isFormField={true}
            className={styles.input}
            register={register}
            registerObj={{
              required: "Укажите пароль",
              pattern: {
                value: regPassword,
                message: `Пароль не соответствует требованиям`,
              },
              onChange: () => {
                clearErrors(`password`);
                if (error?.message) {
                  setErrorTextLogin("");
                }
              },
            }}
          />
          <Button
            variant="transparent"
            className={styles.passwordButton}
            type="button"
            onClick={onPasswordButtonClick}
          >
            <Eye />
          </Button>
        </div>
        {errorTextLogin ? (
          <div className={styles.errorPassword}>
            <span>Неверные данные для входа</span>
            <span>
              <Link to="/passwordRecovery">Отправить ссылку</Link> на
              восстановление пароля
            </span>
          </div>
        ) : !match ? (
          <Button
            className={styles.resetPasswordButton}
            variant="transparent"
            onClick={() => setRestorePassword(true)}
            type="button"
          >
            Восстановить пароль...
          </Button>
        ) : (
          <div className={styles.loginClient__formWrapper__resetPassword}></div>
        )}
      </div>
      {match && (
        <div className={styles.textPassword}>
          <p>- Используйте только латинские буквы (a–z, A–Z)</p>
          <p>
            - Пароль должен содержать хотя бы один специальный символ: <br />! @
            # $ % ^ & * ( ) _ + = ; : , . / ?| ` ~
          </p>
          <p>
            - Ваш пароль должен содержать от 8 до 16 символов <br />с
            использованием цифр,строчных и заглавных символов.
          </p>
        </div>
      )}
      <Button
        className={styles.submitBtn}
        variant="green"
        type="submit"
        onClick={() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("id");
          localStorage.removeItem("role");
        }}
        disabled={Object.keys(errors).length > 0}
      >
        {match ? "Создать кабинет" : "Войти в кабинет"}
      </Button>
    </form>
  );
};
