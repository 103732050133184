import { gql } from "@apollo/client";

export const UPDATE_APPLICATION_TYPE = gql`
  mutation updateApplicationType(
    $applicationTypeUuid: UUID!
    $applicationUuid: UUID!      
  ) {
    updateApplicationType(
      applicationTypeUuid: $applicationTypeUuid
      applicationUuid: $applicationUuid
    ) {
      application {
        id
        uuid
      }
    }
  }
`;
