import { gql } from "@apollo/client";

export const CREATE_APPLICATION = gql`
  mutation CreateApplication(
    $area: Decimal
    $mortgage: Boolean!
    $ownershipUuid: UUID
    $sum: Decimal
    $typeUuid: UUID!
    $statementRegDate: Date
  ) {
    createApplication(
      area: $area
      mortgage: $mortgage
      ownershipUuid: $ownershipUuid
      sum: $sum
      typeUuid: $typeUuid
      statementRegDate: $statementRegDate
    ) {
      application {
        id
        uuid
      }
    }
  }
`;

export const CREATE_APPLICATION_ADDITIONAL = gql`
  mutation createAdditionalApplication(
    $applicationTypeUuid: UUID!
    $originalApplicationUuid: UUID!
    $participantUuid: UUID!
    $roleUuid: UUID!
    $statementRegDate: Date
    $previousStatementKuvd: String
    $note: String
    $time: Date
  ) {
    createAdditionalApplication(
      applicationTypeUuid: $applicationTypeUuid
      originalApplicationUuid: $originalApplicationUuid
      participantUuid: $participantUuid
      roleUuid: $roleUuid
      statementRegDate: $statementRegDate
      previousStatementKuvd: $previousStatementKuvd
      note: $note
      time: $time
    ) {
      additionalApplication {
        id
        uuid
        newApplication {
          id
          uuid
          cause
          requestPackageUrl
        }
      }
    }
  }
`;
