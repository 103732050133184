import { useMemo, useState } from "react";
import { InfoList } from "../../InfoList/ui/InfoList";
import styles from "./BasesList.module.scss";
import cn from "classnames";
import { Pagination } from "../../Pagination/ui/Pagination";
import { ModalWindow } from "../../ModalWindow/ui/ModalWindow";
import {
  CreatePhycicalForm,
  CreateCompanyForm,
  CreateCompanyRepresentativeForm,
} from "../../Forms";
import { StatusLabel } from "../../StatusLabel/ui/StatusLabel";
import { checkObjectFields } from "../../../helpers/checkObjectFields";

export const BasesList = ({
  personData,
  companiesData,
  representativesCompaniesData,
  agentsData,
  setData,
  page,
  setPage,
  totalPersonPages,
  totalCompanyPages,
  totalCompanybyPhisicalPages,
  totalAllPersonPages,
}) => {
  const totalResults = 10;
  const indexOfLastPage = page * totalResults;
  const indexOfFirstPage = indexOfLastPage - totalResults;
  const [activeItemId, setActiveItemId] = useState(null);
  const [activeCompanyId, setActiveCompanyId] = useState(null);
  const [test, setTest] = useState(null);
  const [activeAgent, setActiveAgent] = useState(null);

  function formatPhoneNumber(phone) {
    if (phone && phone.length === 12) {
      // Добавим символ + перед 7
      let formattedPhone = "+" + phone[1] + " ";

      let part1 = phone.substring(2, 5);
      let part2 = phone.substring(5, 8);
      let part3 = phone.substring(8, 10);
      let part4 = phone.substring(10, 12);

      formattedPhone += part1 + " " + part2 + "-" + part3 + "-" + part4;

      return formattedPhone;
    } else {
      return phone;
    }
  }

  const listVariantRender = useMemo(() => {
    if (personData && personData.length > 0) {
      return (
        <>
          {personData.map((item, index) => (
            <>
              <InfoList
                key={item.node.id}
                className={styles.personListItem}
                onClick={() => setActiveItemId(item?.node?.id)}
              >
                <div className={styles.personInfo}>
                  <span className={styles.person}>
                    {item?.node?.lastName +
                      " " +
                      item?.node?.firstName +
                      " " +
                      (item?.node?.patronymic || "")}
                  </span>
                  <div className={styles.personContacts}>
                    <span>{item.node?.email}</span>
                    <span>{formatPhoneNumber(item?.node?.phone)}</span>
                  </div>
                  {checkObjectFields(item.node) ? (
                    <StatusLabel
                      text="Не все данные заполнены"
                      variant="negative"
                    />
                  ) : null}
                </div>
                <div className={styles.edsInfo}>
                  <span
                    className={cn(styles.edsStatus, {
                      [styles.opacity]:
                        item?.node?.signaturesCurrent[0]?.state !== "COMPLETE",
                    })}
                  >
                    {item?.node?.signaturesCurrent[0]?.state !== "COMPLETE"
                      ? "ЭЦП не выпущена"
                      : item?.node?.signaturesCurrent[0]?.connector ===
                        "ID_POINT"
                      ? "IDPoint"
                      : "myDss"}
                  </span>
                  {item?.node?.signaturesActive?.isActive === true && (
                    <span className={styles.edsProvider}>ЭЦП действует</span>
                  )}
                </div>
              </InfoList>
              {activeItemId === item?.node?.id && (
                <ModalWindow
                  show={true}
                  setShowModal={setActiveItemId}
                  size="xl"
                >
                  <CreatePhycicalForm
                    id={activeItemId}
                    uuid={item.node.uuid}
                    hideModal={setActiveItemId}
                    item={item}
                  />
                </ModalWindow>
              )}
            </>
          ))}

          <Pagination
            page={page}
            setPage={setPage}
            totalPages={[...Array(totalPersonPages).keys()]}
          />
        </>
      );
    }

    if (companiesData && companiesData.length > 0) {
      return (
        <>
          {companiesData.map((company) => (
            <>
              <InfoList
                key={company.node.id}
                className={styles.companyListItem}
                onClick={() => setActiveCompanyId(company.node.id)}
              >
                <div className={styles.companyInfo}>
                  <span className={styles.company}>
                    {company.node.shortName
                      ? company.node.shortName
                      : company.node.fullName}
                  </span>
                  <div className={styles.companyContacts}>
                    <span>{company.node.email}</span>
                    <span>{formatPhoneNumber(company.node.phoneNumber)}</span>
                  </div>
                  {checkObjectFields(company.node) ? (
                    <StatusLabel
                      text="Не все данные заполнены"
                      variant="negative"
                    />
                  ) : null}
                </div>
                <div className={styles.innBox}>
                  <span>{company.node.inn}</span>
                  <span>ИНН</span>
                </div>
                <div className={styles.representativesInfo}>
                  {!company.node.representatives ||
                  !company.node.representatives.length ? (
                    <p>Нет представителей</p>
                  ) : (
                    <div className={styles.representativesBox}>
                      {company.node.representatives.map((item, index) => (
                        <span>
                          {item?.physicalPerson?.lastName +
                            " " +
                            item?.physicalPerson?.firstName +
                            " " +
                            item?.physicalPerson?.patronymic}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </InfoList>
              {activeCompanyId === company.node.id && (
                <ModalWindow
                  show={true}
                  setShowModal={setActiveCompanyId}
                  size="xl"
                  dialogClassName={styles.companyModal}
                >
                  <CreateCompanyForm
                    hideModal={setActiveCompanyId}
                    id={activeCompanyId}
                    uuid={company.node.uuid}
                  />
                </ModalWindow>
              )}
            </>
          ))}

          <Pagination
            page={page}
            setPage={setPage}
            totalPages={[...Array(totalCompanyPages).keys()]}
          />
        </>
      );
    }

    if (
      representativesCompaniesData &&
      representativesCompaniesData.length > 0
    ) {
      return (
        <>
          {representativesCompaniesData.map((company) => (
            <>
              <InfoList
                key={company.node.id}
                className={styles.companyListItem}
                onClick={() => setTest(company.node.id)}
              >
                <div className={styles.representativesCompanyInner}>
                  <div className={styles.representativesCompanyInfo}>
                    <span className={styles.representativesCompanyTitle}>
                      Юрлицо - представитель
                    </span>
                    <span> {company?.node?.legalPerson?.fullName}</span>

                    <span>
                      {company?.node?.representative?.lastName +
                        " " +
                        company?.node?.representative?.firstName +
                        " " +
                        company?.node?.representative?.patronymic}
                    </span>
                  </div>

                  <div className={styles.representativesCompanyPersonInfo}>
                    <span className={styles.representativesCompanyTitle}>
                      Представляемое физлицо
                    </span>

                    <span>
                      {company.node.representedPhysicalPerson.lastName +
                        " " +
                        company.node.representedPhysicalPerson.firstName +
                        " " +
                        company.node.representedPhysicalPerson.patronymic}
                    </span>
                  </div>
                </div>
              </InfoList>

              {test === company.node.id && (
                <ModalWindow
                  show={true}
                  setShowModal={setTest}
                  size="xl"
                  className={styles.companyRepresentiveModal}
                >
                  <CreateCompanyRepresentativeForm
                    hideModal={setTest}
                    id={test}
                    uuid={company.node.uuid}
                  />
                </ModalWindow>
              )}
            </>
          ))}

          <Pagination
            page={page}
            setPage={setPage}
            totalPages={[...Array(totalCompanybyPhisicalPages).keys()]}
          />
        </>
      );
    }

    if (agentsData && agentsData.length > 0) {
      return (
        <>
          {agentsData.map((agent) => (
            <>
              <InfoList
                key={agent.node.id}
                className={styles.companyListItem}
                onClick={() => setActiveAgent(agent.node.id)}
              >
                <div className={styles.representativesCompanyInner}>
                  <div className={styles.representativesCompanyInfo}>
                    <span className={styles.representativesCompanyTitle}>
                      Агент
                    </span>

                    <span>{agent.node.legalPerson.fullName}</span>
                    <span>
                      {agent.node.representative.lastName +
                        " " +
                        agent.node.representative.firstName +
                        " " +
                        agent.node.representative.patronymic}
                    </span>
                  </div>

                  <div className={styles.representativesCompanyPersonInfo}>
                    <span className={styles.representativesCompanyTitle}>
                      Юрлицо
                    </span>
                    <span>{agent?.node?.representedLegalPerson?.fullName}</span>
                  </div>
                </div>
              </InfoList>

              {activeAgent === agent.node.id && (
                <ModalWindow
                  show={true}
                  setShowModal={setActiveAgent}
                  size="xl"
                >
                  <CreateCompanyRepresentativeForm
                    hideModal={setActiveAgent}
                    id={activeAgent}
                    uuid={agent.node.uuid}
                    isAgentForm
                  />
                </ModalWindow>
              )}
            </>
          ))}

          <Pagination
            page={page}
            setPage={setPage}
            totalPages={[...Array(totalAllPersonPages).keys()]}
          />
        </>
      );
    }

    return <p className={styles.paginationInfo}>Больше записей нет</p>;
  }, [
    personData,
    companiesData,
    indexOfFirstPage,
    indexOfLastPage,
    page,
    activeItemId,
    activeCompanyId,
    representativesCompaniesData,
    activeAgent,
    agentsData,
    setData,
    test,
    totalPersonPages,
    totalAllPersonPages,
    totalCompanybyPhisicalPages,
    totalCompanyPages,
  ]);

  return (
    <>
      <ul className={styles.personList}>{listVariantRender}</ul>
    </>
  );
};
