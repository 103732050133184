import styles from './DocumentsList.module.scss'

export const DocumentsList = ({documents}) => {

  return(
    <ul className={styles.documentsWrapper}>
      {documents.map(document => (
        <li key={document?.document_uuid}>{document?.file_name}</li>
      ))}
    </ul>
  )
}