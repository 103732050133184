import styles from "./DocumentsLoader.module.scss";
import { useEffect, useMemo } from "react";
import { Button } from "../../Button";
import { downloadFileFromUrl } from "../../../helpers/downloadFileFromUrl";
import { useState } from "react";
import { downloadExistFiles } from "../../Forms/ui/ApplicationForm/ApplicationForm";

export const DocumentsLoader = ({
  downloadText,
  loadingText,
  loadingRequest,
  requestPackage,
  uuid,
  downloadLink,
  status,
  name = "Пакет",
  className,
  type,
  payment,
}) => {
  const [updatedStatus, setUpdatedStatus] = useState(status);
  const [requestStatus, setRequestStatus] = useState(requestPackage)
  const token = localStorage.getItem('accessToken')

  const reloadingDocuments = (e) => {
    e.stopPropagation();
    if (uuid) {
      loadingRequest({
        variables: {
          applicationUuid: uuid,
        },
      });
    }
  };

  const downLoadLinkVariantRender = useMemo(() => {
    if (updatedStatus.toUpperCase() === "PROCESSED" && requestStatus !== null) {
      return (
        <Button
          variant="transparent"
          type="button"
          className={styles.loadingBtn}
          onClick={(e) => {
            e.stopPropagation();
            downloadExistFiles(token, requestStatus)
          }}
        >
          {downloadText}
        </Button>
      );
    }

    if (updatedStatus.toUpperCase() === "PROCESSED" && requestStatus === null) {
      return (
        <Button
          variant="transparent"
          type="button"
          onClick={reloadingDocuments}
          className={styles.loadingBtn}
        >
          {loadingText}
        </Button>
      );
    }

    if (payment && payment?.receiptUrl !== '') {
      return (
        <Button
          variant="transparent"
          type="button"
          className={styles.loadingBtn}
          onClick={(e) => {
            e.stopPropagation();
            downloadFileFromUrl(downloadLink, name);
          }}
        >
          {downloadText}
        </Button>
      );
    }

    if (payment && payment?.receiptUrl === '') {
      return (
        <Button
          variant="transparent"
          type="button"
          onClick={() => loadingRequest({
            variables: {
              applicationUuid: uuid
            }
          })}
          className={styles.loadingBtn}
        >
          {loadingText}
        </Button>
      );
    }
  }, [
    status,
    requestPackage,
    downloadText,
    loadingText,
    downloadLink,
    payment,
  ]);

  useEffect(() => {
    const socketLink = process.env.REACT_APP_API_BASE_URL_WS;
    const token = localStorage.getItem("accessToken");
    const fullSocketLink = socketLink + `?token=${token}`;
    const socket = new WebSocket(fullSocketLink);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data) {
        if (data?.type === type && data?.application_uuid === uuid) {
          if(data?.status?.toUpperCase() === 'PROCESSED'){
            setRequestStatus(null)
          }
        }
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      socket.close();
    };
  }, []);

  return <div className={className}>{downLoadLinkVariantRender}</div>;
};
