import { useMemo } from "react";
import styles from "./Input.module.scss";
import cn from "classnames";
import { useHookFormMask } from "use-mask-input";

/* 
Компонент инпут

В данном компоненте реализован рендеринг по условию, который возвращает нужный инпут в зависимости от условий.

1. Если передан mask, возвращается инпут с указанной маской (пример маски код подразделения: 999-999)

2. Если передан пропс isFormField со значением true, поле подключается к react-hook-form и позволяет легко управлять выводом ошибок и отправкой данных на сервер

3. Если не передано ничего из указанного выше, создается обычный инпут с value и onChange

Пропсы:

className - передача дополнительных стилей из компонента где вызывается инпут (строка)

value - перменная для хранения значения инпута (обычный инпут) (строка)

setValue - функция для управления значением инпута, помогает сделать обычный инпут управляемым

variant - выбор определенной стилизации инпута (строка)

errorVariant - описать тому, кто делал

size - выбор определенного, наиболее часто используемого размера поля (строка)

placeholder - передача плейсхолдера в инпут (строка)

isFormField - отрендерить поле, подключенное к react-hook-form (boolean)

label - название поля

register - объект, для регистрации поля в react-hook-form, значение для передачи можно взять из хука useForm(), который предоставляет библиотека,

registerObj - объект для установки правил валидации поля (например обязательное поле) Пример: registerObj={{ required: "Поле обязательно для заполнения" }}

name - имя под которым поле регистрируется в react-hook-form (строка)

errors - объект с ошибками, предоставляемый библиоткекой react-hook-form (можно взять там же где и register). Если объект содержит ключ, совпадающий по названию с name, то для инпута с этим name выводится ошибка

isValid - описать тому, кто создавал

onFocus - функция, которая сработает когда инпут получит фокус

type - тип поля, например text, number, textarea и др (строка)

inputElement - описать тому, кто создавал,

errorClassName - добавление стилей для ошибки из родительского компонента

mask - добавляет в поле ввода переданный паттерн маски (пример выше)

...props - позволяет использовать остальные атрибуты, доступные для инпута

*/

export const Input = ({
  className,
  value,
  setValue,
  variant,
  errorVariant,
  size,
  placeholder,
  isFormField = false,
  label,
  register,
  registerObj,
  name,
  errors,
  isValid,
  onFocus,
  type,
  inputElement,
  errorClassName,
  mask,
  ...props
}) => {
  const Root = inputElement || `input`;
  const registerWithMask = useHookFormMask(register);

  const inputVariantRender = useMemo(() => {
    if (!isFormField) {
      return (
        <input
          className={cn(styles.input, className, {
            [styles.green]: variant === "green",
            [styles.bordered]: variant === "bordered",
            [styles.borderedGreen]: variant === "bordered-green",
            [styles.whiteBg]: variant === "white-bg",
            [styles.short]: size === "short",
            [styles.full]: size === "full",
            [styles.error]: variant === 'error'
          })}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          onFocus={onFocus}
          {...props}
        />
      );
    } else if (mask) {
      return (
        <label>
          <div className={className}>
            <span>{label}</span>
            <input
              type="text"
              value={value}
              onChange={(event) => setValue(event.target.value)}
              placeholder={placeholder}
              className={cn(styles.input, {
                [styles.green]: variant === "green",
                [styles.bordered]: variant === "bordered",
                [styles.borderedGreen]: variant === "bordered-green",
                [styles.whiteBg]: variant === "white-bg",
                [styles.short]: size === "short",
                [styles.full]: size === "full",
                [styles.errorField]: errors && errors[name] || variant === 'error',
                
              })}
              {...registerWithMask(name, mask, registerObj)}
            />
          </div>
          {errors && errors[name] && (
            <p className={styles.formError}>{errors[name].message}</p>
          )}
        </label>
      );
    } else {
      return (
        <label>
          <div className={className}>
            {Boolean(label) && <span>{label}</span>}
            <Root
              {...register(name, registerObj)}
              className={cn(styles.input, {
                [styles.green]: variant === "green",
                [styles.bordered]: variant === "bordered",
                [styles.borderedGreen]: variant === "bordered-green",
                [styles.whiteBg]: variant === "white-bg",
                [styles.short]: size === "short",
                [styles.full]: size === "full",
                [styles.errorField]: errors && errors[name] || variant === 'error',
              })}
              placeholder={placeholder}
              type={type}
              {...props}
            />
          </div>
          {errors && errors[name] && (
            <p
              className={cn(styles.formError, errorClassName, {
                [styles.absolute]: errorVariant === "absolute",
              })}
            >
              {errors[name].message}
            </p>
          )}
        </label>
      );
    }
  }, [
    isFormField,
    errors,
    register,
    registerObj,
    name,
    setValue,
    value,
    type,
    props,
    mask,
    className,
    errorVariant,
    label,
    onFocus,
    variant,
    size,
    placeholder,
  ]);

  return <>{inputVariantRender}</>;
};
