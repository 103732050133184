import { useState } from "react";
import { InfoList } from "../../../InfoList/ui/InfoList";
import styles from "./ObjectsList.module.scss";
import { ModalWindow } from "../../../ModalWindow";
import { CreateObjectForm } from "../../../Forms";

export const ObjectsList = ({ objectData }) => {
  const [activeObject, setActiveObject] = useState(null);
  return (
    <ul className={styles.objectsList}>
      {objectData.length > 0 ? (
        objectData.map((object) => (
          <li key={object.id}>
            <InfoList
              className={styles.objectItem}
              onClick={() => setActiveObject(object.id)}
            >
              <div className={styles.objectInfoWrapper}>
                <span className={styles.builder}>{object.developer}</span>
                <span className={styles.address}>{object.address?.value}</span>
                <span>
                  {object.type?.title + ", площадь " + object.buildingArea}
                </span>
                {/* {!object.isFull && (
                  <span className={styles.full}>Не все данные заполнены</span>
                )} */}
              </div>
              <div className={styles.cadastralWrapper}>
                <span>{object.cadastralNumber}</span>
                <span className={styles.knTitle}>КН объекта</span>
              </div>
            </InfoList>
            {activeObject === object.id && (
              <ModalWindow
                show={!!activeObject}
                setShowModal={() => setActiveObject(null)}
                size="xl"
              >
                <CreateObjectForm
                  item={object}
                  hideModal={() => setActiveObject(null)}
                />
              </ModalWindow>
            )}
          </li>
        ))
      ) : (
        <p className={styles.paginationInfo}>Больше записей нет</p>
      )}
    </ul>
  );
};
