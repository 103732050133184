import { gql } from "@apollo/client";

export const ALL_TICKETS_LIST = gql`
  query AllTicketsList($first: Int, $offset: Int) {
    allTickets(first: $first, offset: $offset) {
      edges {
        node {
          id
          uuid
          status
          description
          isRead
          messages {
            message
            user {
              role
              isSuperuser
              profile{
                email
              }
            }
            updatedAt
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const ALL_READ_TICKET = gql`
  query AllTicketsList {
    allTickets {
      edges {
        node {
          isRead
        }
      }
    }
  }
`;
