import React, { useEffect, useState } from "react";
import s from "./passwordRecovery.module.scss";
import { Input } from "../../components/Input";
import { useMatch, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { useForm } from "react-hook-form";
import { PASSWORD_RESET, REGISTER_USER } from "../../services/queries/index";
import { useMutation } from "@apollo/client";
import { Eye } from "../../assets/icons/Eye";
import { toast } from "react-toastify";

const PasswordRecovery = () => {
  const navigate = useNavigate();
  const match = useMatch("/reset-password/:token");
  const match_register = useMatch("/register/:token");
  const [pass, setPass] = useState("");
  const [passRepeated, setPassRepeated] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [disabledButton, setDisabledButton] = useState(true);

  const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,16}/;

  useEffect(() => {
    pass === passRepeated && pass !== ""
      ? setDisabledButton(false)
      : setDisabledButton(true);
  }, [passRepeated, pass]);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const [mutateFunction] = useMutation(PASSWORD_RESET, {
    onCompleted: (data) => {
      navigate("/login/");
      localStorage.clear();
    },
    onError(err) {
      console.log(err?.message);
    },
  });

  const [mutateRegister] = useMutation(REGISTER_USER, {
    onCompleted: (data) => {
      navigate("/login/");
      localStorage.clear();
    },
    onError(err) {
      console.log(err?.message);
    },
  });

  const onSubmit = (formData) => {
    mutateFunction({
      variables: {
        newPassword: formData.password,
        token: match.params.token,
      },
    }).then((res) => {
      if (res.errors?.message) {
        toast.error("Недействительная ссылка");
      }
    });
  };

  const onSubmitRegister = (formData) => {
    mutateRegister({
      variables: {
        password: formData.password,
        username: formData.username,
        token: match_register.params.token,
      },
    });
  };

  const onPasswordButtonClick = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <div className={s.passwordRecovery}>
      <form
        onSubmit={
          match ? handleSubmit(onSubmit) : handleSubmit(onSubmitRegister)
        }
      >
        <h4>{match ? "Восстановление пароля" : "Регистрация пользователя"}</h4>
        <div className={s.input}>
          {match_register && (
            <>
              <Input
                id="username"
                name="username"
                type="text"
                errors={errors}
                label="Логин"
                errorVariant="absolute"
                isFormField={true}
                className={s.input}
                register={register}
              />
            </>
          )}
          <div className={s.passwordInputContainer}>
            <Input
              id="password"
              name="password"
              type={passwordType}
              errors={errors}
              label="Пароль"
              isFormField={true}
              className={s.input}
              register={register}
              registerObj={{
                required: "Укажите пароль",
                pattern: {
                  value: regPassword,
                  message: `Пароль не соответствует требованиям`,
                },
              }}
              onChange={(event) => {
                setPass(event.target.value);
                clearErrors("password");
              }}
            />
            <Button
              variant="transparent"
              className={s.passwordButton}
              type="button"
              onClick={onPasswordButtonClick}
            >
              <Eye />
            </Button>
          </div>
          <div className={s.passwordInputContainer}>
            <Input
              id="password_repeated"
              name="password_repeated"
              type={passwordType}
              errors={errors}
              label="Повторите пароль"
              isFormField={true}
              className={s.input}
              register={register}
              registerObj={{
                validate: (value) => {
                  if (value.length > 0 && pass.length > 0 && value !== pass) {
                    return "Пароли не совпадают";
                  }
                },
                pattern: {
                  value: regPassword,
                  message: `Пароль не соответствует требованиям`,
                },
              }}
              onChange={(event) => {
                setPassRepeated(event.target.value);
                clearErrors("password");
              }}
            />
            <Button
              variant="transparent"
              className={s.passwordButton}
              type="button"
              onClick={onPasswordButtonClick}
            >
              <Eye />
            </Button>
          </div>
        </div>
        <div className={s.textPassword}>
          <>
            {" "}
            <p>- Используйте только латинские буквы (a–z, A–Z)</p>
            <p>
              - Пароль должен содержать хотя бы один специальный символ: <br />!
              @ # $ % ^ & * ( ) _ + = ; : , . / ? | ` ~
            </p>
            <p>
              - Ваш пароль должен содержать от 8 до 16 символов <br />с
              использованием цифр,строчных и заглавных символов.
            </p>
          </>
        </div>
        <Button
          variant="green"
          type="submit"
          disabled={disabledButton}
        >
          {match ? "Изменить пароль" : "Создать пользователя"}
        </Button>
      </form>
    </div>
  );
};

export default PasswordRecovery;
