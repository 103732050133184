import React, { useEffect, useState } from "react";
import { Input } from "../../../Input";
import styles from "./SearchAddressInput.module.scss";
import { Button } from "../../../Button";
import { CreateFullAddressForm } from "../CreateFullAddressForm/CreateFullAddressForm";
import cn from "classnames";

export const SearchAddressInput = ({
  data,
  setSearch,
  defaultAddress,
  defaultValues,
  setOtherToAddress,
  className,
  otherToAddress,
  formatAddress,
  size = "full",
  showFullAddress = true,
  addressError,
  setAddressError,
  variant = "bordered-green",
  baseAddress,
  fullAddressClassname,
  btnClassName,
}) => {
  const [editAddress, setEditAddress] = useState(false);
  const [address, setAddress] = useState(defaultAddress); // адрес
  const [isFullAddress, setFullAddress] = useState(false);

  //Обновление otherToAddress при изменении defaultValues
  useEffect(() => {
    if (
      // defaultValues &&
      Object.keys(defaultValues).length > 0 &&
      JSON.stringify(otherToAddress) !== JSON.stringify(defaultValues)
    ) {
      setOtherToAddress(defaultValues);
    } else {
      if (baseAddress) {
        setOtherToAddress(baseAddress);
      }
    }
  }, [defaultValues, baseAddress]);

  useEffect(() => {
    setAddress(defaultAddress);
  }, [defaultAddress]);

  // Обновление address при изменении formatAddress
  useEffect(() => {
    if (formatAddress.length > 0 && address !== formatAddress) {
      setAddress(formatAddress);
    }
  }, [formatAddress]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOtherToAddress(() => ({
      ...otherToAddress,
      [name]: value,
    }));
  };

  const getSearch = (event) => {
    const address = event.target.value;
    setAddress(address);
    if (address.length > 0) {
      setEditAddress(true);
      setSearch(address);
    } else {
      setEditAddress(false);
    }
  };

  useEffect(() => {
    setAddress(address);
  }, [address]);

  return (
    <div>
      <div>
        <div className={cn(styles.searchAdrress, className)}>
          <span className={styles.searchText}>Адрес</span>
          <div>
            <Input
              label="Адрес"
              name="address"
              variant={addressError ? "error" : variant}
              size={size}
              onChange={getSearch}
              onFocus={() => setAddressError(false)}
              value={address?.value ? address?.value : address}
              disabled={isFullAddress}
            />
            {addressError && (
              <p className={styles.formError}>
                Поле обязательно для заполнения
              </p>
            )}
            {editAddress && (
              <div className={styles.addressList}>
                {data?.getAddresses?.map((item, index) => (
                  <div
                    key={index}
                    className={styles.addressName}
                    onClick={() => {
                      setAddress(item.value);
                      setOtherToAddress(item);
                      setEditAddress(false);
                    }}
                  >
                    {item.value}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {showFullAddress ? (
          <Button
            type="button"
            variant="transparent"
            className={cn(styles.fullAddressBtn, btnClassName)}
            onClick={() => {
              setFullAddress(!isFullAddress);
              if (!isFullAddress && formatAddress) {
                setAddress(formatAddress);
              }
            }}
          >
            Уточнить адрес
          </Button>
        ) : null}

        {isFullAddress && (
          <CreateFullAddressForm
            otherToAddress={otherToAddress}
            onChange={handleChange}
            formatAddress={formatAddress}
            fullAddressClassname={fullAddressClassname}
          />
        )}
      </div>
    </div>
  );
};
