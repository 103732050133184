import { gql } from "@apollo/client";

export const TICKET_INFO = gql`
  query TicketInfo($id: ID!){
  ticket(id: $id){
    uuid
    user{
      isSuperuser
    }
    description
    file
    createdAt
    status
    presignedUrl
    messages{
      uuid
      message
      createdAt
      updatedAt
      file
      presignedUrl
      # accounts{
      #   physicalPerson{
      #     email
      #   }
      # }
    }
  }
}

`