import { gql } from "@apollo/client";


export const DOWNLOAD_QUITTANCE_APPLICATION = gql`
  mutation DownloadQuittanceApplication($applicationUuid: UUID!) {
    downloadQuittanceApplication(applicationUuid: $applicationUuid) {
      success
    }
  }
`;
