import { gql } from "@apollo/client";

export const UPDATE_PHYSICAL_PERSON = gql`
  mutation UpdatePhysicalPersonProfile(
    $inputAddress: AddressInput
    $inputDocument: IdentityDocumentInput
    $inputPhysicalPerson: CreatePhysicalPersonInput
    
  ) {
    updatePhysicalPersonProfile(
      inputAddress: $inputAddress
      inputDocument: $inputDocument
      inputPhysicalPerson: $inputPhysicalPerson
    ) {
      physicalPerson {
        id
      }
    }
  }
`;

export const UPDATE_PHYSICAL = gql`
  mutation updatePhysicalPerson(
    $inputAddress: AddressInput
    $inputDocument: IdentityDocumentInput
    $inputPhysicalPerson: CreatePhysicalPersonInput
    $uuid: UUID!
  ) {
    updatePhysicalPerson(
      inputAddress: $inputAddress
      inputDocument: $inputDocument
      inputPhysicalPerson: $inputPhysicalPerson
      uuid: $uuid
    ) {
      physicalPerson {
        id
      }
    }
  }
`;
