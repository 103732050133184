import { useEffect, useState } from "react";
import styles from "./EditAccount.module.scss";
import { Input } from "../../../Input";
import { Button } from "../../../Button";
import { RadioButton } from "../../../RadioButton";
import { useForm } from "react-hook-form";
import { BasketIcon } from "../../../../assets/icons/BasketIcon";

export const EditAccount = ({ account, updateAccount, deleteAccount, error }) => {
  const [value, setValue] = useState(account?.apiKey ? account?.apiKey : "нет");
  const [isEdit, setIsEdit] = useState(false);
  const [role, setRole] = useState(account?.role || "");
  const [permision, setPermision] = useState(
    account?.permissionStatus || false
  );


  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      role: account.role === "MA" ? "Менеджер" : "Администратор",
      newWorkStatus:
        account?.permissionStatus === true ? "Разрешена" : "Запрещена",
    },
  });

  const watchedRole = watch("role");
  const watchedPermision = watch("newWorkStatus");

  const updateUserAccount = () => {
    const userInput = {
      apiKey: value !== "нет" ? value : "",
      role: role,
      permissionStatus: permision,
    };

    updateAccount({
      variables: {
        userUuid: account?.uuid,
        userInput,
      },
    }).then(() => {
      setIsEdit(false);
    })
  };

  const deleteAcc = () => {
    deleteAccount({
      variables: {
        userUuid: account?.uuid,
      },
    }).then(() => localStorage.removeItem("edsRight"));
  };

  useEffect(() => {
    if (watchedRole && watchedRole === "Администратор") {
      setRole("AD");
    }

    if (watchedRole && watchedRole === "Менеджер") {
      setRole("MA");
    }

    if (watchedPermision && watchedPermision === "Разрешена") {
      setPermision(true);
    } else {
      setPermision(false);
    }
  }, [watchedRole, watchedPermision]);

  return (
    <>
      <div>
        <span>Логин: {account.username ? account.username : "нет"}</span>
        <span>
          Токен:{" "}
          {!isEdit ? (
            <span title={value?.length > 20 && value}>
              {!error ? value?.length > 20 ? value?.slice(0, 20) + "..." : value : 'нет'}
            </span>
          ) : (
            <>
              <Input
                variant="bordered-green"
                value={value}
                setValue={setValue}
                className={styles.editToken}
              />
              <div>
                <div className={styles.fieldsWrapper}>
                  <span className={styles.fieldlabel}>Работа в системе:</span>
                  <RadioButton
                    name="newWorkStatus"
                    register={register}
                    text1="Разрешена"
                    text2="Запрещена"
                    className={styles.radioField}
                    registerObj={{ required: "Выберите подходящий вариант" }}
                    errors={errors}
                  />
                </div>
                <div className={styles.fieldsWrapper}>
                  <span className={styles.fieldlabel}>Роль:</span>
                  <RadioButton
                    name="role"
                    register={register}
                    text1="Менеджер"
                    text2="Администратор"
                    className={styles.radioField}
                    registerObj={{ required: "Выберите подходящий вариант" }}
                    errors={errors}
                  />
                </div>
              </div>
            </>
          )}
        </span>
      </div>

      <div className={styles.tokenButtons}>
        {isEdit && (
          <Button
            type="button"
            variant="green"
            disabled={isEdit === false ? true : false}
            className={styles.updateToken}
            onClick={updateUserAccount}
          >
            Сохранить
          </Button>
        )}
        <span
          className={styles.addToken}
          onClick={() => {
            setIsEdit(!isEdit);

            if (!account.apiKey) {
              setValue("нет");
            } else {
              setValue(account.apiKey);
            }
          }}
        >
          {!isEdit ? "Редактировать" : "Закрыть"}
        </span>
        <span
          title="Удалить аккаунт"
          className={styles.deleteAccount}
          onClick={deleteAcc}
        >
          <BasketIcon />
        </span>
      </div>
    </>
  );
};
