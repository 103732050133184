import { gql } from "@apollo/client";

export const ALL_APPLICATIONS = gql`
  query AllApplications($search: String, $first: Int, $offset: Int) {
    allApplications(search: $search, first: $first, offset: $offset) {
      edges {
        node {
          uuid
          id
          status
          similarityField
          downloadZip
          requestPackageUrl
          kuvd
          isCopy
          type {
            title
            id
          }
          createdAt
          updatedAt
          requestSend
          payments {
            id
          }
          requestHistory {
            status
            createdAt
            id
            statusDescription
          }
          participants {
            physicalPerson {
              firstName
              lastName
              patronymic
              fullName
            }

            legalPerson {
              shortName
              inn
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;
