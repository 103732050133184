import { useLazyQuery, useMutation } from "@apollo/client";
import { SEND_REQUEST_APP_SERVICE } from "../../../../services/queries/mutations/applications/sendRequestsApplicationService";
import { toast } from "react-toastify";
import { Button } from "../../../../components/Button";
import styles from './SendToReestr.module.scss';
import { translateStatus } from "../../../../helpers/translateStatus";
import { useEffect } from "react";
import { REQUESTS_HISTORY } from "../../../../services/queries/queries/applications/requestHistory";


export const SendToReestr = ({statement, documentComplete, kuvd, uuid, requestHistory, applicationId, setStatuses}) => {

  const [requestsHistory, {data}] = useLazyQuery(REQUESTS_HISTORY, {
    fetchPolicy: 'no-cache',
    pollInterval: 10000
  })

  const [sendRequestsApplicationService, {data: serviceData}] = useMutation(
    SEND_REQUEST_APP_SERVICE,
    {
      onCompleted: (data) => {
        toast.success(data.sendRequestsApplicationService.message);
      },
      onError(err) {
        toast.error(err.message);
      },
      
    }
  );


  useEffect(() => {
    if(serviceData){
      requestsHistory({
        variables: {
          id: applicationId
        }
      })
    }
  }, [serviceData])

  useEffect(() => {
    if(data && data?.application?.requestHistory){
      setStatuses(data?.application?.requestHistory)
    }
  }, [data])

  return (
    <>
      {((statement?.length > 0 &&
        documentComplete === true) ||
        translateStatus(
          requestHistory?.at(-1)?.status,
          kuvd
        ) === ("Ошибка при отправке" || "Ошибка при обработке запроса")) && (
        <>
          <Button
            variant="green"
            className={styles.addBtn}
            type="button"
            onClick={() =>
              sendRequestsApplicationService({
                variables: {
                  applicationUuid: uuid,
                },
              })
            }
          >
            <span>Отправить обращение в РР</span>
          </Button>
          <span className={styles.info}>
            Нужно получить все подписи, чтобы отправить обращение.
          </span>
        </>
      )}
    </>
  );
};
