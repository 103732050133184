import styles from "./EdsInfo.module.scss";
import { Button } from "../../../components/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { AppStoreIcon } from "../../../assets/icons/AppStoreIcon";
import { GooglePlayIcon } from "../../../assets/icons/GooglePlayIcon";
import { ChangeActiveEds } from "../../../components/ChangeActiveEds/ui/ChangeActiveEds";

export const EdsInfo = () => {
  const [active, setActive] = useState(null);
  const [connector, setConnector] = useState("");
  const [consumerUuid, setConsumerUuid] = useState("");
  const [allSignatures, setAllSignatures] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  

  const edsInfoVariantRender = useMemo(() => {
    if (consumerUuid !== "") {
      return (
        <div className={styles.edsInfoBox}>
          <h3 className={styles.edsInfoTitle}>ЭЦП</h3>
          <p className={styles.edsInfoDescr}>
            Если есть представитель, <br /> подписывать документы будет он и
            требуется именно его ЭЦП.
          </p>
          <h2 className={styles.edsInfoStatus}>
            Подпись от {connector} выпущена
          </h2>

          {connector !== "ID_POINT" ? (
            <div className={styles.edsDownloadInfo}>
              <h3 className={styles.edsDownloadTitle}>Скачать приложение</h3>
              <div className={styles.edsDownloadLinks}>
                <Link
                  to={
                    "https://apps.apple.com/ru/app/%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%B0%D1%8F-%D1%81%D0%B4%D0%B5%D0%BB%D0%BA%D0%B0/id6463122781"
                  }
                >
                  <AppStoreIcon />
                </Link>
                <Link
                  to={
                    "https://play.google.com/store/apps/details?id=com.digitaldeal"
                  }
                >
                  <GooglePlayIcon />
                </Link>
              </div>
            </div>
          ) : null}
          {allSignatures.length > 1 && (
            <div className={styles.selectBox}>
              <span>
                Изменить активную подпись:
              </span>
              <ChangeActiveEds signaturesData={allSignatures} active={active}/>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className={styles.edsInfoBox}>
          <h3 className={styles.edsInfoTitle}>ЭЦП</h3>
          <p className={styles.edsInfoDescr}>
            Если есть представитель, <br /> подписывать документы будет он и
            требуется именно его ЭЦП.
          </p>
          <h2 className={styles.edsStatusNegative}>
            Ждём вас в нашем в офисе с паспортом. Там оформим электронную
            подпись и больше приезжать в офис не придётся.
          </h2>
          <div className={styles.edsDownloadInfo}>
            <h3 className={styles.edsDownloadTitle}>Скачать приложение</h3>
            <div className={styles.edsDownloadLinks}>
              <Link
                to={
                  "https://apps.apple.com/ru/app/%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%B0%D1%8F-%D1%81%D0%B4%D0%B5%D0%BB%D0%BA%D0%B0/id6463122781"
                }
              >
                <AppStoreIcon />
              </Link>
              <Link
                to={
                  "https://play.google.com/store/apps/details?id=com.digitaldeal"
                }
              >
                <GooglePlayIcon />
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }, [consumerUuid]);

  useEffect(() => {
    if (location && location?.state?.activeSignature) {
      setActive(location.state.activeSignature);
    }

    if (location && location?.state?.connector) {
      setConnector(location.state.connector);
    }

    if (location && location?.state?.signatureUuid) {
      setConsumerUuid(location.state.signatureUuid);
    }

    if (location && location?.state?.signaturesData) {
      setAllSignatures(location.state.signaturesData);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.edsInfoContent}>
        <Button
          className={styles.edsInfoBtn}
          onClick={() => navigate(`/client-cabinet/`)}
        >
          <span>{"<"}</span>
          Кабинет
        </Button>
        <div className={styles.edsContainer}>{edsInfoVariantRender}</div>
      </div>
    </div>
  );
};
