import Form from "react-bootstrap/Form";
import styles from "./index.module.scss";
import cn from 'classnames'

export const Checkbox = ({ label, register, name, className, labelClassName, isChecked, onChange}) => {

	return (
		<Form.Check type="checkbox" className={styles.checkboxContainer} {...register(name)}>
			<Form.Check.Input 
				type="checkbox" 
				isValid 
				className={cn(styles.checkbox, className)} 
				onChange={onChange && onChange}
				checked={isChecked && isChecked}
			/>
			<label className={cn(styles.label, labelClassName)}>{label}</label>
		</Form.Check>
	);
};

