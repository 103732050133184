import { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { Button } from "../../../../../Button";
import { ModalWindow } from "../../../../../ModalWindow";
import styles from "./ObjectBlock.module.scss";
import { Edit } from "../../../../../../assets/icons/Edit";
import { BasketIcon } from "../../../../../../assets/icons/BasketIcon";
import { ObjectBaseFinder } from "../ObjectBaseFinder/ObjectBaseFinder";
import { CreateObjectForm } from "../../../CreateObjectForm/CreateObjectForm";

const CardRow = ({ isError, text, errorText, name }) => {
  return (
    <>
      {isError ? (
        <span className={styles.dataError}>{errorText}</span>
      ) : (
        <span className={styles[`${name}`]}>{text}</span>
      )}
    </>
  );
};

export const ObjectBlock = ({
  isThirdPartyApplication,
  setRealObjectUuid,
  applicationData,
  applicationSended,
  applicationId,
  applicationInfo,
  isCopy = false,
}) => {
  const [addFromBase, setAddFromBase] = useState(false);
  const [isfromBase, setIsFromBase] = useState(true);
  const [addManual, setAddManual] = useState(false);
  const [objectData, setObjectData] = useState(null);
  const [buildingArea, setBuildingArea] = useState("");
  const [objectType, setObjectType] = useState("");

  useEffect(() => {
    if (applicationData && setRealObjectUuid) {
      setRealObjectUuid(applicationData.uuid);
    }

    if (objectData && setRealObjectUuid) {
      setRealObjectUuid(
        objectData?.node?.uuid || objectData?.uuid || objectData?.[0]?.uuid
      );
    }
  }, [objectData, setRealObjectUuid, applicationData]);

  useEffect(() => {
    if (applicationData) {
      setIsFromBase(true);
      setBuildingArea(applicationData.buildingArea);
    } else {
      setIsFromBase(false);
    }
  }, [applicationData]);

  const isNotAllData = useMemo(() => {
    if (objectData) {
      setBuildingArea(
        objectData?.node?.buildingArea ||
          objectData?.buildingArea ||
          objectData?.[0]?.buildingArea
      );
      return objectData;
    }

    if (applicationData) {
      return applicationData;
    }

    return null;
  }, [objectData, applicationData]);

  const onFromBaseClick = () => {
    setAddFromBase(true);
    setIsFromBase(true);
  };

  const deleteObjectAddedFromBase = () => {
    setObjectData(null);
    setIsFromBase(false);
  };

  const closeBaseModal = () => {
    setAddFromBase(false);
    setIsFromBase(false);
  };

  if (
    !isfromBase &&
    !addManual &&
    !isThirdPartyApplication &&
    !objectData &&
    !applicationData
  ) {
    return (
      <div className={styles.btnContainer}>
        <Button
          className={styles.addBtn}
          variant="outline-green"
          type="button"
          onClick={onFromBaseClick}
        >
          Добавить из базы...
        </Button>
        <Button
          className={styles.addBtn}
          variant="outline-green"
          type="button"
          onClick={() => setAddManual(true)}
        >
          Создать новый...
        </Button>
      </div>
    );
  }


  return (
    <>
      <div className={styles.topBtnContainer}>
        {!isCopy && (
          <Button
            variant="transparent"
            type="button"
            onClick={() => {}}
            className={styles.egrnBtn}
          >
            Заказать выписку ЕГРН...
          </Button>
        )}
        {addManual && (
          <Button
            variant="transparent"
            type="button"
            className={styles.hideObjectFormBtn}
            onClick={() => setAddManual(false)}
          >
            Убрать объект
          </Button>
        )}
      </div>

      {(addManual || isThirdPartyApplication) && (
        <>
          <ModalWindow show={addManual} setShowModal={setAddManual} size="xl">
            <CreateObjectForm
              hideModal={setAddManual}
              setShowModal={setAddManual}
              setObjectData={setObjectData}
              setObjectType={setObjectType}
              applicationData={applicationData}
              applicationInfo={applicationInfo}
              applicationId={applicationId}
              isCopy={isCopy}
            />
          </ModalWindow>
        </>
      )}

      {Boolean(objectData || applicationData) && (
        <div className={styles.objectCard}>
          {isfromBase ? (
            <div className={styles.fromBaseTitle}>
              <span>Добавлено из базы объектов </span>

              {!isNotAllData && (
                <>
                  -<span className={styles.dataError}> дозаполните данные</span>
                </>
              )}
            </div>
          ) : null}
          <div
            className={cn(styles.objectCardInfo, {
              [styles.fromBase]: isfromBase,
            })}
          >
            <span className={styles.cardTitle}>Объект</span>

            <CardRow
              name="objectType"
              text={
                objectData?.[0]?.objectType ||
                objectData?.node?.type?.title ||
                applicationData?.type?.title ||
                objectType
              }
              isError={
                !applicationData?.type?.title &&
                !objectData?.objectType &&
                !objectData?.node?.type?.title &&
                !objectType
              }
              errorText={"Тип объекта не указан"}
            />
            <CardRow
              name="kn"
              isError={
                !objectData?.node?.cadastralNumber &&
                !applicationData?.cadastralNumber &&
                !objectData?.cadastralNumber &&
                !objectData?.[0]?.cadastralNumber
              }
              text={
                !applicationData?.cadastralNumber
                  ? objectData?.node?.cadastralNumber ||
                    objectData?.cadastralNumber ||
                    objectData?.[0]?.cadastralNumber
                  : applicationData?.cadastralNumber
              }
              errorText={"Кадастровый номер не указан"}
            />

            <CardRow
              name="area"
              isError={
                objectData?.node?.buildingArea === "Отсутсвует" &&
                applicationData?.buildingArea === "Отсутсвует"
              }
              text={
                buildingArea !== "Отсутсвует"
                  ? buildingArea + " " + "м²"
                  : "Площадь не указана"
              }
              errorText={"Площадь не указана"}
            />

            <CardRow
              name="address"
              isError={
                !objectData?.node?.address?.value &&
                !applicationData?.address?.value &&
                !objectData?.address &&
                !objectData?.[0]?.address?.value
              }
              text={
                objectData?.node?.address?.value ||
                objectData?.address ||
                objectData?.[0]?.address?.value ||
                applicationData?.address?.value
              }
              errorText={"Адрес не указан"}
            />
          </div>

          <div className={styles.objectCardBtns}>
            {isCopy && (
              <Button
                variant="transparent"
                className={styles.editBtn}
                type="button"
                onClick={() => setAddManual(true)}
              >
                <Edit />
              </Button>
            )}
            {applicationSended && (
              <Button
                variant="transparent"
                className={styles.deleteBtn}
                type="button"
                onClick={deleteObjectAddedFromBase}
              >
                <BasketIcon />
              </Button>
            )}
          </div>
        </div>
      )}

      <ModalWindow show={addFromBase} setShowModal={closeBaseModal} size="xl">
        <ObjectBaseFinder
          setObjectData={setObjectData}
          setShow={setAddFromBase}
          setRealObjectUuid={setRealObjectUuid}
        />
      </ModalWindow>
    </>
  );
};
