import { gql } from "@apollo/client";

export const CHECK_API_KEY = gql`
  query CheckApiKey($id: ID!) {
    user(id: $id) {
      accounts {
        username
        apiKey
      }
    }
  }
`;
