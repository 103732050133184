import { useNavigate } from "react-router-dom";
import { InfoList } from "../../../components/InfoList/ui/InfoList";
import { Sidebar } from "../../../components/Sidebar";
import styles from "./Cabinet.module.scss";
import { ToastComponent } from "../../../components/ToastComponent";
import { Logo } from "../../../assets/icons/Logo";
import { Button } from "../../../components/Button";
import { Preloader } from "../../../components/Preloader";
import { useEffect, useRef, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  CLIENT_INFO,
  CLIENT_INFO_PROFILE,
} from "../../../services/queries/queries/client/clientInfo";
import { ClientDocumentsList } from "../../../components/Lists/ui/ClientDocumentsList";
import { SidebarSelect } from "../../../components/SidebarSelect/ui/SidebarSelect";
import { EdsList } from "../../../components/Lists/ui/EdsList";
import { formattedDateFunc } from "../../../helpers/formattedDateFunc";

export const Cabinet = () => {
  const [showProfile, setShowProfile] = useState(false);
  const [changeApplication, setChangeApplication] = useState("");
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const [currentApplication, setCurrentApplication] = useState([]);
  const [showEds, setShowEds] = useState("false");
  const [socketData, setSocketData] = useState([]);
  const [currentPayments, setCurrentPayments] = useState([]);
  const [allApplications, setAllApplications] = useState([]);
  const [allRequests, setAllRequests] = useState([]);
  const [clientDocuments, setCliendDocuments] = useState([]);

  const {
    data: clientData,
    loading: clientLoading,
    refetch,
  } = useQuery(CLIENT_INFO_PROFILE, {
    fetchPolicy: "no-cache",
  });

  const [clientLazyInfo, { data: clientLazyData }] = useLazyQuery(
    CLIENT_INFO_PROFILE,
    {
      fetchPolicy: "no-cache",
    }
  );

  const toggleProfileHandler = () => {
    setShowProfile(!showProfile);
  };

  useEffect(() => {
    if (clientData?.physicalPersonProfile?.profile?.personalDocuments) {
      setSocketData(
        clientData?.physicalPersonProfile?.profile?.personalDocuments
      );
    }

    if (clientData?.physicalPersonProfile?.profile?.participants) {
      clientData?.physicalPersonProfile?.profile?.participants?.forEach(
        (participant) => {
          setAllApplications((prev) => [...prev, participant?.application]);
        }
      );
    }

    if (clientLazyData?.physicalPersonProfile?.profile?.personalDocuments) {
      setSocketData(
        clientLazyData?.physicalPersonProfile?.profile?.personalDocuments
      );
    }
  }, [clientData]);

  useEffect(() => {
    if (currentApplication[0]?.application.payments?.length > 0) {
      currentApplication[0]?.application.payments?.map((item) => {
        if (
          clientData?.physicalPersonProfile?.profile?.payments?.some(
            (payment) => payment.amount === item.amount
          )
        ) {
          setCurrentPayments((prev) => [...prev, item]);
        }
      });
    } else {
      setCurrentPayments([]);
    }

    if (
      currentApplication &&
      currentApplication[0]?.application?.requestHistory?.length > 0
    ) {
      setAllRequests(currentApplication[0]?.application?.requestHistory);
    }

    if (
      currentApplication &&
      currentApplication[0]?.documentSignatures.length > 0
    ) {
      setCliendDocuments([]);
      setCliendDocuments((prev) => [
        ...prev,
        ...currentApplication[0]?.documentSignatures,
      ]);
    } else {
      setCliendDocuments([]);
    }

  }, [currentApplication]);

  useEffect(() => {
    const socketLink = process.env.REACT_APP_API_BASE_URL_WS;
    const token = localStorage.getItem("accessToken");
    const fullSocketLink = socketLink + `?token=${token}`;
    const socket = new WebSocket(fullSocketLink);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data) {
        if (
          data.type === "person_document_request" ||
          data.type === "person_document_update"
        ) {
          refetch();
          setSocketData((prev) => {
            return prev.map((item) => {
              if (item?.document?.uuid === data?.document?.uuid) {
                return {
                  ...item,
                  document: {
                    ...item.document,
                    title: data?.document?.title,
                  },
                };
              }
              return item;
            });
          });
        }

        if (
          data.type === "payment_request_updated" &&
          clientData?.physicalPersonProfile?.uuid === data.person_uuid
        ) {
          setCurrentPayments((prev) => [
            {
              amount: data.amount,
              paymentType: data.payment_type,
              payTillDate: data.pay_till_date,
              receiptUrl: data.receipt,
            },
            ...prev,
          ]);
        }

        if (
          data.type === "request_updated_rosreestr" ||
          data.type === "history_list"
        ) {
          refetch();
        }

        if (
          data.type === "create_requests_statement" &&
          data.application_uuid === currentApplication[0]?.application?.uuid
        ) {
          refetch();
        }

        if (data.type === "person_create_signature") {
          setCliendDocuments((prev) => {
            const documentExists = prev.some((doc) => doc.id === data.id);

            if (!documentExists) {
              return [
                ...prev,
                {
                  state: data.state,
                  id: data.id,
                  document: {
                    title: data.document_title,
                    uuid: data?.document_uuid,
                  },
                  participantUuid: data.participant_uuid,
                },
              ];
            }
            return prev;
          });
        }

        if (data.type === "person_delete_signature" && data?.document_uuid) {
          setCliendDocuments((prevDocuments) => {
            const newDocuments = prevDocuments.filter(
              (item) => item?.document?.uuid !== data?.document_uuid
            );
            return newDocuments;
          });
        }
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (changeApplication !== "") {
      const application =
        clientData?.physicalPersonProfile?.profile?.participants?.filter(
          (participant) => changeApplication === participant?.application?.uuid
        );

      setCurrentApplication(application);
    } else {
      setCurrentApplication([
        clientData?.physicalPersonProfile?.profile?.participants[0],
      ]);
    }
  }, [changeApplication, clientData]);

  useEffect(() => {
    const edsStatus = localStorage.getItem("edsStatus");

    if (edsStatus) {
      setShowEds(edsStatus);
    }
  }, []);

  return (
    <div className={styles.cabinetWrapper}>
      <Sidebar className={styles.cabinetSidebar}>
        <div className={styles.logoBox}>
          <Logo />
        </div>
        <div className={styles.userInfo}>
          {!clientLoading ? (
            <span>
              Добро пожаловать, <br />
              <span>
                {clientData?.physicalPersonProfile?.profile?.lastName +
                  " " +
                  clientData?.physicalPersonProfile?.profile?.firstName +
                  " " +
                  clientData?.physicalPersonProfile?.profile?.patronymic}
              </span>
            </span>
          ) : (
            <Preloader variant="small" className={styles.clientPreloader} />
          )}

          <div
            className={styles.profileToastWrapper}
            ref={sidebarRef}
            onClick={toggleProfileHandler}
          >
            <Button variant="transparent">Профиль</Button>
            <ToastComponent
              isOpen={showProfile}
              setIsOpen={setShowProfile}
              toastRef={sidebarRef.current}
              className={styles.profileToast}
            >
              <div className={styles.sidebarProfile}>
                <div className={styles.toastLinks}>
                  <span className={styles.toastDescr}>Данные для входа</span>
                  <p className={styles.clientContacts}>
                    {clientData?.physicalPersonProfile?.username}
                  </p>
                </div>
                <div className={styles.toastButtons}>
                  <Button
                    variant="outline-green"
                    onClick={() => navigate(`/change-password`)}
                  >
                    Сменить пароль...
                  </Button>

                  <Button
                    variant="transparent"
                    onClick={() => {
                      localStorage.removeItem("accessToken");
                      navigate("/login/");
                    }}
                    className={styles.exitBtn}
                  >
                    Выйти из профиля
                  </Button>
                </div>
              </div>
            </ToastComponent>
          </div>

          {clientData?.physicalPersonProfile?.profile?.participants?.length >
          1 ? (
            <div className={styles.selectBox}>
              <span className={styles.selectTitle}>Ваши сделки:</span>
              <SidebarSelect
                clientData={clientData && clientData}
                setApplication={setChangeApplication}
                setCliendDocuments={setCliendDocuments}
              />
            </div>
          ) : clientData?.physicalPersonProfile?.profile?.participants
              ?.length === 1 ? (
            <div>
              <span className={styles.selectTitle}>
                Ваши сделки: <br />
              </span>
              <span className={styles.addressInfo}>
                {currentApplication[0]?.application?.realObject?.address?.value}
              </span>
            </div>
          ) : null}
        </div>

        {!clientData?.physicalPersonProfile?.profile?.participants?.length ? (
          <p className={styles.sidebarDescr}>
            Вам необходимо заполнить свои персональные данные и далее
            ознакомиться с договором
          </p>
        ) : null}
      </Sidebar>

      <div className={styles.cabinetContent}>
        <div>
          <h3 className={styles.contentTitle}>Личные документы</h3>
          <InfoList
            className={styles.contentList}
            onClick={() =>
              navigate(`/client-documents/`, {
                state: {
                  clientData: clientData,
                },
              })
            }
          >
            <h3>Удостоверение личности и СНИЛС</h3>
            {clientLoading ? null : !clientData?.physicalPersonProfile?.profile
                ?.snils ? (
              <span>Ждем данные</span>
            ) : null}
          </InfoList>
        </div>

        {!clientLoading ? (
          socketData?.length > 0 && (
            <div>
              <h3 className={styles.contentTitle}>
                Документы, ожидающие действий
              </h3>
              {socketData?.map((personalDocument, index) => (
                <InfoList
                  key={index}
                  className={styles.contentList}
                  onClick={() =>
                    navigate("/hot-document/", {
                      state: {
                        action: personalDocument.actyonType,
                        documentTitle: personalDocument?.document?.title,
                        documentUuid: personalDocument.uuid,
                        clientUuid: clientData?.physicalPersonProfile?.uuid,
                        confirmFile: personalDocument?.document?.presignedUrl,
                        showEds: showEds,
                      },
                    })
                  }
                >
                  <h3>{personalDocument?.document?.title}</h3>
                  <span>
                    {personalDocument.actyonType === "REQUEST_DOCUMENT"
                      ? "Документ запрошен"
                      : personalDocument.actyonType === "CONFIRM_DOCUMENT" &&
                        "Документ ожидает подтверждения"}
                  </span>
                </InfoList>
              ))}
            </div>
          )
        ) : (
          <Preloader />
        )}

        {clientData?.physicalPersonProfile?.profile?.participants?.length >
        0 ? (
          <div>
            <h3 className={styles.contentTitle}>Электронная подпись</h3>
            <EdsList
              data={clientData?.physicalPersonProfile}
              className={styles.edsList}
            />
          </div>
        ) : null}

        {clientData?.physicalPersonProfile?.profile?.participants?.length >
        0 ? (
          <div>
            <h3 className={styles.contentTitle}>Объект</h3>
            <InfoList
              className={styles.objectList}
              onClick={() =>
                navigate("/object-info/", {
                  state: { objectInfo: currentApplication[0]?.application },
                })
              }
            >
              {currentApplication[0]?.application?.realObject?.address?.value ||
                "Адрес не указан"}
            </InfoList>
          </div>
        ) : null}

        {clientDocuments.length > 0 ? (
          <div>
            {clientDocuments.length > 0 && (
              <h3 className={styles.contentTitle}>Документы по сделке</h3>
            )}
            <ClientDocumentsList
              clientData={clientDocuments}
              className={styles.dealList}
            />
          </div>
        ) : null}

        {currentApplication[0]?.application?.requestHistory.length > 0 &&
          allRequests.length > 0 && (
            <div>
              <h3 className={styles.contentTitle}>Росреестр</h3>

              <InfoList className={styles.contentList}>
                <h3
                  className={styles.requestTitle}
                  onClick={() =>
                    navigate("/reestr-history/", {
                      state: {
                        historyData: allRequests,
                        downloadZip: currentApplication[0]?.downloadZip,
                        requestPackage: currentApplication[0]?.requestPackage,
                        kuvd: currentApplication[0]?.application?.kuvd,
                      },
                    })
                  }
                >
                  Росреестр
                </h3>
              </InfoList>
            </div>
          )}

        {currentPayments?.length > 0 && (
          <div>
            <h3 className={styles.contentTitle}>Пошлины</h3>
            {currentPayments.map((payment, index) => (
              <InfoList
                key={index}
                className={styles.dutyList}
                onClick={() =>
                  navigate("/duty/", {
                    state: {
                      fileUrl: payment?.receiptUrl,
                    },
                  })
                }
              >
                <span className={styles.dutyPrice}>{payment?.amount} ₽</span>
                <p className={styles.dutyInfo}>
                  {payment?.paymentType === "PAYMENT_REGISTRATION"
                    ? "Оплата госпошлины за государственную регистрацию прав и сделок в отношении объектов недвижимости"
                    : "Оплата выписки"}
                </p>
                <span className={styles.paymentDate}>
                  Оплатить до: {formattedDateFunc(payment?.payTillDate)}
                </span>
              </InfoList>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
