import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import styles from "./login.module.scss";
import { Logo } from "../../../../assets/icons/Logo";
import { AuthForm } from "../../../../components/Forms";
import { PasswordRecoveryForm } from "../../../../components/Forms";

export const Login = () => {
  const [restorePassword, setRestorePassword] = useState(false);
  const match = useMatch("/reset-password/:token");

  return (
    <div className={styles.loginClient}>
      <div className={styles.info}>
        <Logo />
        {match && (
          <>
            <h3>Создание кабинета</h3>
            <p>
              Вам прислали приглашение создать личный кабинет. В нем вы найдёте
              все документы по сделке, заполните паспортные данные и выпустите
              электронную подпись.
            </p>
            <p> Всё оформление сделки дистанционно и безопасно.</p>
          </>
        )}
      </div>
      <div className={styles.formWrapper}>
        {restorePassword ? (
          <PasswordRecoveryForm setRestorePassword={setRestorePassword} />
        ) : (
          <AuthForm setRestorePassword={setRestorePassword} />
        )}
      </div>
    </div>
  );
};
