import styles from "./CreateFullAddressForm.module.scss";
import { checkValidation } from "../../../../helpers/checkValidation";
import { useState } from "react";
import cn from 'classnames'

export const CreateFullAddressForm = ({
  otherToAddress,
  onChange,
  formatAddress,
  fullAddressClassname
}) => {
  const numbersValidation = /^[0-9]+$/;
  const [region, setRegion] = useState(otherToAddress?.region);
  const [district, setDistrict] = useState(otherToAddress?.district);
  const [locality, setLocality] = useState(otherToAddress?.locality || otherToAddress?.city);
  const [street, setStreet] = useState(otherToAddress?.street);
  const [house, setHouse] = useState(otherToAddress?.house);
  const [block, setBlock] = useState(otherToAddress?.block || otherToAddress?.building);
  const [apartment, setApartment] = useState(otherToAddress?.apartment)
  const [index, setIndex] = useState(otherToAddress?.index)

  return (
    <div className={cn(styles.formWrapper, fullAddressClassname)}>
      <div className={styles.addressBlock}>
        <span className={styles.searchText}>Регион</span>
        <input
          className={styles.addressInput}
          name="region"
          label="Регион"
          variant="bordered-green"
          size="full"
          value={region}
          onChange={ (event) => {
              setRegion(event.target.value);
              onChange(event);
            }
          }
        />
      </div>
      <div className={styles.addressBlock}>
        <span className={styles.searchText}>Район</span>
        <input
          className={styles.addressInput}
          name="district"
          label="Район"
          variant="bordered-green"
          size="full"
          value={district}
          onChange={ (event) => {
              setDistrict(event.target.value);
              onChange(event);
            }
          }
        />
      </div>
      <div className={styles.addressBlock}>
        <span className={styles.searchText}>Населенный пункт</span>
        <input
          className={styles.addressInput}
          name="locality"
          label="Населенный пункт"
          variant="bordered-green"
          size="full"
          value={locality}
          onChange={ (event) => {
              setLocality(event.target.value);
              onChange(event);
            }
          }
        />
      </div>
      <div className={styles.addressBlock}>
        <span className={styles.searchText}>Улица</span>
        <input
          className={styles.addressInput}
          name="street"
          label="Улица"
          variant="bordered-green"
          value={street}
          onChange={ (event) => {
              setStreet(event.target.value);
              onChange(event);
            }
          }
        />
      </div>
      <div className={styles.addressBlock}>
        <span className={styles.searchText}>Номер дома</span>
        <input
          className={styles.addressInput}
          name="house"
          label="Номер дома"
          variant="bordered-green"
          size="full"
          value={house}
          onChange={ (event) => {
              setHouse(event.target.value);
              onChange(event);
            }
          }
        />
      </div>
      <div className={styles.addressBlock}>
        <span className={styles.searchText}>Корпус</span>
        <input
          className={styles.addressInput}
          name="block"
          label="Корпус"
          variant="bordered-green"
          size="full"
          value={block}
          onChange={ (event) => {
              setBlock(event.target.value);
              onChange(event);
            }
          }
        />
      </div>
      <div className={styles.addressBlock}>
        <span className={styles.searchText}>Квартира</span>
        <input
          className={styles.addressInput}
          name="apartment"
          label="Квартира"
          variant="bordered-green"
          value={apartment}
          onChange={ (event) => {
              setApartment(event.target.value);
              onChange(event);
            }
          }
        />
      </div>
      <div className={styles.addressBlock}>
        <span className={styles.searchText}>Индекс</span>
        <input
          className={styles.addressInput}
          name="index"
          label="Индекс"
          variant="bordered-green"
          size="full"
          value={index}
          onChange={ (event) => {
              setIndex(event.target.value);
              onChange(event);
            }
          }
          onKeyPress={(e) => {
            checkValidation(e, numbersValidation);
          }}
        />
      </div>

      {numbersValidation && (
        <div className={styles.finalAddress}>
          <h4 className={styles.finalAddressTitle}>Сформированный адрес</h4>
          <p>{formatAddress}</p>
        </div>
      )}
    </div>
  );
};
