import { useState, useEffect } from "react";
import styles from "./ChangeActiveEds.module.scss";
import { useMutation } from "@apollo/client";
import { UPDATE_SIGNATURE } from "../../../services/queries/mutations/client/updateAciveSignature";
import { CLIENT_INFO_PROFILE } from "../../../services/queries/queries/client/clientInfo";
import { InfoList } from "../../InfoList";
import cn from "classnames";

export const ChangeActiveEds = ({ signaturesData }) => {
  const [changeEds, setChangeEds] = useState("");
  const [firstChoice, setFirstChoice] = useState(false);
  const [updateSignature] = useMutation(UPDATE_SIGNATURE, {
    refetchQueries: [{ query: CLIENT_INFO_PROFILE }],
    fetchPolicy: "no-cache",
  });

  const handleClick = (uuid) => {
    updateSignature({
      variables: {
        consumerUuid: uuid,
      },
    }).then((data) =>
      setChangeEds(data?.data?.updateSignatureActive?.signature?.connector)
    );
  };

  useEffect(() => {
    signaturesData.forEach((signature) => {
      if (signature.isActive && !firstChoice) {
        setChangeEds(signature.connector);
        setFirstChoice(true);
      }
    });
  }, [signaturesData, firstChoice]);

  return (
    <ul className={styles.edsList}>
      {signaturesData &&
        signaturesData.length > 0 &&
        signaturesData.map((signature) => (
          <InfoList
            key={signature.consumerUuid}
            className={cn(styles.signatures, {
              [styles.activeSignature]: signature.connector === changeEds,
            })}
            onClick={() => handleClick(signature.consumerUuid)}
          >
            <span>{signature.connector}</span>
            {signature.connector === changeEds && (
              <span className={styles.activeIndicator}></span>
            )}
          </InfoList>
        ))}
    </ul>
  );
};
