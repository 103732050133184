import { useEffect, useState, useCallback } from "react";
import styles from "./CryptoToken.module.scss";
import { CryptoPro } from "ruscryptojs";
import { Button } from "../../Button";
import { useMutation, useLazyQuery } from "@apollo/client";
import { DOCUMENT_SIGNATURE_TOKEN } from "../../../services/queries/mutations/physicalPersons/tokenSign";
import cn from "classnames";
import { Error } from "../../Error/ui/Error";
import { PARTICIPANT_INFO } from "../../../services/queries/queries/applications/participantInfo";

/* 
Комопнент для подписания документа в карточке физлица криптотокеном

Пропсы: 

toastRef - пропс для отслеживания области, при клике на которую не будет закрываться toastComponent

fileName - имя файла, которое необходимо для подписания

onClickHandler - действие для кнопки подписания документа через приложение,

documentId - id документа для передачи в graphql вместе с ответом от функции подписания

signatureActive - информация о наличии электронной подписи у физлица, если подписи нет - подписать документ через приложение нельзя, но можно криптотокеном если стоит плагин (за проверку плагина отчечает checkPlugin). Если и плагина нет, то и кроптокеном документ подписать нельзя.


*/

export const CryptoToken = ({
  file,
  inn,
  tokenBtnContent,
  tokenBtnClassName,
  participantFullName,
  applicationInfo,
  applicationId,
  documentId,
  setCheckPluginStatus,
  tokenSign,
  participantId,
}) => {
  const [certificates, setCertificates] = useState([]);
  const [currentCertificate, setCurentCertificate] = useState(null);
  const [convertedFile, setConvertedFile] = useState(null);
  const [checkPlugin, setCheckPlugin] = useState(null);
  const [showError, setShowError] = useState(false);
  const [tokenInfo, setTokenInfo] = useState(tokenSign ? tokenSign : false);

  const [documentSign] = useMutation(DOCUMENT_SIGNATURE_TOKEN);
  const [participantInfo, { data }] = useLazyQuery(PARTICIPANT_INFO);

  const cryptopro = new CryptoPro();

  cryptopro.init().then(function (info) {
    console.log("Initialized", info);
  });

  const getCertificatesList = useCallback(() => {
    cryptopro.listCertificates().then((data) => {
      setCertificates(data);
    });
  }, [cryptopro]);

  const convertFileToBase64 = async (file) => {
    const token = localStorage.getItem("accessToken");
    const response = await fetch(file, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Ошибка при получениии файла: ${response.statusText}`);
    }

    const pdfFile = await response.blob(); // Получаем blob из ответа
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onload = (e) => {
        const header = ";base64,";
        const base64Data = e.target.result;
        const base64File = base64Data.substr(
          base64Data.indexOf(header) + header.length
        );
        resolve(base64File); // Возвращаем base64 как результат
      };

      fileReader.onerror = (error) => {
        reject(error); // Обрабатываем ошибку
      };

      fileReader.readAsDataURL(pdfFile); // Считываем blob как Data URL
    });
  };

  const handleFileConversion = async (file) => {
    try {
      const base64File = await convertFileToBase64(file);
      setConvertedFile(base64File);
    } catch (error) {
      console.error("Ошибка при конвертации файла:", error);
    }
  };

  const signatureDocument = () => {
    if (convertedFile && currentCertificate) {
      cryptopro
        .signData(convertedFile, currentCertificate[0]?.id)
        .then(function (data) {
          if (data && documentId) {
            documentSign({
              variables: {
                base64File: data,
                signatureId: documentId,
              },
            }).then(() => {
              if (applicationInfo && applicationId) {
                applicationInfo({
                  variables: {
                    id: applicationId,
                  },
                  skip: !applicationInfo || !applicationId,
                });
              }
            });
          }
        })
        .catch((error) => {
          setShowError(true);
        });
    }
  };

  useEffect(() => {
    cryptopro.init().then(function (info) {
      setCheckPlugin(info);
    });
  }, []);

  useEffect(() => {
    getCertificatesList();
  }, []);

  useEffect(() => {
    if (certificates.length > 0 && inn) {
      const certificate = certificates.filter(
        (cert) =>
          cert?.subject?.INN === inn
      );
      setCurentCertificate(certificate);
    }
  }, [certificates, inn]);

  useEffect(() => {
    if (file) {
      handleFileConversion(file);
    }
  }, [file]);

  useEffect(() => {
    if (setCheckPluginStatus && checkPlugin?.version) {
      setCheckPluginStatus(true);
    }

    if (setCheckPluginStatus && !checkPlugin?.version) {
      setCheckPluginStatus(false);
    }
  }, [checkPlugin, setCheckPluginStatus]);

  useEffect(() => {
    if (!tokenSign && participantId) {
      participantInfo({
        variables: {
          id: participantId,
        },
      });
    }
  }, [tokenSign, participantId]);

  useEffect(() => {
    if (data?.participant?.legalPerson) {
      const fullNameParts = participantFullName.split(" ");
      const representative =
        data?.participant?.legalPerson?.representatives?.find((el) =>
          fullNameParts.includes(el?.physicalPerson?.lastName)
        );

      if (representative?.signToken) {
        setTokenInfo(representative.signToken);
      }
    }
  }, [data]);

  return (
    <div>
      {tokenInfo && (
        <Button
          type="button"
          variant="outline-green"
          onClick={signatureDocument}
          className={cn(styles.signatureBtn, tokenBtnClassName)}
          disabled={checkPlugin?.version === "" ? true : false}
        >
          {tokenBtnContent}
        </Button>
      )}
      {showError && (
        <Error error={showError} errorText={"Сертикат не найден"} />
      )}
    </div>
  );
};
