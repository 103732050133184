import { FILTERED_APPLICATIONS } from "../../../../services/queries/queries/applications/filteredApplications";
import { useDebounce } from "use-debounce";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

/* 
Компонент для фильтрации заявок. который объединяет в себе значения выбранные в фильтрах и отправляет запрос с этими значениями на сервер. Если какое-то значени уходит пустым - фильтр для этого значения сбрасывается

 Пропсы:

  value - передача значения из поиска

  typeSearch - передача значения из фильтра по типу

  firstdate, secondDate - передача значений календаря для фильтрации по дате

  mainValue - значение для сброса фильтра по дате

  dealparty - значение из фильтра по продавцу

  status - значение из филььтра по статусу

  setAllApplications - функция для обновления массива с заявками значением, поступившим в ответ на отправленный запрос

  setLoadingStatus - функция для управления показом прелоадера

*/

export const ApplicationsFilters = ({
  value,
  typeSearch,
  firstDate,
  mainValue,
  secondDate,
  dealParty,
  status,
  setAllApplications,
  setLoadingStatus,
  setCurrentPageFilter,
  currentPageFilter,
  setTotalPageFilter,
  setTotalCountedFiltered,
  shouldFetchAllApplications,
}) => {
  const first = 10; // Количество объектов на странице
  const offset = currentPageFilter * first;
  const [debouncedValue] = useDebounce(value, 500);
  const [getFilteredApplications, { data, loading }] = useLazyQuery(
    FILTERED_APPLICATIONS,
    {
      fetchPolicy: "no-cache",
      skip: shouldFetchAllApplications,
    }
  );
  const totalObjects = data ? data.allApplications.totalCount : 0;
  useEffect(() => {
    setTotalPageFilter(Math.ceil(totalObjects / first));
    setTotalCountedFiltered(data?.allApplications?.totalCount);
  }, [data]);


  useEffect(() => {

    getFilteredApplications({
      variables: {
        search: debouncedValue,
        typeSearch,
        dateRange:
          firstDate && secondDate
            ? firstDate + "-" + secondDate
            : mainValue
            ? mainValue
            : "",
        dealParty,
        status,
        offset: offset,
        first: first,
      },
    });
  }, [
    offset,
    first,
    typeSearch,
    firstDate,
    secondDate,
    dealParty,
    debouncedValue,
    status
  ]);

  useEffect(() => {
    if (data && data?.allApplications?.edges) {
      setAllApplications(data?.allApplications?.edges);
    }
  }, [data]);

  useEffect(() => {
    setLoadingStatus(loading);
  }, [loading]);

  return <></>;
};
