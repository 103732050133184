import { useEffect, useState } from "react";
import { Logo } from "../../../assets/icons/Logo";
import styles from "./MassiveSignPage.module.scss";
import { PluginInfo } from "./PluginInfo/PluginInfo";
import { useParams } from "react-router-dom";

export const MassiveSignPage = () => {
  const [currentCertificate, setCurrentCertificate] = useState("");
  const [clientDocuments, setClientDocuments] = useState([]);
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const getDoccuments = process.env.REACT_APP_MASSIVE_SIGN_DOCUMENTS;
  const fullLink = getDoccuments + token + "/documents/";

  const getClientDocuments = async () => {
    try {
      const response = await fetch(
        fullLink
      );

      if (!response.ok) {
        throw new Error(
          "Не удалось получить документы. Вомзможно ссылка устарела"
        );
      }

      const data = await response.json();

      return data;
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (token) {
      getClientDocuments()
        .then((data) => setClientDocuments(data?.documents))
        .finally(() => setLoading(false));
    }
  }, [token]);

  return (
    <>
      <header className={styles.header}>
        <Logo />
      </header>
      <div>
        <PluginInfo
          currentCertificate={currentCertificate}
          setCurrentCertificate={setCurrentCertificate}
          documents={clientDocuments}
          loading={loading}
          error={error}
        />
      </div>
    </>
  );
};
