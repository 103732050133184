import { gql } from "@apollo/client";

  export const CREATE_STATEMENT_APPLICATION = gql`
    mutation CreateStatementApplication(
        $applicationUuid: UUID!
    ){
    createStatementApplication(
      applicationUuid: $applicationUuid
    ){
      success
    }
  }
  `
