import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ReestrHistory.module.scss";
import { Button } from "../../../components/Button";
import { formatDate } from "../../../helpers/formatDate";
import { downloadExistFiles } from "../../../components/Forms/ui/ApplicationForm/ApplicationForm";

export const ReestrHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { historyData } = location.state || [];
  const { downLoadZip, kuvd, requestPackage } = location.state || "";
  const token = localStorage.getItem("accesToken");

  return (
    <div className={styles.reestrWrapper}>
      <Button
        className={styles.reestrPrevBtn}
        onClick={() => navigate(`/client-cabinet/`)}
      >
        <span>{"<"}</span>
        Кабинет
      </Button>
      <div className={styles.reestrContainer}>
        <div className={styles.reestInfo}>
          <h3>Заявление</h3>
          <div className={styles.historyWrapper}>
            {historyData.map((item) => (
              <div key={item?.id}>
                <span className={styles.documentStatus}>
                  Статус: {item?.statusName}{" "}
                  {formatDate(item?.statusTime) + " "}
                  {item?.statusName === "Отправлено в РР" && (
                    <span
                      className={styles.requestPackage}
                      onClick={() => downloadExistFiles(token, downLoadZip)}
                    >
                      Скачать отправленный пакет
                    </span>
                  )}
                  {item?.statusName === "Принято от заявителя" && kuvd && (
                    <div className={styles.acceptedBlock}>
                      <span className={styles.requestKuvd}>{kuvd}</span>
                    </div>
                  )}
                  {item?.statusName === "Обработка завершена" && (
                    <span
                      className={styles.requestPackage}
                      onClick={() => downloadExistFiles(token, requestPackage)}
                    >
                      Скачать полученный пакет
                    </span>
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};