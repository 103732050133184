import styles from "./AccountsItem.module.scss";
import { NewAccount } from "../NewAccount/NewAccount";
import { EditAccount } from "../EditAccount/EditAccount";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_ACCOUNT } from "../../../../services/mutations/addUserAccount";
import { ALL_USERS } from "../../../../services/queries/queries/user";
import { DELETE_USER_ACCOUNT } from "../../../../services/mutations/deleteUserAccount";
import { CHECK_API_KEY } from "../../../../services/queries/checkApiToken";
import { toast } from "react-toastify";

export const AccountsItem = ({
  accounts,
  showFields,
  setShowFields,
  tokenValue,
  setTokenValue,
  setRole,
  setPermition,
  addUserAccount
}) => {

  const userId = localStorage.getItem('id');

  const [updateAccount, {error}] = useMutation(UPDATE_USER_ACCOUNT, {
    refetchQueries: [ALL_USERS, {query: CHECK_API_KEY, variables: {id: userId}}],
    fetchPolicy: "no-cache",
    onError: (error) => {
      toast.error(error.message)
    }
  });

  
  const [deleteAccount] = useMutation(DELETE_USER_ACCOUNT, {
    refetchQueries: [ALL_USERS, {query: CHECK_API_KEY, variables: {id: userId}}],
    fetchPolicy: 'no-cache',
    onError: (error) => {
      toast.error(error.message)
    }
  })

  return (
    <>
      {accounts.map((account, index) => (
        <div className={styles.userInfo} key={account.id}>
          <span className={styles.companyName}>
            {account?.companyName ? account?.companyName : "Без компании"}
          </span>
          <div className={styles.accountInfo}>
            <EditAccount 
              account={account} 
              updateAccount={updateAccount}
              deleteAccount={deleteAccount}
              error={error}
            />
          </div>
        </div>
      ))}
      {showFields && (
        <NewAccount
          tokenValue={tokenValue}
          setShowFields={setShowFields}
          setTokenValue={setTokenValue}
          setRole={setRole}
          setPermition={setPermition}
          addUserAccount={addUserAccount}
        />
      )}
    </>
  );
};

