import { gql } from "@apollo/client";

export const PARTICIPANT_INFO = gql`
  query ParticipantInfo($id: ID!) {
    participant(id: $id) {
      legalPerson {
        representatives {
          signToken
          physicalPerson {
            id
            lastName
          }
        }
      }
    }
  }
`;
