import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ClientObject.module.scss";
import { useState, useEffect } from "react";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { useForm } from "react-hook-form";

export const ClientObject = () => {
  const [objectInfo, setObjectInfo] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const { register, reset } = useForm();

  useEffect(() => {
    if (location.state && location.state.objectInfo) {
      setObjectInfo(location.state.objectInfo);
    }
  }, []);

  useEffect(() => {
    if (objectInfo) {
      reset({
        type: objectInfo?.realObject?.type?.title
          ? objectInfo?.realObject?.type?.title
          : "-",
        cadastralNumber: objectInfo?.realObject?.cadastralNumber
          ? objectInfo?.realObject?.cadastralNumber
          : "-",
        address: objectInfo?.realObject?.address?.value
          ? objectInfo?.realObject?.address?.value
          : "-",
        area: objectInfo?.area ? objectInfo?.area : "-",
      });
    }
  }, [objectInfo]);

  return (
    <>
      <div className={styles.clientObjectContent}>
        <Button
          className={styles.clientObjectBtn}
          onClick={() => navigate(`/client-cabinet/`)}
        >
          <span>{"<"}</span>
          Кабинет
        </Button>
        <div className={styles.clientObjectContainer}>
          <form className={styles.clientObjectForm}>
            <div className={styles.titleWrapper}>
              <h3 className={styles.clientObjectTitle}>Объект</h3>
            </div>
            <Input
              isFormField={true}
              variant="bordered-green"
              readOnly
              label="Тип объекта"
              name="type"
              register={register}
            />
            <Input
              isFormField={true}
              variant="bordered-green"
              readOnly
              label="Кадастровый номер"
              name="cadastralNumber"
              register={register}
            />
            <div className={styles.addressWrapper}>
              <span>Адрес</span>
              <div className={styles.addressBox}>
                {objectInfo?.realObject?.address?.value}
              </div>
            </div>
            <Input
              isFormField={true}
              variant="bordered-green"
              readOnly
              label="Площадь"
              name="area"
              register={register}
            />
          </form>
        </div>
      </div>
    </>
  );
};
