import { Input } from "../../../../components/Input";
import styles from "./TypeInfoForm.module.scss";
import { FakeCheckbox } from "../../../../components/FakeCheckbox";
import { techMistakeStatement, removeEncumbrancesAppeal } from "../../../../constants/constants";
import { unTransformDate } from "../../../../helpers/untransformDate";

export const TypeInfoForm = ({ typeData }) => {
  return (
    <form className={styles.typeInfoForm}>
      <div className={styles.fieldWrapper}>
        <label>Сумма, ₽</label>
        <Input variant="bordered-green" value={typeData?.sum} readOnly={true} />
      </div>
      <div className={styles.fieldWrapper}>
        <label>Площадь из договора, м²</label>
        <Input
          variant="bordered-green"
          value={typeData?.area}
          readOnly={true}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <label>Тип права</label>
        <Input
          variant="bordered-green"
          value={typeData?.ownership ? typeData?.ownership?.title : "-"}
          readOnly={true}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <label>Особенности</label>
        <FakeCheckbox isChecked={typeData?.mortgage} label="Ипотека" />
      </div>

      {typeData?.type?.uuid === techMistakeStatement && (
            <>
              <div className={styles.fieldWrapper}>
                <label>КУВД</label>
                <Input
                  variant="bordered-green"
                  value={typeData?.kuvd}
                  readOnly={true}
                />
              </div>

              <div className={styles.fieldWrapper}>
                <label>Дата</label>
                <Input
                  variant="bordered-green"
                  value={typeData?.statementRegDate}
                  readOnly={true}
                />
              </div>
              
              <div className={styles.fieldWrapper}>
                <label>Текущая запись</label>
                <Input
                  variant="bordered-green"
                  inputElement="textarea"
                  value={typeData?.errRecord}
                  readOnly={true}
                />
              </div>

              <div className={styles.fieldWrapper}>
                <label>Новая запись</label>
                <Input
                  variant="bordered-green"
                  inputElement="textarea"
                  value={typeData?.corrRecord}
                  readOnly={true}
                />
              </div>
            </>
          )}

          {typeData?.type?.uuid === removeEncumbrancesAppeal && (
            <>
              <div className={styles.fieldWrapper}>
                <label>Номер регистрации</label>
                <Input
                  variant="bordered-green"
                  value={typeData?.mortgageRegNumber}
                  readOnly={true}
                />
              </div>
              <div className={styles.fieldWrapper}>
                <label>Дата регистрации</label>
                <Input
                  variant="bordered-green"
                  value={unTransformDate(typeData?.mortgageRegDate)}
                  readOnly={true}
                />
              </div>
            </>
          )}
    </form>
  );
};
