import { gql } from "@apollo/client";

export const APPLICATION_INFO = gql`
  query ApplicationInfo($id: ID!) {
    application(id: $id) {
      id
      uuid
      documentComplete
      sum
      requestPackageUrl
      area
      dealParty
      statementRegDate
      previousStatementKuvd
      note
      cause
      errRecord
      corrRecord
      time
      mortgageRegDate
      mortgageRegNumber
      requestHistory {
        statusDescription
        id
        createdAt
        status
      }
      payments {
        id
        uuid
        amount
        paymentType
        createdAt
        payTillDate
        receiptUrl
      }
      ownership {
        id
        uuid
        title
      }
      mortgage
      kuvd
      realObject {
        id
        uuid
        cadastralNumber
        developer
        buildingArea
        address {
          id
          region
          regionType
          district
          locality
          house
          building
          structure
          okato
          oktmo
          index
          city
          street
          streetType
          country
          value
          block
          apartment
          regionKladrId
          physicalPerson {
            id
            uuid
            firstName
            lastName
            patronymic
            phone
            email
            signaturesCurrent {
              consumerUuid
              isActive
              state
              expireDate
              createdAt
            }
          }
        }
        type {
          id
          uuid
          title
        }
      }
      type {
        id
        uuid
        title
        roles {
          id
          uuid
          name
        }
      }
      status
      createdAt
      updatedAt
      documents {
        id
        uuid
        title
        documentsSignatures {
          id
          state
          participantUuid
          person {
            firstName
            lastName
            patronymic
          }
          documentUuid
        }
        documentType {
          id
          uuid
          name
          code
        }
        file
        sigFile
        presignedUrl
        presignedSigUrl
      }
      participants {
        id
        uuid
        participantId
        role {
          id
          uuid
          name
        }
        participantType
        physicalPerson {
          id
          uuid
          firstName
          lastName
          patronymic
          gender
          dateOfBirth
          placeOfBirth
          citizenship
          snils
          inn
          phone
          email
          createdAt
          updatedAt
          isProfileActivated
          # document {
          #   id
          #   uuid
          #   code
          # }
          # accounts {
          #   id
          #   uuid
          #   role
          #   permissionStatus
          #   deleteStatus
          #   accStatus
          #   isStaff
          #   isActive
          #   isEmailVerified
          #   apiKey
          # }
          signaturesCurrent {
            consumerUuid
            isActive
            state
            expireDate
            createdAt
            connector
            type
          }
        }
        legalPerson {
          id
          uuid
          fullName
          shortName
          ogrn
          inn
          kpp
          createdAt
          updatedAt
          phoneNumber
          email
          representatives{
            physicalPerson{
              id
              lastName
            }
            signToken
          }
          address {
            id
            region
            regionType
            district
            locality
            house
            building
            structure
            okato
            oktmo
            index
            city
            street
            streetType
            country
            value
            block
            apartment
            regionKladrId
          }
          
        }
        representative {
          id
          uuid
          firstName
          lastName
          patronymic
          gender
          dateOfBirth
          placeOfBirth
          citizenship
          snils
          inn
          createdAt
          updatedAt
          isProfileActivated
          signToken
          # document {
          #   id
          #   uuid
          #   type
          #   series
          #   number
          #   dateIssue
          #   issuedBy
          #   code
          #   physicalPerson {
          #     firstName
          #     lastName
          #     patronymic
          #   }
          # }
          signaturesCurrent {
            consumerUuid
            isActive
            state
            expireDate
            createdAt
            connector
            type
          }
        }
        legalAuthorityRecord {
          uuid
          file
          sigFile
          state
          presignedUrl
          presignedSigUrl
          documentType {
            id
            uuid
            name
            code
          }
          documentSignature{
            documentUuid
          }
        }
        applicant {
          fullName
          shortName
          id
          inn
          kpp
          ogrn
          shortName
          representatives{
            signToken
          }
        }
        shareNumerator
        shareDenominator
        legalAuthorityRecord {
          uuid
          file
          sigFile
          presignedUrl
          presignedSigUrl
          durationStart
          durationStop
        }
        documentSignatures {
          id
          signature {
            id
            consumerUuid
            isActive
            state
            dateRelease
            expireDate
            createdAt
            updatedAt
            connector
            type
            person {
              uuid
              firstName
              lastName
              patronymic
            }
          }
          document {
            id
            uuid
            file
            sigFile
            presignedUrl
            presignedSigUrl
          }
          serviceId
          state
          signatureFile
        }
      }
      additionalApplications {
        id
        uuid
        originalApplication {
          id
          uuid
          kuvd
          createdAt
          type {
            title
          }
          participants {
            id
            uuid
            physicalPerson {
              id
              uuid
              firstName
              lastName
              patronymic
            }
            representative {
              id
              uuid
              firstName
              lastName
              patronymic
            }
          }
        }
        createdAt
        updatedAt
        comment
        status
        newApplication {
          id
          uuid
          kuvd
          type {
            title
            uuid
          }
          participants {
            id
            uuid
            
          }
        }
      }
      statement {
        id
        uuid
        title
        file
        sigFile
        pdfUrl
        presignedUrl
        presignedSigUrl
        documentType{
          id
          uuid
          name
          code
        }
        documentsSignatures {
          state
          id
          person {
            firstName
            lastName
            patronymic
          }
        }
      }
    }
  }
`;
