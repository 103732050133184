import { useForm } from "react-hook-form";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button } from "../../../../../Button";
import styles from "./DocumentsBlock.module.scss";
import { BasketIcon } from "../../../../../../assets/icons/BasketIcon";
import { Select } from "../../../../../Select/ui/Select";
import { Checkbox } from "../../../../../Checkbox";
import { GET_DOCUMENT_TYPES } from "../../../../../../services/queries/queries/documentTypes";
import { ModalWindow } from "../../../../../ModalWindow";
import {
  CREATE_DOCUMENT,
  CREATE_SIGNATURE_MANUAL,
  REQUEST_DOCUMENT,
  SEND_DOCUMENT,
} from "../../../../../../services/queries/mutations/documents/documents";
import { APPLICATION_INFO } from "../../../../../../services/queries/queries/applications/applicationInfo";
import { ADD_DOC_TO_APP } from "../../../../../../services/queries/mutations/documents/addDocumentsToApplication";
import { Error } from "../../../../../Error/ui/Error";
import { toast } from "react-toastify";
import { TokenKey } from "../../../../../../assets/icons/TokenKey";
import { CryptoToken } from "../../../../../CryptoToken/ui/CryptoToken";
import { FakeCheckbox } from "../../../../../FakeCheckbox/ui/FakeCheckbox";
import { downloadFile } from "../../../../../PhysicalDocumentsUpload/ui/PhysicalDocumentsUpload";
import { downloadSigFile } from "../../../../../../helpers/downloadSigFile";
import { newRool } from "../../../../../../constants/constants";

const ParticipantBlockRow = ({
  register,
  participantFullName,
  item,
  isSent,
  documentUuid,
  title,
  fileUrl,
  documentsIds,
  applicationId,
  applicationInfo,
}) => {
  const [showKey, setShowKey] = useState(false);
  const toastRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [checkPluginStatus, setCheckPluginStatus] = useState(false);
  const [currentDocumentId, setCurrentDocumentId] = useState(null);
  const [tokenSign, setTokenSign] = useState(false);

  const signersUiids = isSent
    ? isSent?.map((object) =>
        object?.document?.uuid === documentUuid
          ? object?.signature?.person?.uuid
          : null
      )
    : [];

  const signsStatus = isSent?.map(
    (object) =>
      object?.document?.uuid === documentUuid && {
        status: object?.state,
        presignedSigUrl: object?.presignedSigUrl,
      }
  );

  const [newSignersUiids, setNewSignersUiids] = useState([...signersUiids]);

  useEffect(() => {
    // Проверяем, если документ в списке документов с состоянием "COMPLETE"
    const documentIsComplete = documentsIds?.some(
      (item) => item?.uuid === documentUuid && item.state === "COMPLETE"
    );

    // Устанавливаем showKey только если документ не завершен и чекбокс отмечен
    setShowKey(isChecked && !documentIsComplete);
  }, [documentsIds, documentUuid, isChecked]);

  function isInArray(status) {
    return signsStatus?.some((e) => e.status === status);
  }

  useEffect(() => {
    if (
      isInArray("COMPLETE") === true ||
      isInArray("WAITING_SEND") === true ||
      isInArray("WAITING") === true
    ) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }

    statusVariantRender();
  }, [signsStatus, newSignersUiids, isInArray, isSent]);

  const [sendDocument] = useMutation(SEND_DOCUMENT, {
    onCompleted: () => {
      const signers = [...signersUiids, item?.uuid];
      setNewSignersUiids(signers);
      applicationInfo({
        variables: {
          id: applicationId,
        },
      });
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const [deleteDocumentSignature] = useMutation(SEND_DOCUMENT, {
    onCompleted: () => {
      const signers = newSignersUiids?.filter(
        (e) =>
          e !== item?.uuid &&
          item?.representative?.uuid &&
          item?.physicalPerson?.uuid
      );
      setNewSignersUiids(signers, "signers");
      applicationInfo({
        variables: {
          id: applicationId,
        },
      });
    },
    onError: (err) => {
      console.log(err?.message);
    },
  });

  const getSignatures = () => {
    sendDocument({
      variables: {
        documentUuid: documentUuid,
        participantUuid: item?.uuid,
      },
    });
  };

  const deleteSignatures = () => {
    deleteDocumentSignature({
      variables: {
        documentUuid: documentUuid,
        participantUuid: item?.uuid,
      },
    });
    const signers = newSignersUiids?.filter((e) => e !== item?.uuid);
    setNewSignersUiids(signers);
  };

  const handleCheckboxChange = () => {
    if (isSent.some((item) => item?.document?.uuid === documentUuid)) {
      deleteSignatures();
    } else {
      getSignatures();
    }
  };

  const statusVariantRender = useCallback(
    (item) => {
      if (item?.document?.uuid === documentUuid) {
        if (
          item?.state === "WAITING" ||
          (item?.state === "WAITING_SEND" && isInArray("WAITING_SEND") === true)
        ) {
          return (
            <span className={styles.centralRightText}>Подпись запрошена</span>
          );
        }

        if (item?.state === "COMPLETE") {
          return (
            <span className={styles.centralRightTextActive}>Подписано</span>
          );
        }

        if (item?.state === "REJECTED") {
          return (
            <span className={styles.centralRightText}>
              Подписание отклонено
            </span>
          );
        }

        if (item?.state === "ERROR") {
          return (
            <span className={styles.centralRightText}>Ошибка подписания</span>
          );
        }
      }
    },
    [showKey]
  );

  useEffect(() => {
    if (documentsIds && documentUuid) {
      documentsIds.forEach((document) => {
        if (document?.participantUuid === item?.uuid) {
          setCurrentDocumentId(document?.id);
        }
      });
    }
  }, [documentsIds, documentUuid, isChecked]);

  useEffect(() => {
    if (item?.legalPerson && item?.legalPerson?.representatives?.length > 0) {
      const representative = item.legalPerson.representatives.find(
        (el) => el?.physicalPerson?.id === item.representative?.id
      );

      if (representative?.signToken) {
        setTokenSign(representative.signToken);
      }

      if (item?.legalPerson && item?.representative) {
        setTokenSign(item?.representative?.signToken);
      }
    }
  }, [item]);

  return (
    <div className={styles.centralBMenu}>
      <div className={styles.centralLeftText}>
        {isSent?.some(
          (item) =>
            item?.document?.uuid === documentUuid && item.state === "COMPLETE"
        ) ? (
          <FakeCheckbox isChecked={true} label={participantFullName} />
        ) : (
          <Checkbox
            name="peculiarities"
            register={register}
            label={participantFullName}
            isChecked={isChecked}
            onChange={handleCheckboxChange}
          />
        )}
      </div>
      <div className={styles.dashed}></div>
      {showKey &&
        !isSent?.some(
          (item) =>
            item?.document?.uuid === documentUuid && item.state === "COMPLETE"
        ) && (
          <abbr
            ref={toastRef}
            title={
              checkPluginStatus
                ? "Подписать токеном"
                : "Плагин КриптоПро не найден"
            }
            className={styles.tokenKey}
          >
            <CryptoToken
              showEdsBtn={false}
              tokenBtnContent={
                <TokenKey
                  className={!checkPluginStatus ? styles.negativeSvg : ""}
                />
              }
              tokenBtnClassName={styles.tokenBtn}
              fileName={title && title}
              file={fileUrl}
              setCheckPluginStatus={setCheckPluginStatus}
              applicationId={applicationId}
              documentId={currentDocumentId}
              applicationInfo={applicationInfo}
              inn={item?.representative?.inn}
              participantFullName={participantFullName}
              tokenSign={tokenSign}
            />
          </abbr>
        )}

      {!showKey ? (
        <div className={styles.centralRightText}>Подпись не запрошена</div>
      ) : (
        isSent.length > 0 &&
        isSent?.map((item) => (
          <div key={item.id}>{statusVariantRender(item)}</div>
        ))
      )}
    </div>
  );
};

export const DocumentsBlock = ({
  item,
  participants,
  deleteDocument,
  setDocuments,
  documents,
  applicationUuid,
  applicationSended,
  title,
  fileUrl,
  documentsIds,
  applicationId,
  applicationInfo,
  isCopy = false,
  applicationType,
}) => {
  const [documentName, setDocumentName] = useState("");
  const [typeArray, setTypeArray] = useState([]);
  const [documentTypeUuid, setDocumentTypeUuid] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [participantUuid, setParticipantUuid] = useState("");
  const [uuid, setUuid] = useState("");
  const [isError, setIsError] = useState(false); // есть или нет ошибки при отправке на подпись
  const [errorText, setErrorText] = useState(false); //текст ошибки при отправке на подпись
  const documentUuid = item?.uuid;
  const documentId = item?.id;

  const { register, handleSubmit, watch, setValue } = useForm();

  const watchedDocument = watch("file");

  const { data } = useQuery(GET_DOCUMENT_TYPES);

  const [addDocumentsToApplication] = useMutation(ADD_DOC_TO_APP, {
    refetchQueries: [APPLICATION_INFO],
    onCompleted: (data) => {
      setOpenModal(false);
    },
  });

  const [createDocument] = useMutation(CREATE_DOCUMENT, {
    onCompleted: (data) => {
      addDocumentsToApplication({
        variables: {
          applicationUuid: applicationUuid,
          documentsUuids: data?.createDocument?.document?.uuid,
        },
      });
    },
    onError(err) {
      console.log(err?.message);
    },
  });

  const [requestDocument, { data: requestData }] = useMutation(
    REQUEST_DOCUMENT,
    {
      refetchQueries: [APPLICATION_INFO],
      onCompleted: (data) => {
        setOpenModal(false);
        localStorage.setItem(
          "requestDocument",
          data?.physicalPersonRequestDocument?.document?.uuid
        );
      },
      onError(err) {
        console.log(err?.message);
      },
    }
  );

  const [createSignatureManual, { data: signatureManualData }] = useMutation(
    CREATE_SIGNATURE_MANUAL,
    {
      refetchQueries: [APPLICATION_INFO],
      onCompleted: (data) => {},
      onError(err) {
        console.log(err?.message);
      },
    }
  );

  useEffect(() => {
    if (data != null) {
      const newArray = data?.documentTypes?.edges?.map((item) => {
        return {
          title: item?.node?.name,
          value: item?.node?.uuid,
        };
      });
      setTypeArray(newArray);
    }
  }, [data]);

  useEffect(() => {
    setDocumentName(item);
  }, [item]);

  const onSubmit = () => {
    if (documentName && !item?.file) {
      createDocument({
        variables: {
          documentTypeUuid: documentTypeUuid,
          file: watchedDocument,
          title: watchedDocument?.name,
        },
      });
    }
  };

  useEffect(() => {
    if (watchedDocument?.name) onSubmit();
  }, [documentName]);

  const setError = () => {
    setIsError(true);
    setErrorText("Выберите тип документа");
    setTimeout(() => setIsError(false), 500);
    setTimeout(() => setErrorText(""), 500);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container}>
          <div className={styles.selectContainer}>
            <Select
              name="role"
              register={register}
              isFormSelect={true}
              className={styles.select}
              variant="transparent"
              options={typeArray}
              onChange={(e) => {
                setDocumentTypeUuid(e.target.value);
              }}
              placeholder={
                item?.documentType?.name
                  ? item?.documentType?.name
                  : "Тип документа"
              }
            />
          </div>
          {!item?.file || item?.file === "" || item?.presignedUrl === "" ? (
            <div className={styles.addBtns}>
              <div className={styles.addFileContainer}>
                <label>
                  <span className={styles.addFile}>
                    {documentName?.file
                      ? documentName?.file?.name || documentName?.title
                      : "Загрузить..."}
                  </span>
                  <input
                    name="file"
                    type="file"
                    {...register("file")}
                    className={styles.fileInput}
                    onChange={(e) => {
                      setValue("file", e.target.files[0]);
                      setDocumentName({
                        file: e.target.files[0],
                      });
                    }}
                  />
                </label>
              </div>
              <Button
                variant="transparent"
                type="button"
                className={styles.addFile}
                onClick={() =>
                  documentTypeUuid ? setOpenModal(true) : setError()
                }
              >
                Запросить у клиента...
              </Button>
            </div>
          ) : (
            <div className={styles.centralBlock}>
              <div className={styles.centralTBlock}>
                <strong>
                  <Button
                    variant="transparent"
                    type="button"
                    onClick={() =>
                      downloadFile(
                        item?.presignedUrl,
                        item?.file?.split(".")[0]?.split("/")[2] +
                          "." +
                          item?.file?.split(".")[1]
                      )
                    }
                  >
                    Документ
                  </Button>{" "}
                  загружен
                </strong>
                {item?.presignedSigUrl !== (null || "") && (
                  <strong>
                    <Button
                      variant="transparent"
                      type="button"
                      onClick={() =>
                        downloadSigFile(
                          item?.presignedSigUrl,
                          item?.file?.split(".")[0]?.split("/")[2] +
                            "." +
                            item?.file?.split(".")[1]
                        )
                      }
                    >
                      Скачать подпись
                    </Button>
                  </strong>
                )}

                <div className={styles.addFileContainer}>
                  <label>
                    <span className={styles.addFile}>Загрузить подпись...</span>
                    <input
                      name="signature"
                      type="file"
                      {...register("signature")}
                      className={styles.fileInput}
                      onChange={(e) => {
                        createSignatureManual({
                          variables: {
                            documentUuid: item?.uuid,
                            file: e.target.files[0],
                          },
                        });
                      }}
                    />
                  </label>
                </div>
              </div>
              <ul className={styles.centralBBlock}>
                {applicationType === newRool ? (
                  <li key={1}>
                    <ParticipantBlockRow
                      title={title}
                      isCopy={isCopy}
                      fileUrl={fileUrl}
                      register={register}
                      documentsIds={documentsIds}
                      applicationId={applicationId}
                      applicationInfo={applicationInfo}
                      participantFullName={
                        participants[participants.length - 1]?.representative
                          ? participants[participants.length - 1]
                              ?.representative?.lastName +
                            " " +
                            participants[participants.length - 1]
                              ?.representative?.firstName +
                            " " +
                            participants[participants.length - 1]
                              ?.representative?.patronymic
                          : !participants[participants.length - 1]
                              ?.representative &&
                            participants?.[participants.length - 1]
                              ?.physicalPerson
                          ? participants[participants.length - 1]
                              ?.physicalPerson?.lastName +
                            " " +
                            participants[participants.length - 1]
                              ?.physicalPerson?.firstName +
                            " " +
                            participants[participants.length - 1]
                              ?.physicalPerson?.patronymic
                          : null
                      }
                      item={participants[participants.length - 1]}
                      isSent={
                        participants[participants.length - 1]
                          ?.documentSignatures
                      }
                      documentUuid={documentUuid}
                      documentId={documentId}
                    />
                  </li>
                ) : (
                  participants?.map(
                    (item, index) =>
                      (item?.representative ||
                        (!item?.representative && item?.physicalPerson)) && (
                        <li key={index}>
                          <ParticipantBlockRow
                            title={title}
                            isCopy={isCopy}
                            fileUrl={fileUrl}
                            register={register}
                            documentsIds={documentsIds}
                            applicationId={applicationId}
                            applicationInfo={applicationInfo}
                            participantFullName={
                              item?.representative
                                ? item?.representative?.lastName +
                                  " " +
                                  item?.representative?.firstName +
                                  " " +
                                  item?.representative?.patronymic
                                : !item?.representative && item?.physicalPerson
                                ? item?.physicalPerson?.lastName +
                                  " " +
                                  item?.physicalPerson?.firstName +
                                  " " +
                                  item?.physicalPerson?.patronymic
                                : null
                            }
                            item={item}
                            isSent={item?.documentSignatures}
                            documentUuid={documentUuid}
                            documentId={documentId}
                          />
                        </li>
                      )
                  )
                )}

                {item?.documentsSignatures?.map(
                  (document, index) =>
                    !document.participantUuid && (
                      <li className={styles.active} key={index}>
                        <div className={styles.centralBMenu}>
                          <div className={styles.signText}>
                            Подпись загружена вручную
                          </div>
                          <div className={styles.dashed}></div>
                          <div className={styles.centralRightTextActive}>
                            Подписано
                          </div>
                        </div>
                      </li>
                    )
                )}
              </ul>
            </div>
          )}
          <div className={styles.deleteBtn}>
            {applicationSended && (
              <Button
                variant="transparent"
                type="button"
                onClick={() => {
                  item?.uuid
                    ? deleteDocument({
                        variables: {
                          uuid: item?.uuid,
                        },
                      }).then(() => {
                        if (applicationInfo && applicationId) {
                          applicationInfo({
                            variables: {
                              id: applicationId,
                            },
                          });
                        }
                      })
                    : setDocuments(documents.filter((i) => i.id !== item.id));
                }}
              >
                <BasketIcon />
              </Button>
            )}
          </div>
        </div>
      </form>

      <ModalWindow
        show={openModal}
        setShowModal={() => setOpenModal(false)}
        dialogClassName={styles.newModal}
      >
        <h2>Запросить документ</h2>
        <ul>
          {participants?.map((item, index) => (
            <>
              {item?.physicalPerson && (
                <li
                  className={
                    participantUuid === item?.physicalPerson?.uuid &&
                    styles.active
                  }
                  key={index}
                  onClick={() => {
                    setParticipantUuid(item?.physicalPerson?.uuid);
                    setUuid(item?.uuid);
                  }}
                >
                  {item?.physicalPerson?.lastName +
                    " " +
                    item?.physicalPerson?.firstName +
                    " " +
                    item?.physicalPerson?.patronymic}
                </li>
              )}
              {item?.representative && (
                <li
                  className={
                    participantUuid === item?.representative?.uuid &&
                    styles.active
                  }
                  key={index}
                  onClick={() => {
                    setParticipantUuid(item?.representative?.uuid);
                    setUuid(item?.uuid);
                  }}
                >
                  {item?.representative?.firstName +
                    " " +
                    item?.representative?.lastName +
                    " " +
                    item?.representative?.patronymic}
                </li>
              )}
            </>
          ))}
        </ul>
        <Button
          variant="green"
          type="button"
          onClick={() => {
            requestDocument({
              variables: {
                applicationUuid: applicationUuid,
                participantUuid: uuid,
                documentTypeUuid: documentTypeUuid,
              },
            });
          }}
        >
          Запросить документ
        </Button>
      </ModalWindow>

      <Error error={isError} errorText={errorText} />
    </>
  );
};
