import { useForm } from "react-hook-form";
import styles from "./ContractComment.module.scss";
import { Button } from "../../Button";
import { Input } from "../../Input";
import { useNavigate } from "react-router-dom";
import cn from 'classnames'
import { useMutation } from "@apollo/client";
import { READ_DOCUMENT } from "../../../services/queries/mutations/client/readDocument";
import { CLIENT_INFO_PROFILE } from "../../../services/queries/queries/client/clientInfo";

export const ContractComment = ({documentUuid, setShowComment, className}) => {

  const navigate = useNavigate()
  const [readDocument] = useMutation(READ_DOCUMENT, {
    refetchQueries: [CLIENT_INFO_PROFILE],
    fetchPolicy: 'no-cache'
  })


  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });

  const userComment = watch('contractComment')

  const onSubmitHandler = (formData) => {

    if(documentUuid && formData.contractComment || documentUuid && userComment){
      readDocument({
        variables: {
          personDocumentUuid: documentUuid,
          description: formData?.contractComment || userComment,
          isConfirmed: false
        }
      }).then(() => navigate('/client-cabinet/'))
    }
    
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className={cn(styles.contractFormInner, className)}>
        <Button type="button" onClick={() => setShowComment(false)} variant='transparent-green'>
          Отмена
        </Button>
        <Input
          type="textarea"
          isFormField={true}
          name="contractComment"
          className={styles.commentField}
          placeholder="Оставьте комментарий"
          register={register}
          registerObj={{ required: "Поле обязательно для заполнения" }}
          size="full"
          errors={errors}
        />
        <Button type="submit" variant='transparent-green'>Отправить</Button>
      </div>
    </form>
  );
};
