import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { BaseFinderTemplate } from "../../../../../BaseFinderTemplate/BaseFinderTemplate";
import { Button } from "../../../../../Button";
import styles from "./IndividualBaseFinder.module.scss";
import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";
import { PHYSICALPERSONSLIST } from "../../../../../../services/queries/queries/physicalPersonsList";
import {
  selectParticipants,
  setParticipant,
  setParticipantUpdated,
} from "../../../../../../redux/slices/application";
import { checkObjectFields } from "../../../../../../helpers/checkObjectFields";
import { StatusLabel } from "../../../../../StatusLabel/ui/StatusLabel";

const ListRow = ({ data, onAdd, choosenElId }) => {
  return (
    <li
      className={cn(styles.row, {
        [styles.active]: choosenElId === data?.node?.id,
      })}
      onClick={() => {
        onAdd(data?.node?.id);
      }}
    >
      <div className={styles.name}>
        {data?.node?.lastName} {data?.node?.firstName} {data?.node?.patronymic}
      </div>

      {data?.acp && (
        <div className={styles.acp}>
          <span>ЭЦП {data?.node?.acp}</span>
        </div>
      )}
    </li>
  );
};

const IndividualCard = ({ data, onAdd }) => {
  const items = useMemo(() => {
    return [
      {
        name: "surname",
        label: "Фамилия",
        value: data?.node?.lastName,
      },
      { name: "name", label: "Имя", value: data?.node?.firstName },
      {
        name: "patronymic",
        label: "Отчество",
        value: data?.node?.patronymic,
      },
      { name: "sex", label: "Пол", value: data?.node?.sex },
      { name: "dob", label: "Дата рождения", value: data?.node?.dob },
      {
        name: "birthplace",
        label: "Место рождения",
        value: data?.node?.placeOfBirth,
      },
      {
        name: "registrationAddress",
        label: "Адрес регистрации",
        value: data?.node?.registrationAddress?.value,
      },
      {
        name: "citizenship",
        label: "Гражданство",
        value: data?.node?.citizenship,
      },
      {
        name: "passport",
        label: "Серия и номер паспорта",
        value:
          data?.node?.document?.series + " " + data?.node?.document?.number,
      },
      { name: "phone", label: "Телефон", value: data?.node?.phone },
      { name: "email", label: "Почта", value: data?.node?.email },
      {
        name: "acp",
        label: "ЭЦП",
        value: data?.node?.acp
          ? `${data?.acp}, действует до ${data?.node?.acpLimit}`
          : "",
      },
    ];
  }, [data]);

  return (
    <div className={styles.individualCard}>
      <div>
        <h2 className={styles.cardTitle}>Физлицо</h2>
        {checkObjectFields(data?.node) ? (
          <StatusLabel text="Не все данные заполнены" variant="negative" />
        ) : null}
      </div>

      <div className={styles.mainInfo}>
        {items?.map(
          (item) =>
            item?.value && (
              <div
                key={item.name}
                className={cn(styles.mainInfoRow, styles[`${item?.name}`])}
              >
                <span className={styles.label}>{item?.label} </span>
                <span className={styles.value}>{item?.value}</span>
              </div>
            )
        )}
      </div>
      <Button variant="green" onClick={() => onAdd(data)} type="button">
        Добавить участника
      </Button>
    </div>
  );
};

export const IndividualBaseFinder = ({
  individualData,
  setShow,
  participantType,
  setData,
  setParticipantType,
  role,
  cardId,
}) => {
  const [choosenIndividual, setChoosenIndividual] = useState(null);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500)
  const dispatch = useDispatch();
  const participants = useSelector(selectParticipants);
  const onAdd = useCallback(
    (value) => {
      const newIndividual = {
        id: cardId,
        firstName: value?.node?.firstName,
        lastName: value?.node?.lastName,
        patronymic: value?.node?.patronymic,
        uuid: value?.node?.uuid,
        signaturesCurrent: value?.node?.signaturesCurrent,
        participantType: participantType,
      };

      if (participantType === "representative") {
        const participant = {
          id: cardId,
          role: role,
          participantType: "PHYSICAL_PERSON",
          representative: [newIndividual],
        };
        dispatch(setParticipant(participant));
        setParticipantType("PHYSICAL_PERSON");
      } else {
        const participant = {
          id: cardId,
          physicalPerson: newIndividual,
          participantType: "PHYSICAL_PERSON",
          role: role,
        };
        dispatch(setParticipant(participant));
        setParticipantType("PHYSICAL_PERSON");
      }

      if (individualData?.uuid) {
        dispatch(setParticipantUpdated({ uuid: individualData.uuid }));
      }
      setData && setData(newIndividual);
      setShow(false);
    },
    [individualData, participantType, setShow]
  );

  const { data } = useQuery(PHYSICALPERSONSLIST, {
    variables: {
      search: debouncedSearch,
      first: 10,
      offset: 0,
    },
  });

  const array = data?.allPhysicalPersons?.edges;

  const onChoose = useCallback(
    (id) => {
      const individual = array?.find((item) => item?.node?.id === id);
      setChoosenIndividual(individual);
    },
    [array]
  );

  return (
    <BaseFinderTemplate
      inputLabel="Поиск по базе физлиц"
      isEmpty={!search}
      emptyText="Начните вводить имя, номер паспорта, снилс, или инн физлица"
      listClassnames={styles.list}
      withList={true}
      ListRow={ListRow}
      data={search !== "" ? array : []}
      onAdd={onChoose}
      choosenElId={choosenIndividual?.node?.id}
      setSearch={setSearch}
    >
      {choosenIndividual && (
        <IndividualCard data={choosenIndividual} onAdd={onAdd} />
      )}
    </BaseFinderTemplate>
  );
};
