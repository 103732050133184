import { useState, useCallback, useEffect } from "react";
import styles from "./PluginInfo.module.scss";
import { CryptoPro } from "ruscryptojs";
import { formattedDateFunc } from "../../../../helpers/formattedDateFunc";
import { DocumentsList } from "../DocumentsList/DocumentsList";
import { CryptoTokenMassive } from "../../../../components/CryptoTokenMassive/ui/CryptoTokenMassive";
import { Preloader } from "../../../../components/Preloader";

export const PluginInfo = ({
  currentCertificate,
  setCurrentCertificate,
  documents,
  loading,
  error
}) => {
  const [certificates, setCertificates] = useState([]);
  const [certificateInfo, setCertificateInfo] = useState(null);
  const [certificateNumber, setCertificateNumber] = useState(1)

  

  const cryptopro = new CryptoPro();


  cryptopro.init().then(function (info) {
    console.log("Initialized", info);
  });

  const getCertificatesList = useCallback(() => {
    cryptopro.listCertificates().then((data) => {
      setCertificates(data);
      setCurrentCertificate(data[0]?.id);
    });
  }, [cryptopro]);

  useEffect(() => {
    getCertificatesList();
  }, []);

  useEffect(() => {
    if (currentCertificate) {
      cryptopro
        .certificateInfo(currentCertificate)
        .then((data) => setCertificateInfo(data));
    }
  }, [currentCertificate]);

  return (
    <div className={styles.pluginInfoContainer}>
      <div className={styles.pluginInfoWrapper}>
        <div className={styles.certificatesBlock}>
          <h3>Выберите сертификат</h3>
          <div className={styles.sertificatesInner}>
            {certificates.map((certificate, index) => (
              <span
                key={certificate?.id}
                className={
                  currentCertificate === certificate?.id
                    ? styles.activeCertificate
                    : ""
                }
                onClick={() => {
                  setCurrentCertificate(certificate?.id)
                  setCertificateNumber(index + 1)
                }}
              >
                CN={certificate?.name}; Выдан:{" "}
                {formattedDateFunc(String(certificate?.validFrom))}
              </span>
            ))}
          </div>
          <div className={styles.certInfo}>
            <h3>Информация о сертификате</h3>
            {certificateInfo && (
              <div className={styles.infoInner}>
                <span>Владелец: CN={certificateInfo?.Name}</span>
                <span>Издатель: CN={certificateInfo?.Issuer?.CN}</span>
                <span>
                  Выдан:{" "}
                  {String(
                    new Date(certificateInfo?.ValidFromDate).toLocaleString(
                      "ru-RU"
                    )
                  )}
                </span>
                <span>
                  Действителен до:{" "}
                  {String(
                    new Date(certificateInfo?.ValidToDate).toLocaleString(
                      "ru-RU"
                    )
                  )}
                </span>
                <span>Криптопровайдер: CN={certificateInfo?.ProviderName}</span>
                <span>
                  Добавлен в хранилище:{" "}
                  {certificateInfo?.HasPrivateKey ? "Да" : "Нет"}
                </span>
                <span>Алгоритм: {certificateInfo?.Algorithm}</span>
                <span>
                  Статус:{" "}
                  {certificateInfo?.IsValid
                    ? "Действителен"
                    : "Не действителен"}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.documentsList}>
          {loading ? (
            <Preloader />
          ) : error !== '' ? (
            <span>{error}</span>
          ) : (
            <>
              <h3>Документы для подписания</h3>
              <DocumentsList documents={documents} />
              <CryptoTokenMassive
                documents={documents}
                currentCertificate={currentCertificate}
                certificateInfo={certificateInfo}
                setCurrentCertificate={setCurrentCertificate}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
