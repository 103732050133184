import { gql } from "@apollo/client";

export const AUTH = gql`
  mutation Auth($password: String = "", $login: String = "") {
    auth(password: $password, login: $login) {
      refreshToken
      token
      user {
        id
        role
        username
        uuid
        companyName
        profile{
          id
        }
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String = "") {
    refreshToken(refreshToken: $refreshToken) {
      refreshToken
      token
    }
  }
`;

export const PASSWORD_RECOVERY = gql`
  mutation SendPasswordResetEmail($email: String = "", $phone: String = "") {
    sendPasswordResetEmail(email: $email, phone: $phone) {
      success
    }
  }
`;

export const PASSWORD_RESET = gql`
  mutation ResetPassword($newPassword: String = "", $token: String = "") {
    resetPassword(newPassword: $newPassword, token: $token) {
      success
    }
  }
`;

export const REGISTER_USER = gql`
  mutation register(
    $password: String = ""
    $username: String!
    $token: String = ""
  ) {
    register(
      password: $password
      username: $username
      token: $token
    ) {
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($newPassword: String!, $oldPassword: String!) {
    changePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
      success
    }
  }
`;
