import { gql } from "@apollo/client";

export const UPDATE_APPLICATION_OBJECT = gql`
	mutation updateApplicationRealObject(
		$applicationUuid: UUID!
        $realObjectUuid: UUID!
	) {
		updateApplicationRealObject(
			applicationUuid: $applicationUuid
			realObjectUuid: $realObjectUuid
		) {
			application {
				id
			}
		}
	}
`;