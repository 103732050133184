import { useEffect, useState } from "react";
import { Select } from "../../../Select/ui/Select";
import { useQuery } from "@apollo/client";
import { GET_ALL_TYPES } from "../../../../services/queries/queries/allApplicationTypes";

/* 
Компонент для фильтрации заявок по типу

Пропсы:

type - выбранный тип

setType - передача выбранного типа


*/

export const ApplicationsTypeFilter = ({
  type,
  setType,
  setCurrentPageFilter,
  setCurrentPage,
}) => {
  const { data: allTypes } = useQuery(GET_ALL_TYPES);

  const [types, setTypes] = useState([]);
  const currentType = sessionStorage.getItem("currentType");

  useEffect(() => {
    allTypes?.allApplicationTypes?.edges?.forEach((item) => {
      if (
        !types.includes(item?.node?.title) &&
        item?.node?.title != undefined
      ) {
        setTypes([...types, item?.node?.title]);
      }
    });
  }, [allTypes, types]);

  useEffect(() => {
    if (type && type !== "Тип обращения в РР") {
      setType(type);
      sessionStorage.setItem("currentType", type);
    }

    if (type === "Тип обращения в РР" || type === "" || !type) {
      sessionStorage.removeItem("currentType");
      setType("");
    }
  }, [type]);

  useEffect(() => {
    if (currentType) {
      setType(currentType);
      setCurrentPageFilter(0);
      setCurrentPage(0);
    }
  }, [currentType]);

  return (
    <Select
      variant="bordered-green"
      size="short"
      value={type}
      setValue={setType}
    >
      <option value=''>Тип обращения в РР</option>
      {types.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </Select>
  );
};
