import { gql } from "@apollo/client";

export const SEARCH_REPRESENTATIVE = gql`
  query SearchRepresentative(
  $search: String
){
  allPhysicalPersons(search: $search){
    edges{
      node{
        uuid
        firstName
        lastName
        patronymic
        snils
        signaturesActive{
          connector
        }
      }
    }
  }
}

`