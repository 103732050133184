import { useForm } from "react-hook-form";
import styles from "./ChangePasword.module.scss";
import { Input } from "../../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import { getOldPassword, setOldPassword } from "../../../redux/slices/user";
import { Button } from "../../../components/Button";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../../services/queries/queries/login";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onBlur",
  });

  const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,16}/;

  const oldPassword = useSelector(getOldPassword);
  const watchedOldPassword = watch("oldPassword");
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const dispatch = useDispatch();
  const [isChange, setIsChange] = useState(false);
  const navigate = useNavigate();

  const onSubmit = (formData) => {
    changePassword({
      variables: {
        newPassword: formData.newPassword,
        oldPassword: formData.oldPassword,
      },
    }).then(() => {
      dispatch(setOldPassword(formData.oldPassword));
      setIsChange(true);
    });
  };

  const repeatEntry = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')

    navigate('/login')
  }

  return (
    <div className={styles.changeContainer}>
      <h4>Сменить пароль</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          isFormField={true}
          name="newPassword"
          label="Новый пароль"
          register={register}
          variant="white-bg"
          errors={errors}
          className={styles.changeInput}
          registerObj={{
            required: "Укажите новый пароль",
            pattern: {
              value: regPassword,
              message: `Пароль не соответствует требованиям`,
            },
          }}
        />
        <Input />
        <Input
          isFormField={true}
          name="oldPassword"
          label="Старый пароль"
          register={register}
          variant="white-bg"
          errors={errors}
          className={styles.changeInput}
          registerObj={{
            required: "Укажите старый пароль",
            pattern: {
              value: oldPassword === watchedOldPassword,
              message: `Пароли не совпадают`,
            },
          }}
        />
        <Input />
        <Button variant="green">Сменить пароль</Button>
        {isChange && <h6>Пароль успешно изменен. <span onClick={repeatEntry}>Войти с новыми данными</span></h6>}
      </form>
    </div>
  );
};
