import { useForm } from "react-hook-form";
import cn from "classnames";
import { Input } from "../Input";
import styles from "./BaseFinderTemplate.module.scss";

export const BaseFinderTemplate = ({
  inputLabel,
  isEmpty,
  emptyText,
  children,
  withList,
  data,
  ListRow,
  onAdd,
  choosenElId,
  listClassnames,
  setSearch,
}) => {
  const {
    register,
  } = useForm();

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <label>{inputLabel}</label>
        <Input
          className={styles.input}
          name="name"
          variant="transparent"
          register={register}
          isFormField={true}
          onChange={(e) => {
            setSearch && setSearch(e.target.value);
          }}
        />
      </div>

      <div className={styles.resultContainer}>
        {isEmpty ? (
          <div className={styles.resultPlaceholder}>{emptyText}</div>
        ) : (
          <>
            <div className={styles.result}>
              {withList && (
                <ul className={cn(styles.list, listClassnames)}>
                  {data?.map((item, index) => (
                    <ListRow
                      key={index}
                      data={item}
                      onAdd={onAdd}
                      choosenElId={choosenElId}
                    />
                  ))}
                </ul>
              )}
              {children}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
