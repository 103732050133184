import { createSlice } from "@reduxjs/toolkit";

const initialState = { token: "", refreshToken: "", oldPassword: "", uuid: "" };

const userSlice = createSlice({
	name: "user",
	initialState: initialState,
	reducers: {
		setTokens(state, action) {
			localStorage.setItem("accessToken", action.payload.token);
			localStorage.setItem("refreshToken", action.payload.refreshToken);

			return {
				...state,
				token: action.payload.token,
				refreshToken: action.payload.refreshToken,
			};
		},
        setUserData(state, action) {
			return {
				...state,
				...action.payload,
			};
		},
		setDocument(state, action) {
			return {
				...state,
				document: action.payload.token,
			};
		},

    setOldPassword(state, action) {
      return {
        ...state,
        oldPassword: action.payload
      }
    },

    setUserUuid(state, action){
      return{
        ...state,
        uuid: action.payload
      }
    }
	},
	selectors: {
		selectToken: (state) => {
			const token = localStorage.getItem("accessToken");
			if (token) {
				return token;
			}
			return state.token;
		},
		selectRefreshToken: (state) => {
			const refreshToken = localStorage.getItem("refreshToken");
			if (refreshToken) {
				return refreshToken;
			}
			return state.refreshToken;
		},
		selectDocument: (state) => {
			return state.document;
		},

    getOldPassword: (state) => {
      return state.oldPassword
    },

    getUserUuid: (state) => {
      return state.uuid
    }
	},
});

export const { setTokens, setDocument, setUserData, setOldPassword, setUserUuid } = userSlice.actions;
export const { selectToken, selectRefreshToken, selectDocument, getOldPassword, getUserUuid } = userSlice.selectors;
export default userSlice.reducer;
