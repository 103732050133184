import { useForm } from "react-hook-form";
import styles from "./CreateObjectForm.module.scss";
import { Input } from "../../../Input";
import { Select } from "../../../Select/ui/Select";
import { Button } from "../../../Button";
import { useEffect, useState } from "react";
import useInputSearch from "../../../../hooks/useInputSearch";
import {
  CREATE_REAL_OBJECT,
  DELETE_OBJECT,
  UPDATE_REAL_OBJECT,
} from "../../../../services/mutations/object";
import { useMutation, useQuery } from "@apollo/client";
import {
  ALL_OBJECT_TYPES,
  ALL_REAL_OBJECT,
  GET_ADDRESS_OBJECT,
} from "../../../../services/queries/queries/object";
import { SearchAddressInput } from "../SearchAddressInput/SearchAddressInput";
import { toast } from "react-toastify";

export const CreateObjectForm = ({
  objectData,
  hideModal,
  item,
  setShowModal,
  applicationData,
  setObjectData,
  setObjectType,
  isCopy,
  applicationId,
  applicationInfo,
}) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: objectData?.node || applicationData || item,
  });

  const watchedCadastralNumber = watch("cadastralNumber");
  const watchedBuildingArea = watch("buildingArea");
  const watchedType = watch("type");

  const id = item?.id || objectData?.node?.id || applicationData?.id;
  const { data: objectDataAddress } = useQuery(GET_ADDRESS_OBJECT, {
    variables: { id: id },
    fetchPolicy: "no-cache",
  });

  const [createObject] = useMutation(CREATE_REAL_OBJECT, {
    refetchQueries: [ALL_REAL_OBJECT],
  });
  const [updateRealObject] = useMutation(UPDATE_REAL_OBJECT, {
    refetchQueries: [
      { query: GET_ADDRESS_OBJECT, variables: { id: id } },
      ALL_REAL_OBJECT,
    ],
  });
  const [deleteObject] = useMutation(DELETE_OBJECT, {
    refetchQueries: [ALL_REAL_OBJECT],
  });

  const [object, setObject] = useState(null);
  const { search, setSearch, data } = useInputSearch("", "GET_ADDRESSES");

  const [objectsType, setObjectsType] = useState();
  const [optionsMap, setOptionsMap] = useState({});

  const { data: dataTypes } = useQuery(ALL_OBJECT_TYPES);

  const [otherToAddress, setOtherToAddress] = useState(
    objectDataAddress?.realObject?.address || applicationData?.address || {}
  ); // Вытекающие из адреса (locality, city etc)
  const [addressError, setAddressError] = useState(false);

  const formatAddress = [
    otherToAddress?.index || "",
    otherToAddress?.region || "",
    otherToAddress?.district || "",
    otherToAddress?.locality || otherToAddress?.locality !== "None None"
      ? otherToAddress?.locality
      : "",
    otherToAddress?.settlementWithType || "",
    otherToAddress?.locationName || "",
    otherToAddress?.street || "",
    otherToAddress?.house ? "дом " + otherToAddress?.house : "",
    otherToAddress?.block
      ? "корпус " + otherToAddress?.block
      : otherToAddress?.building
      ? "корпус " + otherToAddress?.building
      : "",
    otherToAddress?.apartment ? "кв " + otherToAddress?.apartment : "",
  ]
    .filter(Boolean)
    .join(", ");

  useEffect(() => {
    if (dataTypes?.allObjectTypes?.edges) {
      const types = dataTypes.allObjectTypes.edges.map((item) => [
        item?.node?.uuid,
        item?.node?.title,
      ]);

      setObjectsType(types);

      const map = types.reduce((acc, [uuid, name]) => {
        acc[name] = uuid;
        return acc;
      }, {});
      setOptionsMap(map);
    }
  }, [dataTypes]);

  const handleSelectChange = (e) => {
    const selectedName = e.target.value;
    const selectedUUID = optionsMap[selectedName];
    setObject(selectedUUID);
    setObjectType && setObjectType(selectedName);
  };

  const options = [
    ...(applicationData?.type ? [applicationData?.type] : []),
    ...(item?.type?.[0] ? [item.type[0]] : []),
    ...(objectsType?.length > 0 ? objectsType.map((data) => data[1]) : []),
  ];
  const uniqueOptions = [...new Set(options)].filter(Boolean);
  const updateObjectForm = (obj) => {
    const inputAddress = {
      block: otherToAddress?.block,
      city: otherToAddress?.city,
      country: otherToAddress?.country,
      district: otherToAddress?.district,
      house: otherToAddress?.house,
      index: otherToAddress?.index,
      locality: otherToAddress?.locality,
      okato: otherToAddress?.okato,
      oktmo: otherToAddress?.oktmo,
      region: otherToAddress?.region,
      street: otherToAddress?.street,
      streetType: otherToAddress?.typeStreet,
      structure: otherToAddress?.structure,
      value: formatAddress?.length > 0 ? formatAddress : search,
      apartment: otherToAddress?.apartment,
      regionKladrId: otherToAddress?.regionKladrId,
      fias: otherToAddress?.fias,
      kladr: otherToAddress?.kladr,
      building: otherToAddress.building
    };

    const inputObject = {
      cadastralNumber: watchedCadastralNumber || null,
      buildingArea: watchedBuildingArea,
      typeId: object || watchedType.uuid,
      developer: null,
    };

    const variables = {
      cadastralNumber: watchedCadastralNumber,
      block: otherToAddress?.block,
      buildingArea: watchedBuildingArea,
      city: otherToAddress?.city,
      country: otherToAddress?.country,
      district: otherToAddress?.district,
      house: otherToAddress?.house,
      index: otherToAddress?.index,
      locality: otherToAddress?.locality,
      okato: otherToAddress?.okato,
      oktmo: otherToAddress?.oktmo,
      region: otherToAddress?.region,
      street: otherToAddress?.street,
      streetType: otherToAddress?.typeStreet,
      structure: otherToAddress?.structure,
      value: formatAddress?.length > 0 ? formatAddress : search,
      apartment: otherToAddress?.apartment,
      regionKladrId: otherToAddress?.regionKladrId,
      fias: otherToAddress?.fias,
      kladr: otherToAddress?.kladr,
      building: otherToAddress.building
    };
    if (!Array.isArray(object)) {
      variables.typeId = object;
    }
    updateRealObject({
      variables: {
        realObjectUuid: objectDataAddress?.realObject.uuid,
        inputAddress,
        inputObject,
      },
    }).then((res) => {
      if (applicationId) {
        applicationInfo({
          variables: {
            id: applicationId,
          },
        });
      }

      if (hideModal) {
        hideModal(false);
      }
    });
  };

  const saveObjectForm = (obj) => {
    if (Array.isArray(object) || !object) {
      return toast.error("Укажите тип объекта");
    }
    createObject({
      variables: {
        cadastralNumber: watchedCadastralNumber,
        block: otherToAddress?.block,
        buildingArea: watchedBuildingArea,
        city: otherToAddress?.city,
        country: otherToAddress?.country,
        district: otherToAddress?.district,
        house: otherToAddress?.house,
        index: otherToAddress?.index,
        locality: otherToAddress?.locality,
        okato: otherToAddress?.okato,
        oktmo: otherToAddress?.oktmo,
        region: otherToAddress?.region,
        street: otherToAddress?.street,
        streetType: otherToAddress?.typeStreet,
        structure: otherToAddress?.structure,
        value: formatAddress.length > 0 ? formatAddress : search,
        typeId: object,
        regionKladrId: otherToAddress?.regionKladrId,
        apartment: otherToAddress?.apartment,
        fias: otherToAddress?.fias,
        kladr: otherToAddress?.kladr,
        building: otherToAddress.building
      },
    }).then((res) => {
      setObjectData &&
        setObjectData([res?.data?.createRealObject?.realObjectData]);
      if (!errors?.root?.serverError) {
        setShowModal(false);
        if (hideModal) {
          hideModal(null);
        }
      }
    });
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const objId = item?.uuid;
    try {
      const response = await deleteObject({ variables: { objId } });
      if (response.data.deleteRealObject.success) {
        hideModal(false);
      } else {
        console.log("Ошибка");
      }
    } catch (e) {
      console.error("Ошибка", e);
    }
  };
  return (
    <form>
      <div className={styles.formInner}>
        <h3 className={styles.formTitle}>Объект</h3>
        <div className={styles.objectFormWrapper}>
          <Select
            isFormSelect={true}
            name="type"
            label="Тип объекта"
            variant="bordered-green"
            onChange={handleSelectChange}
            options={uniqueOptions}
            register={register}
            size="full"
            placeholder={
              objectDataAddress?.realObject?.type?.title
                ? objectDataAddress?.realObject?.type?.title
                : "Выберите тип"
            }
            registerObj={{ required: "Поле обязательно для заполнения" }}
            errors={errors}
          />

          <Input
            isFormField={true}
            label="Кадастровый номер"
            name="cadastralNumber"
            variant="bordered-green"
            size="full"
            register={register}
            registerObj={{ required: "Поле обязательно для заполнения" }}
            errors={errors}
          />
          <SearchAddressInput
            defaultAddress={
              objectData?.node?.address ||
              applicationData?.address ||
              item?.address
            }
            data={data}
            setSearch={setSearch}
            defaultValues={objectDataAddress?.realObject.address || {}}
            setOtherToAddress={setOtherToAddress}
            otherToAddress={otherToAddress}
            formatAddress={formatAddress}
            addressError={addressError}
            setAddressError={setAddressError}
          />
          <Input
            isFormField={true}
            label="Площадь"
            name="buildingArea"
            variant="bordered-green"
            size="full"
            register={register}
            registerObj={{ required: "Поле обязательно для заполнения" }}
            errors={errors}
          />
        </div>
        <Button
          variant="green"
          type="button"
          onClick={() => {
            formatAddress === "" && setAddressError(true);
            item?.uuid || applicationData?.uuid
              ? updateObjectForm()
              : saveObjectForm();
          }}
        >
          Сохранить
        </Button>
        {!isCopy && (
          <Button
            variant="red"
            className={styles.deleteBtn}
            onClick={(e) => handleDelete(e)}
          >
            Удалить
          </Button>
        )}
      </div>
    </form>
  );
};
