import { useMemo } from "react";
import styles from "./CoincidenceSearch.module.scss";

export const CoincidenceSearch = ({ item, searchValue }) => {
  const coincidenceSearchVariantRender = useMemo(() => {
    const person = item?.participants?.filter(
      (participant) => participant?.physicalPerson
    );
    const legalPerson = item?.participants?.filter(
      (participant) => participant?.legalPerson
    );

    const representative = item?.participants?.filter(
      (participant) => participant?.representative
    );

    const searchWords = searchValue.trim().toLowerCase().split(" ");

    const inn = searchWords.find((word) => legalPerson.find(item => item?.legalPerson?.inn === word))

    const matchedPerson = person?.find((item) => {
      const fullName = item?.physicalPerson?.fullName?.trim().toLowerCase();
      return searchWords.every((word) => fullName?.includes(word));
    });

    const matchedLegalPerson = legalPerson?.find((item) => {
      const fullName = item?.legalPerson?.fullName?.trim().toLowerCase();
      return searchWords.every((word) => fullName?.includes(word));
    });

    const matchedRepresentative = representative?.find((item) => {
      const fullName = item?.representative?.fullName?.trim().toLowerCase();
      return searchWords.every((word) => fullName?.includes(word));
    });

    if (searchValue !== "") {
      if (matchedPerson) {

        const fullName =
          matchedPerson?.physicalPerson?.lastName +
          " " +
          matchedPerson?.physicalPerson?.firstName +
          " " +
          matchedPerson?.physicalPerson?.patronymic;

        return (
          <div className={styles.infoBottom}>
            <div className={styles.valueInfo}>
              <div>
                <span>{matchedPerson?.role?.name}: </span>
                <span>{fullName}</span>
              </div>
            </div>
          </div>
        );
      }

      if (matchedRepresentative?.representative) {

        const fullName =
          matchedRepresentative?.representative?.lastName +
          " " +
          matchedRepresentative?.representative?.firstName +
          " " +
          matchedRepresentative?.representative?.patronymic;

        return (
          <div className={styles.infoBottom}>
            <div className={styles.valueInfo}>
              <div>
                <span>{'Представитель по сделке'}: </span>
                <span>{fullName}</span>
              </div>
            </div>
          </div>
        );
      }

      if (matchedLegalPerson) {
        const shortName = matchedLegalPerson?.legalPerson?.shortName;

        return (
          <div className={styles.infoBottom}>
            <div className={styles.valueInfo}>
              <div>
                <span>{matchedLegalPerson?.role?.name}: </span>
                <span>{shortName}</span>
              </div>
            </div>
          </div>
        );
      }

      if(inn){
        return (
          <div className={styles.infoBottom}>
            <div className={styles.valueInfo}>
              <div>
                <span>Инн юрлица: </span>
                <span>{inn}</span>
              </div>
            </div>
          </div>
        );
      }

      if(searchWords.every((word) => item?.realObject?.address?.value?.toLowerCase().includes(word.toLowerCase()))){
        return (
          <div className={styles.infoBottom}>
            <div className={styles.valueInfo}>
              <div>
                <span>Адрес: </span>
                <span>{item?.realObject?.address?.value}</span>
              </div>
            </div>
          </div>
        );
      }

      if(searchWords.every((word) => item?.realObject?.cadastralNumber?.includes(word))){
        return (
          <div className={styles.infoBottom}>
            <div className={styles.valueInfo}>
              <div>
                <span>Кадастровый номер: </span>
                <span>{item?.realObject?.cadastralNumber}</span>
              </div>
            </div>
          </div>
        );
      }

      if(searchWords.every((word) => item?.kuvd?.toLowerCase().includes(word.toLowerCase()))){
        return (
          <div className={styles.infoBottom}>
            <div className={styles.valueInfo}>
              <div>
                <span>{item?.kuvd}</span>
              </div>
            </div>
          </div>
        );
      }
      
    }

    return null;
  }, [item, searchValue]);

  

  return <div>{coincidenceSearchVariantRender}</div>;
};

