import { useState, useEffect, useRef, useCallback } from "react";
import Calendar from "react-calendar";
import { Input } from "../../Input";
import styles from "./Calendar.module.scss";
import "react-calendar/dist/Calendar.css";
import { CalendarIcon } from "../../../assets/icons/CalendarIcon";
import { isVisible } from "@testing-library/user-event/dist/utils";
/* 
в компонент добавлен запрос для фильтрации, если mainValue не пустой. Пока такой календарь используется только в заявках, если будет использоваться где-то еще, где фильтр не нужен, нужно добавить флаг isFiltered и фильтровать только если он true

*/

export const CalendarComponent = ({
  firstDate,
  setFirstDate,
  secondDate,
  setSecondDate,
  mainValue,
  filteredData,
  setMainValue,
  setAllApplications,
  isClear,
  setIsClear,
  setCurrentPageFilter,
  setCurrentPage,
}) => {
  const [value, onChange] = useState(null);
  const [isVisisble, setIsVisible] = useState(false);
  const calendarRef = useRef(null);
  const currentDate = sessionStorage.getItem("currentDate");

  const onChangeHandler = useCallback(() => {
    if (Array.isArray(value) && value.length > 0) {
      const startDay = value[0].getDate();
      const startMonth = value[0].getMonth() + 1;
      const startYear = value[0].getFullYear();

      const startDayFormatted = `${startDay
        .toString()
        .padStart(2, "0")}.${startMonth
        .toString()
        .padStart(2, "0")}.${startYear}`;
      setFirstDate(startDayFormatted);

      const endDay = value[1].getDate();
      const endMonth = value[1].getMonth() + 1;
      const endYear = value[1].getFullYear();
      const endDayFormatted = `${endDay.toString().padStart(2, "0")}.${endMonth
        .toString()
        .padStart(2, "0")}.${endYear}`;
      setSecondDate(endDayFormatted);

      const mainValueCreateHandler = () => {
        if (!secondDate && firstDate) {
          setMainValue(firstDate);
        }

        if (secondDate && firstDate) {
          const newDate = firstDate + "-" + secondDate;
          setMainValue(newDate);
          sessionStorage.setItem("currentDate", newDate);
        }
        setIsVisible(false);
      };

      mainValueCreateHandler();
    }
  }, [value, setFirstDate, setSecondDate, firstDate, secondDate, setMainValue]);

  useEffect(() => {
    onChangeHandler();
    if (value) {
      setCurrentPageFilter(0);
      setCurrentPage(0);
    }
  }, [value, onChangeHandler]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isVisible &&
        calendarRef.current &&
        !calendarRef.current.contains(e.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isVisible]);

  useEffect(() => {

    if (isClear) {
      setAllApplications(filteredData);
      setIsClear(false);
      setFirstDate("");
      setSecondDate("");
    }
  }, [firstDate, secondDate, filteredData, mainValue]);

  useEffect(() => {
    if (currentDate) {
      setMainValue(currentDate);
    }
  }, [currentDate]);

  return (
    <div className={styles.calendarBox} ref={calendarRef}>
      <Input
        variant="bordered-green"
        value={mainValue}
        readOnly
        size="short"
        onClick={() => setIsVisible(!isVisisble)}
        className={styles.mainField}
        placeholder="Дата обращения"
      />
      <CalendarIcon />
      {isVisisble && (
        <div>
          <Calendar
            onChange={onChange}
            value={value}
            locale="ru-RU"
            selectRange={true}
          />
        </div>
      )}
    </div>
  );
};
