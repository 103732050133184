export const downloadSigFile = async (file, name) => {
  const defaultExtension = ".sig"; // Установите ваше желаемое расширение по умолчанию

  if (file instanceof Blob || file instanceof File) {
    // Если файл загружен и является объектом Blob или File
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    // Убедитесь, что имя файла содержит расширение
    link.download = name
      ? name + (name.endsWith(defaultExtension) ? "" : defaultExtension)
      : "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } else if (typeof file === "string") {
    try {
      // Используем fetch для загрузки файла
      const token = localStorage.getItem("accessToken");
      const response = await fetch(file, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Ошибка при получении файла: ${response.statusText}`);
      }
      const blob = await response.blob(); // Получаем файл в формате Blob
      const url = URL.createObjectURL(blob);
      // Создаем ссылку и эмулируем клик для скачивания файла
      const link = document.createElement("a");
      link.href = url;
      // Убедитесь, что имя файла содержит расширение
      link.download = name
        ? name + (name.endsWith(defaultExtension) ? "" : defaultExtension)
        : "";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Ошибка при загрузке файла:", error);
    }
  } else {
    console.error("Неподдерживаемый формат файла");
  }
};