import { gql } from "@apollo/client";

export const DUPLICATE_APPLICATION = gql`
  mutation DuplicateApplication($applicationUuid: UUID!) {
    duplicateApplication(applicationUuid: $applicationUuid) {
      application {
        id
        uuid
        sum
        area
        requestHistory {
          statusDescription
          id
          createdAt
          status
        }
        ownership {
          id
          uuid
          title
        }

        mortgage
        kuvd
        realObject {
          id
          uuid
          cadastralNumber
          developer
          buildingArea
          address {
            id
            region
            regionType
            district
            locality
            house
            building
            structure
            okato
            oktmo
            index
            city
            street
            streetType
            country
            value
            block
            apartment
            regionKladrId
            physicalPerson {
              id
              uuid
              firstName
              lastName
              patronymic
              signaturesCurrent {
                consumerUuid
                isActive
                state
                expireDate
                createdAt
              }
            }
          }
          type {
            id
            uuid
            title
          }
        }
        type {
          id
          uuid
          title
          roles {
            id
            uuid
            name
          }
        }
        status
        createdAt
        updatedAt
        documents {
          id
          uuid
          title
          documentsSignatures {
            id
            state
            person {
              firstName
              lastName
              patronymic
            }
          }
          documentType {
            id
            uuid
            name
            code
          }
          file
          sigFile
          presignedUrl
          presignedSigUrl
        }
        participants {
          id
          uuid
          role {
            id
            uuid
            name
          }
          participantType
          physicalPerson {
            id
            uuid
            firstName
            lastName
            patronymic
            gender
            dateOfBirth
            placeOfBirth
            citizenship
            snils
            inn
            createdAt
            updatedAt
            # document {
            #   id
            #   uuid
            #   code
            # }
            # user {
            #   id
            #   uuid
            #   role
            #   phone
            #   email
            #   permissionStatus
            #   deleteStatus
            #   accStatus
            #   isStaff
            #   isActive
            #   isEmailVerified
            #   apiKey
            # }
            signaturesCurrent {
              consumerUuid
              isActive
              state
              expireDate
              createdAt
              connector
              type
            }
          }
          legalPerson {
            id
            uuid
            fullName
            shortName
            ogrn
            inn
            kpp
            createdAt
            updatedAt
            phoneNumber
            email
            address {
              id
              region
              regionType
              district
              locality
              house
              building
              structure
              okato
              oktmo
              index
              city
              street
              streetType
              country
              value
              block
              apartment
              regionKladrId
            }
          }
          representative {
            id
            uuid
            firstName
            lastName
            patronymic
            gender
            dateOfBirth
            placeOfBirth
            citizenship
            snils
            inn
            createdAt
            updatedAt
            # document {
            #   id
            #   uuid
            #   type
            #   series
            #   number
            #   dateIssue
            #   issuedBy
            #   code
            #   physicalPerson {
            #     firstName
            #     lastName
            #     patronymic
            #   }
            # }
            signaturesCurrent {
              consumerUuid
              isActive
              state
              expireDate
              createdAt
              connector
              type
            }
          }
          legalAuthorityRecord {
            uuid
            file
            sigFile
            presignedUrl
            presignedSigUrl
            documentType {
              id
              uuid
              name
              code
            }
          }
          applicant {
            fullName
            id
            inn
            kpp
            ogrn
            shortName
          }
          shareNumerator
          shareDenominator
          legalAuthorityRecord {
            uuid
            file
            sigFile
            presignedUrl
            presignedSigUrl
          }
          documentSignatures {
            id
            signature {
              id
              consumerUuid
              isActive
              state
              dateRelease
              expireDate
              createdAt
              updatedAt
              connector
              type
              person {
                uuid
                firstName
                lastName
                patronymic
              }
            }
            document {
              id
              uuid
              file
              sigFile
              presignedUrl
              presignedSigUrl
            }
            serviceId
            state
            signatureFile
          }
        }
        additionalApplications {
          id
          uuid
          originalApplication {
            id
            uuid
            kuvd
            createdAt
            type {
              title
            }
            participants {
              id
              uuid
              physicalPerson {
                id
                uuid
                firstName
                lastName
                patronymic
              }
              representative {
                id
                uuid
                firstName
                lastName
                patronymic
              }
            }
          }
          createdAt
          updatedAt
          comment
          status
          newApplication {
            id
            uuid
            kuvd
            type {
              title
            }
          }
        }
        statement {
          uuid
          title
          file
          sigFile
          presignedUrl
          presignedSigUrl
          documentsSignatures {
            state
            id
            person {
              firstName
              lastName
              patronymic
            }
          }
        }
        documentComplete
      }
    }
  }
`;
