import { gql } from "@apollo/client";

export const UPDATE_TYPE = gql`
  mutation UpdateType($applicationTypeUuid: UUID!, $applicationUuid: UUID!) {
    updateApplicationType(
      applicationTypeUuid: $applicationTypeUuid
      applicationUuid: $applicationUuid
    ) {
      application {
        uuid
      }
    }
  }
`;
