import { gql } from "@apollo/client";

export const ADD_USER_ACCOUNT = gql`
  mutation addUserAccount($personUuid: UUID!, $userInput: UserInput!) {
    addUserAccount(personUuid: $personUuid, userInput: $userInput) {
      user {
        uuid
      }
    }
  }
`;

export const UPDATE_USER_ACCOUNT = gql`
  mutation updateUserAccount($userUuid: UUID!, $userInput: UserInput!) {
    updateUserAccount(userUuid: $userUuid, userInput: $userInput) {
      user {
        uuid
        apiKey
      }
    }
  }
`;
