import React, { useState } from "react";
import styles from "./PaymentsBlock.module.scss";
import { Button } from "../../../../../Button";
import { ModalWindow } from "../../../../../ModalWindow";
import { useMutation } from "@apollo/client";
import { UPDATE_PAYMENT_REQUEST } from "../../../../../../services/queries/mutations/applications/updatePaymentRequest";
import { DocumentsLoader } from "../../../../../DocumentsLoader/ui/DocumentsLoader";
import { DOWNLOAD_QUITTANCE_APPLICATION } from "../../../../../../services/queries/mutations/applications/downloadQuittance";

export const PaymentsBlock = ({
  payment, //payment - все данные по заявлениям
  participants, //все участники
  requestPackage,
  status,
  uuid
}) => {

  const [openModal, setOpenModal] = useState(false);
  const [participantUuid, setParticipantUuid] = useState("");
  const [downloadQuittance] = useMutation(DOWNLOAD_QUITTANCE_APPLICATION)

  const [updatePaymentRequest] = useMutation(UPDATE_PAYMENT_REQUEST, {
    onCompleted: (data) => {},
    onError(err) {
      console.log(err?.message);
    },
  });

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  return (
    <>
      <div className={styles.paymentContainer}>
        <div className={styles.textContainer}>
          <div className={styles.paymentText}>
            {payment?.amount + "₽"} от {formatDate(payment?.createdAt)}
          </div>
          <DocumentsLoader
            downloadText={"Скачать квитанцию"}
            loadingText="Загрузить квитанцию"
            downloadLink={payment?.receiptUrl}
            requestPackage={requestPackage}
            status={status}
            uuid={uuid}
            name={'Квитанция на оплату'}
            loadingRequest={downloadQuittance}
            className={styles.paymentTextLink}
            type='download_rosreestr_quittance'
            payment={payment}
          />
        </div>
        <Button
          type="button"
          variant={"transparent-green-text"}
          onClick={() => setOpenModal(true)}
        >
          Отправить участнику на оплату...
        </Button>
      </div>

      <ModalWindow
        show={openModal}
        setShowModal={() => setOpenModal(false)}
        dialogClassName={styles.newModal}
      >
        <h2>Отправить пошлину участнику на оплату</h2>
        <ul>
          {participants?.map((item, index) => (
            <li
              key={index}
              className={participantUuid === item?.uuid && styles.active}
              onClick={() => {
                setParticipantUuid(item?.uuid);
              }}
            >
              {item?.physicalPerson && !item?.representative && (
                <span>
                  {item?.physicalPerson?.lastName +
                    " " +
                    item?.physicalPerson?.firstName +
                    " " +
                    item?.physicalPerson?.patronymic}
                </span>
              )}
              {item?.representative && (
                <span>
                  {item?.representative?.lastName +
                    " " +
                    item?.representative?.firstName +
                    " " +
                    item?.representative?.patronymic}
                </span>
              )}
            </li>
          ))}
        </ul>
        <Button
          variant="green"
          type="button"
          onClick={() => {
            updatePaymentRequest({
              variables: {
                participantUuid: participantUuid,
                paymentUuid: payment?.uuid,
              },
            });
          }}
        >
          Отправить пошлину
        </Button>
      </ModalWindow>
    </>
  );
};
