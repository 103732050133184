import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../../Button";
import { StandartParticipantCard } from "../StandartParticipantCard/StandartParticipantCard";
import { ParticipantCardWithAgent } from "../ParticipantCardWithAgent/ParticipantCardWithAgent";
import { setParticipants as setStateParticipants } from "../../../../../../redux/slices/application";
import { DELETE_PARTICIPANT } from "../../../../../../services/queries/mutations/applications/deleteParticipant";
import { APPLICATION_INFO } from "../../../../../../services/queries/queries/applications/applicationInfo";
import { selectParticipants } from "../../../../../../redux/slices/application";
import { newRool } from "../../../../../../constants/constants";

export const ParticipantBlock = ({
  lawType,
  applicationType,
  setParticipantsUuids,
  participantsUuids,
  roles,
  setParticipantType,
  applicationSended,
  allParticipant,
  isCopy = false,
  applicationInfo,
  applicationId,
}) => {
  const dispatch = useDispatch();
  const participants = useSelector(selectParticipants);
  const [deleteParticipant] = useMutation(DELETE_PARTICIPANT, {
    refetchQueries: [APPLICATION_INFO],
  });
  
  const addnew = () => {
    dispatch(
      setStateParticipants([
        ...participants,
        {
          id: participants?.length + 1,
        },
      ])
    );
  };

  const deleteCard = (card) => {
    if (card?.uuid) {
      deleteParticipant({
        variables: {
          uuid: card?.uuid,
        },
      });
    } else {
      let newParticipants = participants?.filter(
        (item) => item?.id !== card?.id
      );
      dispatch(setStateParticipants(newParticipants));
    }
  };

  return (
    <>
      {applicationSended && (
        <Button variant="outline-green" onClick={addnew} type="button">
          Добавить...
        </Button>
      )}

      {!isCopy &&
        participants?.map((item, index) => (
          <div key={index}>
            {applicationType !== newRool ? (
              <StandartParticipantCard
                deleteAllCard={deleteCard}
                lawType={lawType}
                setParticipantsUuids={setParticipantsUuids}
                participantsUuids={participantsUuids}
                roles={roles}
                setParticipantType={setParticipantType}
                participantItem={item}
                applicationSended={applicationSended}
                isCopy={isCopy}
                applicationInfo={applicationInfo}
                applicationId={applicationId}
              />
            ) : 
              item?.participantType === "PHYSICAL_PERSON" ?
              (
                <StandartParticipantCard
                deleteAllCard={deleteCard}
                lawType={lawType}
                setParticipantsUuids={setParticipantsUuids}
                participantsUuids={participantsUuids}
                roles={roles}
                setParticipantType={setParticipantType}
                participantItem={item}
                applicationSended={applicationSended}
                isCopy={isCopy}
                applicationInfo={applicationInfo}
                applicationId={applicationId}
              />
              ) :
              (<ParticipantCardWithAgent
                setParticipants={setStateParticipants}
                deleteAllCard={deleteCard}
                data={item}
                setParticipantType={setParticipantType}
              />)
            }
          </div>
        ))}

      {isCopy &&
        allParticipant?.map((item, index) => (
          <div key={index}>
            {applicationType !== newRool ? (
              <StandartParticipantCard
                deleteAllCard={deleteCard}
                lawType={lawType}
                setParticipantsUuids={setParticipantsUuids}
                participantsUuids={participantsUuids}
                roles={roles}
                setParticipantType={setParticipantType}
                participantItem={item}
                applicationSended={applicationSended}
                isCopy={isCopy}
                applicationInfo={applicationInfo}
                applicationId={applicationId}
              />
            ) : (
              <ParticipantCardWithAgent
                setParticipants={setStateParticipants}
                deleteAllCard={deleteCard}
                data={item}
                setParticipantType={setParticipantType}
                isCopy={isCopy}
              />
            )}
          </div>
        ))}
    </>
  );
};
