import { useForm } from "react-hook-form";
import { RadioButton } from "../../../RadioButton";
import { Input } from "../../../Input";
import styles from "./NewAccount.module.scss";
import { useEffect } from "react";
import { CloseIcon } from "../../../../assets/icons/CloseIcon";
import { Button } from "../../../Button";

export const NewAccount = ({
  tokenValue,
  setTokenValue,
  setRole,
  setPermition,
  setShowFields,
  addUserAccount
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm();

  const watchedRole = watch("newRole");
  const watchedPermision = watch("newWorkStatus");

  useEffect(() => {
    if (watchedRole && watchedRole !== "") {
      setRole(watchedRole);
    }

    if (watchedPermision && watchedPermision !== "") {
      setPermition(watchedPermision);
    }
  }, [watchedRole, watchedPermision]);

  return (
    <div className={styles.newAccWrapper}>
      <div className={styles.fieldsWrapper}>
        <span className={styles.fieldlabel}>Работа в системе:</span>
        <RadioButton
          name="newWorkStatus"
          register={register}
          text1="Разрешена"
          text2="Запрещена"
          className={styles.radioField}
          registerObj={{ required: "Выберите подходящий вариант" }}
          errors={errors}
        />
      </div>
      <div className={styles.fieldsWrapper}>
        <span className={styles.fieldlabel}>Роль:</span>
        <RadioButton
          name="newRole"
          register={register}
          text1="Менеджер"
          text2="Администратор"
          className={styles.radioField}
          registerObj={{ required: "Выберите подходящий вариант" }}
          errors={errors}
        />
      </div>
      <div className={styles.fieldsWrapper}>
        <span className={styles.fieldlabel}>Токен:</span>
        <Input
          value={tokenValue}
          setValue={setTokenValue}
          variant="bordered-green"
          className={styles.accountInput}
          placeholder={"Можно оставить пустым"}
        />
      </div>
      <span
        title="Отменить создание аккаунта"
        className={styles.closeNewAcc}
        onClick={() => setShowFields(false)}
      >
        <CloseIcon />
      </span>
      <Button
        type="button"
        variant="green"
        className={styles.accountsBtn}
        onClick={addUserAccount}
      >
        Сохранить
      </Button>
    </div>
  );
};
