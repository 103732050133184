import { useContext, useEffect, useState } from "react";
import { UsersList } from "../../../components/Lists";
import styles from "./Users.module.scss";
import { Context } from "../../../App";
import { useLocation } from "react-router-dom";
import { ModalWindow } from "../../../components/ModalWindow";
import { CreateUserForm } from "../../../components/Forms";
import { useLazyQuery, useQuery } from "@apollo/client";
import { ALL_USERS } from "../../../services/queries/queries/user";
import { Pagination } from "../../../components/Pagination/ui/Pagination";
import { Preloader } from "../../../components/Preloader";
import { FilteredSearch } from "../../../components/FilteredSearch";

export const Users = () => {
  const { showModal, setShowModal } = useContext(Context);
  const location = useLocation();
  const currentPath = location.pathname;
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [first, setFirst] = useState(10); // Количество объектов на странице
  const [offset, setOffset] = useState(currentPage * first);
  const [allUsers, { loading, error, data }] = useLazyQuery(
    ALL_USERS,
    {
      variables: { offset: offset, first: first },
    }
  );

  const totalObjects = data ? data.allUsers.totalCount : 0;
  const totalPages = Math.ceil(totalObjects / first);

  const formatData = (dateString) => {
    // Разделяем строку на части (год, месяц, день)
    const [year, month, day] = dateString?.split("-") || "0000-00-00";

    // Формируем новую строку в нужном формате
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  };

  useEffect(() => {
    setOffset(currentPage * first);
  }, [currentPage, first]);

  useEffect(() => {
    if (data && data.allUsers) {
      setUsers(extractObjectData(data));
    }
  }, [data]);

  const extractObjectData = (data) => {
    if (!data) return [];
    return data.allUsers?.edges.map((edge) => edge.node);
  };

  return (
    <>
      {!loadingStatus ? (
        <>
          <div className={styles.searchWrapper}>
            <FilteredSearch
              response={allUsers}
              className={styles.basesSearch}
              placeholder={"ФИО"}
              setCurrentPage={setCurrentPage}
              setFirst={setFirst}
            />
          </div>

          <UsersList usersData={users} loading={loading} error={error} />
          <Pagination
            page={currentPage}
            setPage={setCurrentPage}
            totalPages={[...Array(totalPages).keys()]}
          />
          {currentPath === "/users/" && showModal && (
            <ModalWindow show={showModal} setShowModal={setShowModal} size="xl">
              <CreateUserForm usersData={users} showModal={showModal} />
            </ModalWindow>
          )}
        </>
      ) : (
        <Preloader />
      )}
    </>
  );
};
