import styles from "./AdditionalApplication.module.scss";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button } from "../../../Button";
import { DocumentsBlock } from "../ApplicationForm/ui/DocumentsBlock/DocumentsBlock";
import { DeclarationBlock } from "../ApplicationForm/ui/DeclarationBlock/DeclarationBlock";
import { DELETE_DOCUMENT } from "../../../../services/queries/mutations/documents/documents";
import { CREATE_STATEMENT_APPLICATION } from "../../../../services/queries/mutations/applications/statement/statement";
import { SEND_DOCUMENTS_FOR_SIGN } from "../../../../services/queries/mutations/documents/documents";
import { GET_APPLICATION_TYPES } from "../../../../services/queries/queries/allApplicationTypes";
import { CREATE_APPLICATION_ADDITIONAL } from "../../../../services/queries/mutations/applications/applications";
import { DELETE_PARTICIPANT } from "../../../../services/queries/mutations/applications/deleteParticipant";
import { toast } from "react-toastify";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalWindow } from "../../../ModalWindow";
import { Input } from "../../../Input";
import { FormCalendar } from "../../../FormCalendar";
import { useForm } from "react-hook-form";
import { additionalDocs } from "../../../../constants/constants";
import { suspensionAppeal } from "../../../../constants/constants";
import { DeleteApplication } from "../ApplicationForm/ui/DeleteApplication/DeleteApplication";
import { CREATE_APPLICATION_PARTICIPANT } from "../../../../services/queries/mutations/applications/createApplicationPatricipants";
import { SEND_REQUEST_APP_SERVICE } from "../../../../services/queries/mutations/applications/sendRequestsApplicationService";
import { UPDATE_APPLICATION_TYPE } from "../../../../services/queries/mutations/applications/updateApplicationType";
import { downloadExistFiles } from "../ApplicationForm/ApplicationForm";
import { translateStatus } from "../../../../helpers/translateStatus";
import { APPLICATION_INFO } from "../../../../services/queries/queries/applications/applicationInfo";
import { UPDATE_APPLICATION } from "../../../../services/queries/mutations/applications/updateApplication";
import { unTransformDate } from "../../../../helpers/untransformDate";
import { MassiveSign } from "../../../../components/MassiveSign/ui/MassiveSign";

export const AdditionalApplication = ({
  applicationData,
  applicationInfo,
  uuid,
  id,
  queryApplicationInfo,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  const [applicationType, setApplicationType] = useState(
    "Выберите тип обращения"
  );
  const [openChooseModal, setOpenChooseModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [applicant, setApplicant] = useState(null);
  const [participantUuid, setParticipantUuid] = useState(null);
  const [filteredRepresentatives, setFilteredRepresentatives] = useState([]);
  const [deleteShow, setDeleteShow] = useState(false);
  const [testData, setTestData] = useState(null);
  const location = useLocation();
  const { comment } = location.state || "";
  const [appUuid, setAppUuid] = useState('')
  const downLoadPackage = process.env.REACT_APP_PACKAGE_DOWNLOAD
  const fullLink = downLoadPackage + appUuid + '/download-zip-send/'

  const accessToken = localStorage.getItem("accessToken");
  const kuvd = applicationInfo?.application?.kuvd;

  const watchedComment = watch("comment");
  const watchedDate = watch("date");

  const participants = applicationData
    ? applicationData?.application?.participants
    : applicationInfo?.application?.additionalApplications?.[0]
        ?.originalApplication?.participants;

  function formatDate2(inputDate) {
    // Разделяем строку на части
    const dateString = inputDate;

    if (dateString) {
      const [day, month, year] = dateString.split(".");
      // Формируем новую строку в формате YYYY-MM-DD
      return `${year}-${month}-${day}`;
    }

    return null;
  }

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    onCompleted: (data) => {},
    onError(err) {
      console.log(err?.message);
    },
  });

  const [mutateApplication, { data: mutateData }] = useMutation(
    CREATE_APPLICATION_ADDITIONAL,
    {
      onCompleted: (data) => {
        //   setParticipantUuid(null);
        navigate(
          `/additional/edit/${data?.createAdditionalApplication?.additionalApplication?.newApplication?.id}`,
          {
            state: {
              comment: watchedComment,
            },
          }
        );
      },
      onError(err) {
        toast.error(err?.message);
      },
    }
  );

  const [updateApplicationType] = useMutation(UPDATE_APPLICATION_TYPE);

  const { data } = useQuery(GET_APPLICATION_TYPES, {
    variables: { systemType: "additional_statement" },
  });

  const applicationTypes = [data?.allApplicationTypes];

  const [createStatementApplication] = useMutation(
    CREATE_STATEMENT_APPLICATION,
    {
      refetchQueries: [APPLICATION_INFO],
    }
  );

  const [sendDocumentsForSign] = useMutation(SEND_DOCUMENTS_FOR_SIGN, {
    onCompleted: (data) => {
      toast.success(data.sendDocuments.message);
    },
  });

  const [deleteParticipant] = useMutation(DELETE_PARTICIPANT);

  const [createParcipants] = useMutation(CREATE_APPLICATION_PARTICIPANT);

  const [sendRequestsApplicationService] = useMutation(
    SEND_REQUEST_APP_SERVICE,
    {
      onCompleted: (data) => {
        toast.success(data.sendRequestsApplicationService.message);
      },
      onError(err) {
        toast.error(err.message);
      },
    }
  );

  const [updateApplication, { data: updateData }] =
    useMutation(UPDATE_APPLICATION);

  useEffect(() => {
    if (applicationInfo !== null) {
      const participants = applicationInfo?.application?.participants;

      setApplicant(
        participants?.[0]?.representative !== null
          ? participants?.[0]?.representative?.lastName +
              " " +
              participants?.[0]?.representative?.firstName +
              " " +
              participants?.[0]?.representative?.patronymic
          : participants?.[0]?.physicalPerson?.lastName +
              " " +
              participants?.[0]?.physicalPerson?.firstName +
              " " +
              participants?.[0]?.physicalPerson?.patronymic
      );


      setAppUuid(applicationInfo?.application?.uuid)
    }
  }, [applicationInfo]);

  useEffect(() => {
    reset({
      comment:
        applicationInfo?.application?.cause ||
        mutateData?.additionalApplication?.newApplication?.cause ||
        comment,
      date: unTransformDate(applicationInfo?.application?.time),
    });
  }, [applicationInfo]);

  useEffect(() => {
    if (applicationInfo !== null) {
      setApplicationType(applicationInfo?.application?.type);
    }
  }, [applicationInfo]);

  useEffect(() => {
    if (
      applicationType !== "Выберите тип обращения" &&
      participantUuid !== null &&
      applicationData &&
      !applicationInfo
    ) {
      mutateApplication({
        variables: {
          previousStatementKuvd: applicationData
            ? applicationData?.application?.kuvd
            : applicationInfo?.application?.kuvd,
          statementRegDate: formatDateNumber(
            applicationData
              ? applicationData?.application?.createdAt
              : applicationInfo?.application?.createdAt
          ),
          applicationTypeUuid: applicationType?.uuid,
          participantUuid: participantUuid,
          originalApplicationUuid: uuid,
          roleUuid: applicationType?.roles?.[0]?.uuid,
          cause: watchedComment,
          time: formatDate2(watchedDate),
        },
      });
    }
  }, [applicationType, participantUuid, watchedComment, watchedDate]);

  useEffect(() => {
    if (
      applicationType !== "Выберите тип обращения" &&
      applicationInfo &&
      watchedDate !== null &&
      watchedComment !== null
    ) {
      updateApplication({
        variables: {
          applicationUuid: applicationInfo?.application?.uuid,
          applicationData: {
            cause: watchedComment || comment,
            time: formatDate2(watchedDate),
          },
        },
      });
    }
  }, [watchedDate, watchedComment, applicationInfo, comment]);

  useEffect(() => {
    if (applicationInfo) {
      setDocuments(applicationInfo?.application?.documents);
    }
  }, [applicationInfo]);

  const chooseApplicationType = (value) => {
    setApplicationType(value);
    setOpenChooseModal(false);
  };

  const chooseNewApplicationType = (value) => {
    updateApplicationType({
      variables: {
        applicationUuid: applicationInfo?.application?.uuid,
        applicationTypeUuid: value?.uuid,
      },
    });
    setOpenChooseModal(false);
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${month} ${year} ${hours}:${minutes}`;
  }

  function formatDateNumber(inputDate) {
    const date = new Date(inputDate);

    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${year}-${month}-${day <= 9 ? "0" + day : day}`;
  }

  function createParticipantHandle(item) {
    deleteParticipant({
      variables: {
        uuid: applicationInfo?.application?.additionalApplications?.[0]
          ?.newApplication?.participants?.[0]?.uuid,
      },
    });
    createParcipants({
      variables: {
        applicationUuid: applicationInfo?.application?.uuid,
        participantType: "physical_person",
        role: applicationType?.roles?.[0]?.uuid,
        physicalPersonUuid: item,
      },
    });
  }

  useEffect(() => {
    if (participants?.length > 0) {
      const newRepresentatives = [];
      for (let i = participants?.length - 1; i >= 0; i--) {
        if (participants[i]) {
          const representative = participants[i].representative
            ? {
                ...participants[i].representative,
                itemUuid: participants[i].uuid,
              }
            : null;

          const physicalPerson = {
            ...participants[i].physicalPerson,
            itemUuid: participants[i].uuid,
          };

          if (representative) {
            if (
              !newRepresentatives.some(
                (participant) => participant.id === representative.id
              )
            ) {
              newRepresentatives.push(representative);
            }
          } else {
            if (
              !newRepresentatives.some(
                (participant) => participant.id === physicalPerson.id
              )
            ) {
              newRepresentatives.push(physicalPerson);
            }
          }

          if (participants[i]?.representative && participants[i]?.legalPerson) {
            if (
              representative &&
              !newRepresentatives.some(
                (participant) => participant.id === representative.id
              )
            ) {
              newRepresentatives.push(representative);
            }
          }
        }
      }
      setFilteredRepresentatives(newRepresentatives);
    }
  }, [participants]);

  useEffect(() => {
    const socketLink = process.env.REACT_APP_API_BASE_URL_WS;
    const token = localStorage.getItem("accessToken");
    const fullSocketLink = socketLink + `?token=${token}`;
    const socket = new WebSocket(fullSocketLink);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.application_uuid === applicationInfo?.application?.uuid) {
        if (
          data.type !== "application_updated" ||
          data.type === "document_upload_confirmed" ||
          data.type === "document_change_state" ||
          data.type === "signature_change_state"
        ) {
          const currentId = sessionStorage.getItem("additionalId");
          queryApplicationInfo({
            variables: {
              id: currentId,
            },
          });
        }
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (
      mutateData?.createAdditionalApplication?.additionalApplication
        ?.newApplication
    ) {
      sessionStorage.setItem(
        "additionalId",
        mutateData?.createAdditionalApplication?.additionalApplication
          ?.newApplication?.id
      );
    }
  }, [mutateData]);

  return (
    <>
      {applicationData && (
        <section
          className={styles.section}
          onClick={() => {
            navigate(`/applications/edit/${id}`);
          }}
        >
          <h3 className={styles.sectionTitle}>Основное обращение</h3>
          <div className={styles.card}>
            <h4 className={styles.divTitle}>
              {applicationData?.application?.type?.title}
            </h4>
            <span className={styles.divText}>
              {formatDate(applicationData?.application?.createdAt)} -
              {applicationData?.application?.kuvd
                ? " Присвоен КУВД " + applicationData?.application?.kuvd
                : " КУВД не присвоен"}
            </span>
          </div>
        </section>
      )}
      {applicationInfo && (
        <section
          className={styles.section}
          onClick={() =>
            navigate(
              `/applications/edit/${applicationInfo?.application?.additionalApplications?.[0]?.originalApplication?.id}`
            )
          }
        >
          <h3 className={styles.sectionTitle}>Основное обращение</h3>
          <div className={styles.card}>
            <h4 className={styles.divTitle}>
              {
                applicationInfo?.application?.additionalApplications?.[0]
                  ?.originalApplication?.type?.title
              }
            </h4>
            <span className={styles.divText}>
              {formatDate(
                applicationInfo?.application?.additionalApplications?.[0]
                  ?.originalApplication?.createdAt
              )}{" "}
              -
              {applicationInfo?.application?.additionalApplications?.[0]
                ?.originalApplication?.kuvd
                ? " Присвоен " +
                  applicationInfo?.application?.additionalApplications?.[0]
                    ?.originalApplication?.kuvd
                : " КУВД не присвоен"}
            </span>
          </div>
        </section>
      )}
      <form>
        <h2 className={styles.title}>
          {applicationInfo !== null
            ? applicationType?.title
              ? applicationType?.title
              : applicationInfo &&
                applicationInfo?.application &&
                applicationInfo?.application?.type.title
            : applicationType?.title
            ? applicationType?.title
            : applicationType}
        </h2>
        {applicationType?.title !== "Выберите тип обращения" ? (
          <Button
            className={styles.editApplicationBtn}
            variant="transparent"
            type="button"
            onClick={() => setOpenChooseModal(true)}
          >
            Изменить тип обращения...
          </Button>
        ) : (
          <Button
            className={styles.editApplicationBtn}
            variant="transparent"
            type="button"
            onClick={() => setOpenChooseModal(true)}
          >
            Выбрать тип обращения...
          </Button>
        )}
        <section className={styles.section}>
          <h3 className={styles.sectionTitle}>Заявитель</h3>
          {applicant !== null && (
            <span className={styles.active}>{applicant}</span>
          )}
          <Button
            className={styles.editApplicationBtn}
            variant="transparent"
            type="button"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            {applicant === null
              ? "Выберите заявителя..."
              : "Изменить заявителя..."}
          </Button>
        </section>

        {applicationType?.uuid === suspensionAppeal && (
          <section className={styles.section}>
            <Input
              isFormField={true}
              inputElement="textarea"
              name="comment"
              register={register}
              variant="bordered-green"
              size="full"
              label="Комментарий"
              className={styles.appealText}
              errors={errors}
            />

            <FormCalendar
              label="Приостановить до"
              name="date"
              register={register}
              registerObj={{
                required: {
                  message: "Поле обязательно для заполнения",
                },
              }}
              setValue={setValue}
              className={styles.calendar}
              classNameInput={styles.physycalCalendar}
              errors={errors}
            />
          </section>
        )}

        {applicationType?.title ===
          "Предоставление дополнительных документов" && (
          <section className={styles.section}>
            <h3 className={styles.sectionTitle}>Документы</h3>
            <Button
              variant="outline-green"
              className={styles.addBtn}
              type="button"
              onClick={(e) => {
                setDocuments([
                  ...documents,
                  {
                    id: documents?.length + 1,
                  },
                ]);
              }}
            >
              <span>Добавить</span>
            </Button>
            {documents?.map((item, index) => (
              <DocumentsBlock
                id={index}
                item={item}
                participants={applicationInfo?.application?.participants}
                deleteDocument={deleteDocument}
                setDocuments={setDocuments}
                documents={documents}
                documentsIds={item?.documentsSignatures}
                fileUrl={item?.presignedUrl}
                testData={testData}
                applicationId={applicationInfo?.application?.id}
                applicationUuid={applicationInfo?.application?.uuid || uuid}
                applicationSended={
                  applicationInfo?.application?.requestHistory?.length == 0
                }
                applicationInfo={queryApplicationInfo}
              />
            ))}
          </section>
        )}
        <section className={styles.section}>
          <h3 className={styles.sectionTitle}>Заявление</h3>

          <Button
            variant="outline-green"
            className={styles.addBtn}
            type="button"
            disabled={
              applicationType?.uuid === additionalDocs &&
              applicationInfo?.application?.documents?.length == 0
            }
            onClick={() => {
              createStatementApplication({
                variables: {
                  applicationUuid: uuid,
                },
              });
            }}
          >
            <span>Сформировать заявление</span>
          </Button>
          {applicationInfo?.application?.statement?.map((item, index) => (
            <DeclarationBlock
              key={index}
              declaration={item}
              participants={
                updateData?.updateApplication?.application?.participants ||
                applicationInfo?.application?.participants
              }
              applicationId={updateData?.updateApplication?.application?.id}
              applicationInfo={applicationInfo}
            />
          ))}
          {applicationInfo?.application?.statement?.length > 0 &&
            !applicationInfo?.application?.documentComplete && (
              <div className={styles.additionalSignBox}>
                <Button
                  variant="white"
                  type="button"
                  className={styles.addBtn}
                  onClick={() => {
                    sendDocumentsForSign({
                      variables: {
                        applicationUuid: uuid,
                      },
                    });
                  }}
                >
                  <span>Отправить на подпись</span>
                </Button>
                <MassiveSign
                  participants={
                    updateData?.updateApplication?.application?.participants ||
                    applicationInfo?.application?.participants
                  }
                  applicationType={
                    updateData?.updateApplication?.application?.type?.uuid ||
                    applicationInfo?.application?.type?.uuid
                  }
                />
              </div>
            )}
          <div className={styles.declarationBottomBlock}>
            {applicationInfo?.application?.documentComplete === true && (
              <>
                <Button
                  variant="green"
                  className={styles.addBtn}
                  type="button"
                  onClick={() =>
                    sendRequestsApplicationService({
                      variables: {
                        applicationUuid: uuid,
                      },
                    })
                  }
                >
                  <span>Отправить обращение в РР</span>
                </Button>
              </>
            )}
          </div>
        </section>

        <section className={styles.section}>
          {applicationInfo?.application?.requestHistory?.length > 0 && (
            <div className={styles.applicationStatusBlock}>
              <div>
                <h3 className={styles.application_headerText}>
                  Статус заявления
                </h3>
                {applicationInfo?.application?.requestHistory.map((item) => (
                  <div>
                    {formatDate(item.createdAt)} -{" "}
                    {item?.statusDescription !== null
                      ? item?.statusDescription
                      : translateStatus(item.status, kuvd)}
                    {item?.statusDescription === "Принято от заявителя" &&
                      ` Присвоен ${kuvd}`}{" "}
                    {item?.statusDescription === "Получено от РР " && (
                      <span
                        className={styles.link}
                        onClick={() => {
                          downloadExistFiles(
                            accessToken,
                            fullLink
                          );
                        }}
                      >
                        {`скачать пакет`}
                      </span>
                    )}{" "}
                    {item?.status === "SEND_TO" && (
                      <span
                        onClick={() => {
                          downloadExistFiles(
                            accessToken,
                            fullLink
                          );
                        }}
                        className={styles.link}
                      >
                        {`скачать пакет`}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </section>

        <section className={cn(styles.section, styles.deleteSection)}>
          <Button
            type="button"
            variant="red"
            onClick={() =>
              applicationInfo ? setDeleteShow(true) : navigate("/applications/")
            }
          >
            Удалить обращение
          </Button>
        </section>
      </form>

      <ModalWindow
        show={openChooseModal}
        setShowModal={() => setOpenChooseModal(false)}
        dialogClassName={styles.newApplicationModal}
      >
        <h2 className={cn(styles.title, styles.modalTitle)}>
          Выберите тип обращения
        </h2>
        <ul>
          {applicationTypes?.[0]?.edges?.map((item, index) => (
            <li key={index}>
              <Button
                onClick={() => {
                  applicationType?.uuid
                    ? chooseNewApplicationType(item?.node)
                    : chooseApplicationType(item?.node);
                }}
                className={cn(styles.applicationTypesListItem, {
                  [styles.active]: applicationType === item?.node?.title,
                })}
              >
                {item?.node?.title}
              </Button>
            </li>
          ))}
        </ul>
      </ModalWindow>

      <ModalWindow
        show={openModal}
        setShowModal={() => setOpenModal(false)}
        dialogClassName={styles.newModal}
      >
        <h2 className={cn(styles.title, styles.modalTitle)}>
          Выбрать заявителя
        </h2>
        <ul>
          {filteredRepresentatives?.map((item) => (
            <>
              <li
                key={item?.id}
                onClick={() => {
                  setApplicant(
                    item?.lastName +
                      " " +
                      item?.firstName +
                      " " +
                      item?.patronymic
                  );
                  if (applicationData) {
                    setParticipantUuid(item?.itemUuid);
                  }
                  if (applicationInfo) {
                    createParticipantHandle(item?.uuid);
                  }
                  setOpenModal(false);
                }}
              >
                {item?.lastName +
                  " " +
                  item?.firstName +
                  " " +
                  item?.patronymic}
              </li>
            </>
          ))}
        </ul>
      </ModalWindow>

      <DeleteApplication
        setDeleteShow={setDeleteShow}
        deleteShow={deleteShow}
        uuid={uuid}
      />
    </>
  );
};
