import { gql } from "@apollo/client";

export const ALL_USERS = gql`
  query ($search: String, $first: Int, $offset: Int) {
    allUsers(search: $search, first: $first, offset: $offset) {
      edges {
        node {
          firstName
          lastName
          patronymic
          uuid
          email
          phone
          gender
          inn
          citizenship
          placeOfBirth
          dateOfBirth
          snils
          id
          accounts {
            id
            uuid
            role
            username
            apiKey
            companyName
            profile {
              email
            }
            permissionStatus
            deleteStatus
            accStatus
            isStaff
            isActive
            apiKey
          }
          registrationAddress {
            id
            value
            region
            block
            building
            city
            country
            district
            house
            index
            street
          }

          signatures {
            connector
            consumerUuid
            createdAt
            dateRelease
            expireDate
            id
            isActive
            state
            type
            updatedAt
          }
          signaturesCurrent {
            connector
            consumerUuid
            createdAt
            expireDate
            isActive
            state
            type
          }

          signaturesActive {
            state
            type
            isActive
            createdAt
            consumerUuid
            expireDate
            connector
          }
          signatureRelease {
            connector
            consumerUuid
            createdAt
            dateRelease
            expireDate
            id
            isActive
            state
            type
            updatedAt
          }

          document {
            id
            type
            number
            series
            dateIssue
            issuedBy
            code
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const ME = gql`
  query User($id: ID!) {
    user(id: $id) {
      accounts {
        isStaff
        username
        role
        isEmailVerified
        uuid
        isActive
        deleteStatus
        apiKey
        accStatus
        permissionStatus
      }
      id
      email
      phone
      firstName
      lastName
      patronymic
    }
  }
`;

export const USER = gql`
  query User {
    me {
      accStatus
      apiKey
      deleteStatus
      email
      id
      isActive
      isEmailVerified
      isStaff
      isSuperuser
      lastLogin
      password
      permissionStatus

      role
      uuid
      physicalperson {
        updatedAt
        snils
        placeOfBirth
        phoneNumber
        patronymic
        lastName
        inn
        id
        gender
        firstName
        email
        dateOfBirth
        createdAt
        citizenship
        document {
          type
          series
          number
          issuedBy
          id
          dateIssue
          code
        }
        registrationAddress {
          address
          building
          city
          country
          district
          house
          id
          index
          locality
          okato
          oktmo
          region
          regionType
          street
          streetType
          value
          structure
        }
        representative {
          citizenship
          createdAt
          dateOfBirth
          email
          firstName
          gender
          id
          inn
          lastName
          patronymic
          phoneNumber
          placeOfBirth
          snils
          updatedAt
          document {
            code
            dateIssue
            id
            issuedBy
            number
            series
            type
          }
          registrationAddress {
            address
            building
            city
            country
            district
            house
            id
            index
            locality
            oktmo
            okato
            region
            regionType
            street
            streetType
            structure
            value
          }
        }
      }
    }
  }
`;

export const GET_ADDRESS_USER = gql`
  query GetAddressUser($id: ID!) {
    user(id: $id) {
      registrationAddress {
        structure
        streetType
        value
        street
        regionType
        regionKladrId
        region
        oktmo
        okato
        locality
        kladr
        index
        house
        id
        fias
        district
        country
        city
        building
        block
        apartment
      }
    }
  }
`;
