import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser(
    $userInput: UserInput!
    $personInput: CreatePhysicalPersonInput!
    $addressInput: AddressInput!
    $documentInput: IdentityDocumentInput!
  ) {
    createUser(
      userInput: $userInput
      personInput: $personInput
      addressInput: $addressInput
      documentInput: $documentInput
    ) {
      user {
        uuid
        profile {
          firstName
          lastName
          patronymic
          gender
          dateOfBirth
          placeOfBirth
          citizenship
          snils
          inn
          email
          phone
        }
        role
        permissionStatus
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $personInput: CreatePhysicalPersonInput!
    $addressInput: AddressInput!
    $documentInput: IdentityDocumentInput!
    $personUuid: UUID!
  ) {
    updateUser(
      personInput: $personInput
      addressInput: $addressInput
      documentInput: $documentInput
      personUuid: $personUuid
    ) {
      person {
        uuid
        email
        phone
        registrationAddress {
          region
          regionType
          district
          locality
          house
          structure
          okato
          oktmo
          index
          city
          street
          streetType
          country
          value
          block
          fias
          kladr
          regionKladrId
        }
        document {
          type
          series
          number
          dateIssue
          issuedBy
          code
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($personUuid: UUID!) {
    deleteUser(personUuid: $personUuid) {
      success
    }
  }
`;
