import styles from "./FileField.module.scss";
import cn from "classnames";
import { useState } from "react";

/* 

Компонент для загрузки файлов

Если переданы getValues и setValues, можно собирать данные с полей которые выводятся из массива методом map(имена полей должны быть динамическими)

В компоненте стандартный input type file маскируется спаном, в который выводится название файла, если он загружен или любой текст, который может быть передан через пропс

Для работы компонента при вызове его в родительском компоненте, нужно обязательно передать в него register и setValue, которые можно получить из хука useForm
*/

export const FileField = ({
  className,
  labelClassName,
  register,
  name = "files",
  isMultiply = true,
  fileText = "Добавить файлы",
  icon,
  fileTitle,
  setValue,
  readOnly = false,
  getValues,
  setValues,
  handleUpload,
}) => {
  const [file, setFile] = useState([]);
  const { ref: registerRef, ...props } = register(name);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const fileNames = Array.from(files).map((file) => file.name);
    setFile([...fileNames]);
    setValue(name, e.target.files);

    if (getValues && setValues) {
      const currentValues = getValues();
      setValues(currentValues);

      if (handleUpload) {
        handleUpload();
      }
    }
  };

  return (
    <label className={cn(styles.fileFieldWrapper, labelClassName)}>
      <input
        type="file"
        onChange={handleFileChange}
        readOnly={readOnly}
        multiple={isMultiply}
        className={cn(styles.fileField, className)}
      />
      <div>
        {fileTitle && <p>{fileTitle}</p>}
        <span className={cn(styles.fileSpan, {
          [styles.fileSpanActive]: file.length > 0
        })}>
          {file.length > 0 ? file.join(",") : fileText}
        </span>
      </div>
      {icon && icon}
    </label>
  );
};
