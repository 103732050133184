import { gql } from "@apollo/client";

export const UPDATE_APPLICATION = gql`
  mutation UpdateApplication(
    $applicationData: UpdateApplicationInput!
    $applicationUuid: UUID!
  ) {
    updateApplication(
      applicationData: $applicationData
      applicationUuid: $applicationUuid
    ) {
      application {
        sum
        area
        cause
        statementRegDate
        requestPackageUrl
        payments {
          id
          uuid
          amount
          paymentType
          createdAt
          payTillDate
          receiptUrl
        }
        ownership {
          title
        }
        mortgage
        # realObject {
        # 	cadastralNumber
        # 	developer
        # 	buildingArea
        # }
        documents {
          id
          uuid
          title
          documentsSignatures {
            id
            state
            person {
              firstName
              lastName
              patronymic
            }
          }
          documentType {
            id
            uuid
            name
            code
          }
          file
          sigFile
          presignedUrl
          presignedSigUrl
        }
        type {
          uuid
          title
        }
        participants {
          id
          uuid
          physicalPerson {
            firstName
            lastName
            patronymic
            uuid
            id
          }
          legalPerson {
            fullName
            shortName
          }
          representative {
            firstName
            lastName
            patronymic
            uuid
            id
          }
        }
      }
    }
  }
`;
