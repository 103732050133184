import React from "react";
import { ModalWindow } from "../../../../ModalWindow";
import { Button } from "../../../../Button";
import styles from "../CreateUserForm.module.scss";
import cn from "classnames";
import { useMutation } from "@apollo/client";
import { DELETE_USER } from "../../../../../services/mutations/user";
import { ALL_USERS } from "../../../../../services/queries/queries/user";

export const DeleteUserModal = ({
  deleteShow,
  setDeleteShow,
  userId,
  hideModal,
  usersData,
}) => {
  const [deleteUser, { loading, error }] = useMutation(DELETE_USER, {
    refetchQueries: [ALL_USERS],
  });

  const removeObjectWithUid = (arr) => {
    return arr.filter((obj) => obj.uuid !== userId);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteUser({ variables: { personUuid: userId } });
      if (response.data.deleteUser.success) {
        setDeleteShow(false);
        hideModal(null);
        usersData = removeObjectWithUid(usersData);
      } else {
        console.log("Ошибка");
      }
    } catch (e) {
      console.error("Ошибка", e);
    }
  };

  if (loading) return <p>Deleting user...</p>;
  if (error) return <p>Error: {error.message}</p>;
  return (
    <ModalWindow show={deleteShow} setShowModal={setDeleteShow}>
      <h2 className={cn(styles.title, styles.modalTitle)}>
        Вы действительно хотите удалить пользователя?
      </h2>
      <div className={styles.deleteButtonBlock}>
        <Button
          type="button"
          variant="green"
          className={styles.edsBtn}
          onClick={() => handleDelete()}
        >
          Да
        </Button>
        <Button
          type="button"
          variant="red"
          className={styles.edsBtnNon}
          onClick={() => setDeleteShow(false)}
        >
          Нет
        </Button>
      </div>
    </ModalWindow>
  );
};
