import { InfoList } from "../../../InfoList/ui/InfoList";
import { StatusLabel } from "../../../StatusLabel/ui/StatusLabel";
import styles from "./UsersList.module.scss";
import { useState } from "react";
import cn from "classnames";
import { ModalWindow } from "../../../ModalWindow";
import { CreateUserForm } from "../../../Forms";
import { Preloader } from "../../../Preloader";
import { toast } from "react-toastify";

export const UsersList = ({ usersData, loading, error }) => {
  const [activeUserId, setActiveUserId] = useState("");

  if (loading) return <Preloader />;
  if (error) {
    return toast.error(error.message);
  }

  return (
    <ul className={styles.usersList}>
      {usersData.map((user, index) => (
        <div key={index}>
          <InfoList key={index} onClick={() => setActiveUserId(user?.id)}>
            <div className={styles.usersInner}>
              <div className={styles.userInfo}>
                {!user?.accounts?.some(item => item?.profile?.email?.includes(user?.email)) && user?.accounts?.permissionStatus ? (
                  <StatusLabel text="Аккаунт отключен" variant="negative" />
                ) : (
                  <StatusLabel text="Работа разрешена" variant="positive" />
                )}

                <span className={styles.userRole}>{user.userRole}</span>
                <div className={styles.userBio}>
                  <span>
                    {user?.lastName +
                      " " +
                      user?.firstName +
                      " " +
                      user?.patronymic}
                  </span>
                  <div className={styles.userContacts}>
                    <span>{user?.email}</span>
                    <span>{user?.phone}</span>
                  </div>
                </div>
              </div>
              <div className={styles.userEdsInfo}>
                <span
                  className={cn({
                    [styles.edsNone]: !user?.accounts?.accStatus,
                    [styles.signatureAcive]: user?.signaturesActive?.isActive
                  })}
                >
                  {user?.signaturesActive?.isActive ? "ЭЦП действует" : "ЭЦП не действует"}
                  {/* {user?.signaturesActive?.connector} */}
                </span>
              </div>
            </div>
          </InfoList>
          {activeUserId === user?.id && (
            <ModalWindow show={activeUserId} setShowModal={setActiveUserId}>
              <CreateUserForm
                usersData={usersData}
                item={user}
                id={user.id}
                hideModal={setActiveUserId}
                userUuid={user.uuid}
              />
            </ModalWindow>
          )}
        </div>
      ))}
    </ul>
  );
};
